import React, { useContext, useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Grid,
  Icon,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import Message from "./message";
import { CampaignContext } from "./context/campaignContext";
import { useJumboTheme } from "@jumbo/hooks";
import { useTranslation } from "react-i18next";

const IntervalCampaign = ({ showCampaign }) => {
  const { t } = useTranslation();

  const [intervalOption, setIntervalOption] = useState("30-60 sec");
  const [showMessage, setShowMessage] = useState(false);
  const { setInterval , interval } = useContext(CampaignContext);
  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);
  const handleIntervalOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setIntervalOption(newOption);
    }
  };
  const handleBack = () => {
    showCampaign();
  };

  const handleNextClick = () => {
    setShowMessage(!showMessage);
    setInterval(intervalOption);
  };
  const showInterval = () => setShowMessage(false);

  useEffect(() => {
    if (interval) {
      setIntervalOption(interval);
    }
  }, [interval]);
  return (
    <>
      {!showMessage ? (
        <Paper elevation={3} style={{ padding: "2rem 4rem" }}>
          <Typography
            variant="h1"
            container
            sx={{ textAlign: "center" }}
            fontSize="1.6rem"
            marginBottom="1.5rem"
            color="primary"
          >
            {t('pages.title.interval_')}
          </Typography>
          <Typography
            variant="h6"
            container
            sx={{ textAlign: "center" }}
            marginBottom="1rem"
            color="grey"
          >
           {t('pages.title.campaign_subtitle2')}
          </Typography>
          <Grid container flexDirection="column" gap={2}>
            <ToggleButtonGroup
              value={intervalOption}
              exclusive
              onChange={handleIntervalOptionChange}
              sx={{ margin: ".5rem 0" }}
            >
              <ToggleButton value="30-60 sec">30-60 sec</ToggleButton>
              <ToggleButton value="1-3 Min">1-3 Min</ToggleButton>
              <ToggleButton value="3-5 Min">3-5 Min</ToggleButton>
              <ToggleButton value="5-10 Min">5-10 Min</ToggleButton>
            </ToggleButtonGroup>

            <Grid
              container
              gap=".5rem"
              justifyContent="center"
              alignItems="center"
              xs={12}
              margin="1rem 0"
            >
              <Icon color="primary">
                <VideoIcon />
              </Icon>{" "}
              <a
                href={
                  storedLocale === "fr-FR"
                    ? "https://player.vimeo.com/video/867896920?badge=0&autopause=0&player_id=0&app_id=58479"
                    : "https://player.vimeo.com/video/867930211?badge=0&autopause=0&player_id=0&app_id=58479"
                }
                target="_blank"
                rel="noreferrer"
              >
                <span>
                {storedLocale === "fr-FR"
                  ? "VOIR LA VIDEO EXPLICATIVE"
                  : "Watch Tutorial Video"}
                </span>
              </a>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              xs={12}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "lightgrey",
                  color: "white",
                  ":hover": {
                    backgroundColor: "darkgrey",
                  },
                }}
                onClick={handleBack}
              >
<span>
{t("pages.title.back")}

</span>
              </Button>{" "}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextClick}
              >
                                 <span> {t("pages.title.next")}</span>

              </Button>
            </Grid>
          </Grid>
        </Paper>
      ):''}
      {showMessage ? <Message showInterval={showInterval} />:''}
    </>
  );
};

export default IntervalCampaign;

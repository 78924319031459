import { Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { FirstScreen } from "./steps/first-Screen";
import { CreateMessageScreen } from "./steps/create-message-screen";
import { PreviewScreen } from "./steps/preview-screen";
import { MessageListScreen } from "./steps/message-list-screen";
import "./css/index.css";

export const stepCount = {
  firstScreen: 1,
  createScreen: 2,
  previewScreen: 3,
  listScreen: 4,
};

export function MessageDialog({
  setNewMessageId,
  modalState,
  refetch,
  onlyCreateMessage,
  messageData,
  handleMoreButtonOptions
}) {
  const [step, setStep] = useState(stepCount.firstScreen);
  const [previewData, setPreviewData] = useState();
  const [messageId, setMessageId] = useState();
  const [isCreateMessage, setIsCreateMessage] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [variantsInfo, setVariantsInfo] = useState(null);

  const setNewMessageId2 = (msgData) => {
    setStep(stepCount.createScreen);
    setVariantsInfo(msgData);
  }

  const handleClose = () => {
    if (step === stepCount.previewScreen) {
      // Navigate back to the appropriate step based on isCreateMessage
      setStep(isCreateMessage ? stepCount.createScreen : stepCount.listScreen);
      setIsCreateMessage(false);
      setVariantsInfo(null); // Clear preview-related data if needed
    } else if (step === stepCount.createScreen || step === stepCount.listScreen) {
      // Ensure proper step flow from createScreen or listScreen
      setStep(stepCount.firstScreen);
      setVariantsInfo(null); // Clear any residual data
    } else if (step > stepCount.firstScreen) {
      // Go back to the previous step
      setStep((prevStep) => prevStep - 1);
    } else {
      // Close the modal if on the first step
      setStep(stepCount.firstScreen);
      modalState.setIsOpen(false);
      setVariantsInfo(null);
    }
  };


  useEffect(() => {
    if(step === stepCount.firstScreen) {
      setVariantsInfo(null);
    }
  }, [step]);

  return (
    <Dialog
      open={modalState.isOpen}
      onClose={()=>handleClose()}
      fullWidth
      sx={{
        ".MuiPaper-root ": {
          maxWidth: "100%",
        },
      }}
    >
      {step === stepCount.firstScreen && (
        <FirstScreen
          setStep={setStep}
          handleClose={handleClose}
          setCurrentStep={setCurrentStep}
          currentStep={currentStep}
        />
      )}
      {step === stepCount.createScreen && (
        <CreateMessageScreen
          setStep={setStep}
          handleClose={handleClose}
          setPreviewData={setPreviewData}
          setMessageId={setMessageId}
          refetch={refetch}
          setIsCreateMessage={setIsCreateMessage}
          previewData={previewData}
          isCreateMessage={isCreateMessage}
          setNewMessageId={setNewMessageId}
          messageId={messageId}
          messageData={messageData}
          variants_info={variantsInfo}
        />
      )}
      {step === stepCount.previewScreen && (
        <PreviewScreen
          setStep={setStep}
          handleClose={handleClose}
          previewData={previewData}
          setNewMessageId={setNewMessageId2}
          messageId={messageId}
          isCreateMessage={isCreateMessage}
          setIsCreateMessage={setIsCreateMessage}
        />
      )}
      {step === stepCount.listScreen && (
        <MessageListScreen 
          setStep={setStep}
          handleClose={handleClose}
          setPreviewData={setPreviewData}
          setNewMessageId={setNewMessageId2}
          setMessageId={setMessageId}
          setIsCreateMessage={setIsCreateMessage}
          setCategoryList={setCategoryList}
          setSelectedLanguage={setSelectedLanguage}
          selectedLanguage={selectedLanguage}
          categoryList={categoryList}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          handleMoreButtonOptions={handleMoreButtonOptions}
        />
      )}
    </Dialog>
  );
}

import React from "react";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Becomeaffiliate from "./Becomeaffiliate";
import {
  Button,
} from "@mui/material";
import Affiliateaggrement from "./Affiliateaggrement";
import KYC from "./KYC";
import Updatepayoutinformation from "./Updatepayoutinformation";
import CurrentPayOutDetails from "./CurrentPayOutDetails";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { postRequest } from "backendServices/ApiCalls";
import { useEffect } from "react";

const Modals = ({
  open,
  setOpen,
  affiliateagreementopen,
  setAffiliateAgreementOpen,
  becomeaffiliateopen,
  setBecomeAffiliateOpen,
  updatepayoutinformationopen,
  setUpdatePayoutInformationOpen,
  currentPayoutDetails,
  setCurrentPayoutDetails,
}) => {
  const { t } = useTranslation();


  return (
    <>
      {/* Affiliate Dialogue */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ fontSize: "25px" }}>
          {t("pages.title.becomeaffiliate")}
        </DialogTitle>
        <DialogContent>
          <Becomeaffiliate setOpen={setOpen} />
        </DialogContent>
      </Dialog>

      {/* Affiliate Agreement Dialogue */}
      <Dialog
        fullWidth={true}
        open={affiliateagreementopen}
        onClose={() => setAffiliateAgreementOpen(false)}
      >
        <DialogTitle>{t("pages.title.affiliataggrement")}</DialogTitle>
        <DialogContent>
          <Affiliateaggrement />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAffiliateAgreementOpen(false)}>
            {t("pages.title.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Become Affiliate Agreement Dialogue */}
      <Dialog
        fullWidth={true}
        open={becomeaffiliateopen}
        onClose={() => setBecomeAffiliateOpen(false)}
      >
        <DialogTitle>{t("pages.title.uploaddocumentkyc")}</DialogTitle>
        <DialogContent>
          <KYC setBecomeAffiliateOpen={setBecomeAffiliateOpen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBecomeAffiliateOpen(false)}>
            {t("pages.title.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* update payout information open Dialogue */}
      <Dialog
        fullWidth={true}
        open={updatepayoutinformationopen}
        onClose={() => setUpdatePayoutInformationOpen(false)}
      >
        <DialogTitle>{t("pages.title.updatepayoutinformation")}</DialogTitle>
        <DialogContent>
          <Updatepayoutinformation
            setUpdatePayoutInformationOpen={setUpdatePayoutInformationOpen}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdatePayoutInformationOpen(false)}>
            {t("pages.title.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* update payout information open Dialogue */}
      <Dialog
        fullWidth={true}
        open={currentPayoutDetails}
        onClose={() => setCurrentPayoutDetails(false)}
      >

        <DialogTitle>{t("pages.title.currentpayoutdetails")}</DialogTitle>
        <DialogContent>
          <CurrentPayOutDetails
            setCurrentPayoutDetails={setCurrentPayoutDetails}
            setUpdatePayoutInformationOpen={setUpdatePayoutInformationOpen}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCurrentPayoutDetails(false)}>
            {t("pages.title.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Modals;

import React, { useContext, useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getunilevelreports } from "backendServices/ApiCalls";
import { BsFunnel, BsSearch } from "react-icons/bs";
import Div from "@jumbo/shared/Div";
import { useNavigate } from "react-router-dom";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

export default function AffiliateLevelCommission() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [unilevelbonus, setUnilevelBonusData] = useState([]);
  const currentMonth = new Date().getMonth() + 1;

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const navigate = useNavigate();
  if (loginUserData?.user_type === 'Normal') {
    navigate("/affiliate");
  }

  const [selectedFilter, setSelectedFilter] = useState({
    level1: true,
    level2: true,
    others: true,
  });
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 }
  ];


  const UnilevelData = () => {
    let params = {
      month: selectedMonth,
    };

    setLoading(true);
    getunilevelreports(
      params,
      (response) => {
        setLoading(false);
        setUnilevelBonusData(response?.data?.data);
        setFilteredData(response?.data?.data);
      },
      (error) => {
        setLoading(false);
        console.log(error?.response?.data);
      }
    );
  };

  useEffect(() => {
    UnilevelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleFilterChange = (filterType) => {
    // Toggle filter
    const newFilterState = {
      ...selectedFilter,
      [filterType]: !selectedFilter[filterType],
    };

    // Ensure at least one filter remains checked
    if (Object.values(newFilterState).every(value => !value)) {
      return; // Prevent all buttons from being unchecked
    }

    setSelectedFilter(newFilterState);

    const filtered = unilevelbonus?.filter((row) => {
      if (newFilterState.level1 && (row.type === 'Level 1 Bonus' || row.type === 'Level 1 Bonus Deducted')) {
        return true;
      }
      if (newFilterState.level2 && (row.type === 'Level 2 Bonus' || row.type === 'Level 2 Bonus Deducted')) {
        return true;
      }
      if (newFilterState.others && (row.type === 'Bonus Add By Admin' || row.type === 'Bonus Deduct By Admin')) {
        return true;
      }
      return false;
    });
    setFilteredData(filtered);
  };

  const handleSearch = debounce((value) => {
    const searchValue = value.toLowerCase();
    const filtered = unilevelbonus?.filter((row) => {
      const senderName = `${row.firstname || ""} ${row.lastname || ""}`.toLowerCase();
      return senderName.includes(searchValue);
    });
    setFilteredData(filtered);
  }, 500);

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
    handleSearch(event.target.value);
  };

  const handleChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const formatDate = (dateInput) => {
    let date = new Date(dateInput);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const getFormattedAmount = (amount, type) => {
    const negativeTypes = [
      "Bonus Deduct By Admin",
      "Level 1 Bonus Deducted",
      "Level 2 Bonus Deducted"
    ];

    if (negativeTypes.includes(type)) {
      return `-${amount}`;
    }
    return amount;
  };

  const renderRowData = (row, index) => {
    let reason = "";
    let type = row.type;
    let payout = row.payOutPer;
    let amount = getFormattedAmount(row.amount, row.type);

    if (row.type === "Bonus Deduct By Admin") {
      reason = row.reason || "Deducted By Admin";;
      type = "Other";
      payout = "";
    } else if (row.type === "Bonus Add By Admin") {
      reason = row.reason || "Added By Admin";
      type = "Other";
      payout = "";
    } else {
      reason = (row.firstname && row.lastname) ? `${row.firstname} ${row.lastname}` : row.username;
    }

    return (
      <TableRow
        key={row.sender}
        sx={{
          backgroundColor: index % 2 === 0 ? '#f6f6f6' : 'inherit',
          '&:hover': { backgroundColor: '#f1f3f5' },
          transition: 'background-color 0.4s ease',
        }}
      >
        <TableCell align="left">
          <Typography variant="body2">{index + 1}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">{amount}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">{row.currency}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="body2">{reason}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{type}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">{payout}</Typography>
        </TableCell>
        <TableCell align="center">
          <Typography variant="body2">
            {row.createdat && formatDate(row.createdat)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  };



  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <AffiliateLayout>
      <div className="title-area" style={{
        backgroundColor: '#fff',
        boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
        borderRadius: '12px',
        margin: '0 0 20px 0',
        padding: '20px',
      }}>
        <div className="title-area-left levelcommision-head"  >
          <h2> {t("pages.title.level-commission")}</h2>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              variant={selectedFilter.level1 ? "contained" : "outlined"}
              onClick={() => handleFilterChange("level1")}
            >
              {t("pages.title.Level1")}
            </Button>
            <Button
              variant={selectedFilter.level2 ? "contained" : "outlined"}
              onClick={() => handleFilterChange("level2")}
            >
              {t("pages.title.Level2")}
            </Button>
            <Button
              variant={selectedFilter.others ? "contained" : "outlined"}
              onClick={() => handleFilterChange("others")}
            >
              {t("pages.title.Others")}
            </Button>
          </div>

        </div>
      </div>
      {!loading && (
        <Card  sx={{ padding: "20px" }}>
          <div
            className="title-area"
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "30px",
              marginBottom: "20px",
            }}
          >
            <div className="title-area-left">
              <FormControl sx={{ width: "25ch" }} variant="outlined">
                <InputLabel htmlFor="search">Search</InputLabel>
                <OutlinedInput
                  id="search"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchInputChange}
                  startAdornment={
                    <InputAdornment position="center">
                      <IconButton edge="end">
                        <BsSearch />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search"
                />
              </FormControl>
            </div>
            <div
              className="title-area-right"
              style={{
                gap: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ width: "150px" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedMonth}
                  onChange={handleChange}
                >

                  {

                    months.map((item) => (
                      <MenuItem style={{ background: selectedMonth === item.value ? "#2C73FF" : "", color: selectedMonth === item.value && "white" }} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>

            </div>
          </div>
          <TableContainer className="sep-AffiliateTable-div" component={Paper} elevation={3}>
            <Table className="sep-AffiliateTable-main sep-levelCommision" sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead >
                <TableRow sx={{ backgroundColor: '#f4f6f8' }}>
                  <TableCell><Typography variant="subtitle2">#</Typography></TableCell>
                  <TableCell><Typography variant="subtitle2">Amount</Typography></TableCell>
                  <TableCell><Typography variant="subtitle2">Currency</Typography></TableCell>
                  <TableCell align="left">
                    <Typography variant="subtitle2">Sender</Typography>
                  </TableCell>
                  <TableCell align="center"><Typography variant="subtitle2">Type</Typography></TableCell>
                  <TableCell align="center"><Typography variant="subtitle2">% Payout</Typography></TableCell>
                  <TableCell align="center"><Typography variant="subtitle2">Date</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={7} align="center" sx={{ padding: '16px' }}>
                      <Typography variant="body1" color="textSecondary">No Record Found</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredData?.map((row, index) => renderRowData(row, index))
                )}
              </TableBody>

            </Table>
          </TableContainer>

        </Card>
      )}
    </AffiliateLayout>
  );
}
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Autocomplete,
} from "@mui/material";
import Box from "@mui/material/Box";
import { binaryTeamUsersApi } from "backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";

const SearchUser = ({ setRandomCode }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [binaryTeam, setBinaryTeam] = useState([]);
  const [username, setUsername] = useState("");
  const navigate = useNavigate();

  const handleChange = (_, newValue) => {
    setUsername(newValue?.randomcode);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleFilter = () => {
    localStorage.setItem("randomCode", username);
    setRandomCode(username);
    navigate("/binary-tree");
  };

  const getBinaryTeam = () => {
    binaryTeamUsersApi(
      (response) => {
        const filteredData = response?.data?.data.filter(
          (data) => !!data?.username
        );
        setBinaryTeam(filteredData);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    getBinaryTeam();
  }, []);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        endIcon={<PeopleIcon />}
      >
        {t("pages.title.filter_User")}
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>{t("pages.title.filter_Username")}</DialogTitle>
        <DialogContent sx={{ width: 350 }}>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl sx={{ m: 1, minWidth: "100%" }}>
              {/* <InputLabel id="demo-dialog-select-label">User</InputLabel> */}
              <Autocomplete
                onChange={handleChange}
                options={binaryTeam}
                getOptionLabel={(option) => option?.username || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    id="demo-dialog-select"
                    variant="outlined"
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option?.randomcode === value?.randomcode
                }
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            {t("pages.title.cancel")}
          </Button>
          <Button variant="contained" onClick={handleFilter}>
            {t("pages.title.filter")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SearchUser;

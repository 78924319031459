import React, { useState, useContext, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Paper,
  Typography,
  Icon,
  Snackbar,
  Alert,
} from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import IntervalCampaign from "./interval";
import { CampaignContext } from "./context/campaignContext";
import axios from "axios";
import { API_BASE_URL } from "backendServices/ApiCalls";
import { useJumboTheme } from "@jumbo/hooks";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const Campaign = ({
  selectedgroup = null,
  filteredUsersByStage = [],
  stageName = null,
  setStageName,
}) => {
  const { t } = useTranslation();

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [numPeopleInGroup, setNumPeopleInGroup] = useState(0);
  const [error, setError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showIntervalCampaign, setShowIntervalCampaign] = useState(false);
  const { setGroupDetail, groupDetail, setUserIds } =
    useContext(CampaignContext);
  const [groupId, setSelectedGroupId] = useState(0);
  const [groupsFetched, setGroupsFetched] = useState(false); // Track if groups have been fetched
  const { loginUserData } = useContext(CustomProvider);
  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );
  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);
  useEffect(() => {
    if (!groupsFetched) {
      const fetchData = async () => {
        try {
          const response = await axios.get(API_BASE_URL + "/group");
          setGroups(response.data);
          setGroupsFetched(true); // Mark groups as fetched
        } catch (error) {}
      };

      fetchData();
    }
  }, [groupsFetched]);

  useEffect(() => {
    if (groupDetail.selectedGroup) {
      setSelectedGroup(groupDetail.selectedGroup);
    }
  }, [groupDetail]);

  useEffect(() => {
    const fectdata = async () => {
      if (selectedgroup !== null) {
        setSelectedGroup(selectedgroup.name);

        const selectedGroupName = selectedgroup.name;
        setSelectedGroup(selectedGroupName);

        const selectedGroup = groups.find(
          (group) => group.name === selectedGroupName
        );
        if (selectedGroup) {
          const selectedGroupId = selectedGroup.id;
          setSelectedGroupId(selectedGroupId);
          try {
            const response = await axios.get(`${API_BASE_URL}/taggeduser`);
            if (response.data) {
              const taggedUsersWithSelectedGroup = response.data.filter(
                (user) => {
                  const tagIdsArray = user.tag_id.split(",").map(Number);
                  return tagIdsArray.includes(selectedGroupId);
                }
              );
              if (filteredUsersByStage.length > 0) {
                setNumPeopleInGroup(filteredUsersByStage.length);
              } else {
                setNumPeopleInGroup(taggedUsersWithSelectedGroup.length);
              }
              setUserIds(filteredUsersByStage);
            }
          } catch (error) {
            setNumPeopleInGroup(0);
          }
        }
      }
      setError("");
    };
    fectdata();
  }, [selectedgroup, groups, filteredUsersByStage]);
  const handleGroupChange = async (event) => {
    if (typeof setStageName === "function") {
      setStageName(null);
    }
    setSelectedGroup("");
    const selectedGroupName = event.target.value;
    setSelectedGroup(selectedGroupName);
    const selectedGroup = groups.find(
      (group) => group.name === selectedGroupName
    );
    if (selectedGroup) {
      const selectedGroupId = selectedGroup.id;
      setSelectedGroupId(selectedGroupId);
      try {
        const response = await axios.get(`${API_BASE_URL}/taggeduser`);
        if (response.data) {
          let tagIdsArray = [];
          const taggedUsersWithSelectedGroup = response.data.filter((user) => {
            if (user.tag_id.includes(",")) {
              tagIdsArray = user.tag_id.split(",").map(Number);
            } else {
              tagIdsArray = [user.tag_id];
            }

            return tagIdsArray.includes(String(selectedGroupId));
            // const tagIdsArray = user.tag_id.split(",").map(Number); // Split the string and convert to numbers
            // return tagIdsArray.includes(selectedGroupId);
          });
          // Set the number of people in the selected group based on the array length
          setNumPeopleInGroup(taggedUsersWithSelectedGroup.length);
        }
      } catch (error) {
        setNumPeopleInGroup(0);
      }
    }

    setError("");
  };

  const handleNextClick = () => {
    if (!selectedGroup) {
      setSnackbarMessage("Please select a group");
      setSnackbarOpen(true);
    } else {
      setShowIntervalCampaign(!showIntervalCampaign);
      setSnackbarOpen(false);
      setGroupDetail({
        selectedGroup: selectedGroup,
        numPeopleInGroup: numPeopleInGroup,
        id: groupId,
      });
    }
  };

  const showCampaign = () => setShowIntervalCampaign(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        width: "100%",
      }}
    >
      {!showIntervalCampaign ? (
        <Paper elevation={3} style={{ padding: "2rem 4rem" }}>
          <Typography
            variant="h1"
            container
            sx={{ textAlign: "center" }}
            fontSize="1.6rem"
            marginBottom="1.5rem"
            color="primary"
          >
            {t("pages.title.who")}
          </Typography>
          <Typography
            variant="h6"
            container
            sx={{ textAlign: "center" }}
            marginBottom="1.5rem"
            color="grey"
          >
            {t("pages.title.campaign_subtitle")}
          </Typography>
          <Grid container flexDirection="column" gap={2}>
            <Grid item xs={12}>
              <TextField
                select
                label="Group Name"
                value={selectedGroup}
                onChange={handleGroupChange}
                fullWidth
                sx={{ paddingTop: "1rem" }}
              >
                {groups.map((group) => (
                  <MenuItem key={group.id} value={group.name}>
                    <span>{group.name}</span>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {error ? (
              <Grid
                container
                justifyContent="center"
                color="error.main"
                sx={{ marginTop: "1rem" }}
              >
                <Typography>{error}</Typography>
              </Grid>
            ) : (
              ""
            )}
            <Grid
              container
              gap=".5rem"
              justifyContent="center"
              alignItems="center"
              xs={12}
              margin="1rem 0"
            >
              <Icon color="primary">
                <VideoIcon />
              </Icon>{" "}
              <a
                href={
                  storedLocale === "fr-FR"
                    ? "https://player.vimeo.com/video/867896920?badge=0&autopause=0&player_id=0&app_id=58479"
                    : "https://player.vimeo.com/video/867930211?badge=0&autopause=0&player_id=0&app_id=58479"
                }
                target="_blank"
                rel="noreferrer"
              >
                {storedLocale === "fr-FR"
                  ? "VOIR LA VIDEO EXPLICATIVE"
                  : "Watch Tutorial Video"}
              </a>
            </Grid>
            <Grid container>
              <p style={{ fontSize: "1.1rem", margin: 0 }}>
                <span>{t("pages.title.group")}</span>:{" "}
                <span>{selectedGroup}</span>
              </p>
            </Grid>
            {stageName ? (
              <Grid container>
                <p style={{ fontSize: "1.1rem", margin: 0 }}>
                  <span>Stage:</span> <span>{stageName}</span>
                </p>
              </Grid>
            ) : (
              ""
            )}
            <Grid container>
              <p style={{ fontSize: "1.1rem", margin: 0 }}>
                <span>People:</span> <span>{numPeopleInGroup}</span>
              </p>
            </Grid>
            <Grid
              container
              gap="1rem"
              justifyContent="end"
              alignItems="center"
              xs={12}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleNextClick}
              >
                <span> {t("pages.title.next")}</span>
              </Button>
            </Grid>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={4000}
              onClose={() => setSnackbarOpen(false)}
            >
              <Alert onClose={() => setSnackbarOpen(false)} severity="error">
                <span> {snackbarMessage}</span>
              </Alert>
            </Snackbar>
          </Grid>
        </Paper>
      ) : (
        ""
      )}
      {showIntervalCampaign ? (
        <IntervalCampaign showCampaign={showCampaign} />
      ) : (
        ""
      )}
    </div>
  );
};

export default Campaign;

import React, { useState, useEffect, useContext } from "react";
import "./Affiliate.css";
import stoneImg3 from "../../../../assets/img/platinum.png";
import stoneImg1 from "../../../../assets/img/silver.png";
import stoneImg2 from "../../../../assets/img/gold.png";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ReorderIcon from "@mui/icons-material/Reorder";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GridOnIcon from "@mui/icons-material/GridOn";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { BarChart } from "@mui/x-charts/BarChart";
import {
  dashboarddataApi,
  CheckAffiliateHostedPageApi,
  CheckUpgradeHostedPageApi
} from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div";
import { CircularProgress, Grid, Stack } from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";
import Modals from "./Modals";
import styled from "styled-components";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";

const Item = styled(Paper)(({ theme }) => ({}));

const Crypto = ({ targetDate }) => {
  const { t } = useTranslation();
  const [dashboardData, setdDashboardData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(true);
  const [affLoading, setaffLoading] = useState(false);
  const { loginUserData, loading, currentLanguage } =
    useContext(CustomProvider);
  const [open, setOpen] = useState(false);
  const [affiliateagreementopen, setAffiliateAgreementOpen] = useState(false);
  const [becomeaffiliateopen, setBecomeAffiliateOpen] = useState(false);
  const [updatepayoutinformationopen, setUpdatePayoutInformationOpen] =
    useState(false);
  const [currentPayoutDetails, setCurrentPayoutDetails] = useState(false);
  const sweetAlerts = (icone, title, text) => {
    Swal.fire({
      icon: icone,
      title: title,
      text: text,
    });
  };
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  
  const urlSearchParams = new URLSearchParams(window.location.search);
  const hostedId = urlSearchParams.get("id");
  const hostedState = urlSearchParams.get("state");

  const navigate = useNavigate();
  navigate('/affiliate')

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const GetDashboardData = () => {
    setLoader(true);
    dashboarddataApi(
      (response) => {
        setdDashboardData(response?.data?.dashboardData);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  const getCheckAffiliateHostedApi = () => {
    setaffLoading(true);
    let params = {
      hostedId: hostedId,
    };
    let check_upgrade_status = localStorage.getItem('upgradeStatus')
    if (check_upgrade_status === 'Yes') {
      CheckUpgradeHostedPageApi(
        params,
        (response) => {
          if (response?.data?.status === "error") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "error",
            });
          } else if (response?.data?.status === "success") {
            setalertData({
              show: true,
              message: "You have upgrade your subscription successfully.",
              variant: "success",
            });
            setaffLoading(false);
            navigate("/dashboard");
          } else {
            setalertData({
              show: true,
              message: "Server error occurred. Please try again later.",
              variant: "error",
            });
          }
          localStorage.removeItem('upgradeStatus')
          setaffLoading(false);
        },
        (error) => {
          setaffLoading(false);
          console.log(error?.response?.data);
        }
      );
    }
    else {
      CheckAffiliateHostedPageApi(
        params,
        (response) => {
          if (response?.data?.status === "error") {
            setalertData({
              show: true,
              message: response?.data?.message,
              variant: "error",
            });
          } else if (response?.data?.status === "success") {
            localStorage.setItem("affiliate_status", "yes");
            setalertData({
              show: true,
              message: "You have become an affiliate successfully.",
              variant: "success",
            });
            setaffLoading(false);
            navigate("/dashboard");
          } else {
            setalertData({
              show: true,
              message: "Server error occurred. Please try again later.",
              variant: "error",
            });
          }
          setaffLoading(false);
        },
        (error) => {
          setaffLoading(false);
          console.log(error?.response?.data);
        }
      );
    }

  };

  useEffect(() => {
    if (hostedState && hostedId) {
      getCheckAffiliateHostedApi();
    }
  }, []);

  useEffect(() => {
    GetDashboardData();
  }, []);

  const calculateTimeLeft1 = (months) => {
    const currentDate = new Date();
    const endOfMonths = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + months,
      0
    );
    const difference = endOfMonths - currentDate;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const calculateTimeLeft = (month) => {
    const difference = +new Date(month) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeftOneMonth, setTimeLeftOneMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeftThreeMonth, setTimeLeftThreeMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeftSixMonth, setTimeLeftSixMonth] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setTimeLeftOneMonth(calculateTimeLeft("2024-08-31T23:59:59"));
    }, 1000);

    return () => clearTimeout(timer1);
  }, []); // Empty dependency array to run the effect only once

  useEffect(() => {
    const timer2 = setTimeout(() => {
      setTimeLeftThreeMonth(calculateTimeLeft("2024-011-30T23:59:59"));
    }, 1000);

    return () => clearTimeout(timer2);
  }, []);

  useEffect(() => {
    const timer3 = setTimeout(() => {
      setTimeLeftSixMonth(calculateTimeLeft("2024-02-28T23:59:59"));
    }, 1000);

    return () => clearTimeout(timer3);
  }, []);

  // const renderIcons = (users) => {
  //   const iconsToColor = users; // Adjust this based on your logic
  //   const icons = [];

  //   // Loop to generate icons, coloring only the required number
  //   for (let i = 0; i < 10; i++) {
  //     // Assuming there are 10 icons in total
  //     if (i < iconsToColor) {
  //       icons.push(<AccountCircleIcon key={i} className="new colored" />);
  //     } else {
  //       icons.push(<AccountCircleIcon key={i} className="" />);
  //     }
  //   }

  //   return icons;
  // };

  // const handleAgreement = () => {
  //   window.open(
  //     "https://novalya.com/wp-content/uploads/2024/02/Contrat-Affiliation-Novalya-01-Fev-2024-.pdf",
  //     "_blank"
  //   );
  // };

  // const handleLink = () => {
  //   const url = "https://novalya.com/affiliate-compensation/";
  //   window.open(url, "_blank");
  // };

  // useEffect(() => {
  //   if (loginUserData?.user_type === "Distributor") {
  //     setActiveStep(1);
  //   }
  //   if (loginUserData?.kyc_status === "Verified") {
  //     setActiveStep(2);
  //   }
  // }, [loginUserData]);

  // if (loader || loading || affLoading) {
  //   return (
  //     <Div
  //       sx={{
  //         display: "flex",
  //         minWidth: 0,
  //         alignItems: "center",
  //         alignContent: "center",
  //         height: "100%",
  //       }}
  //     >
  //       <CircularProgress sx={{ m: "-40px auto 0" }} />
  //     </Div>
  //   );
  // }

  return null

  // return (
  //   <>
  //     {alertData.show && (
  //       <SweetAlert alertData={alertData} setalertData={setalertData} />
  //     )}
  //     {loginUserData?.user_type === "Normal" ? (
  //       <div className="affiliate">
  //         <div className="affiliate-grid">
  //           <div className="grid-box-2">
  //             <div>
  //               <div className="">
  //                 <Grid container fullWidth spacing={2}>
  //                   <Grid item sm={12} xs={12}>
  //                     <Stack
  //                       sx={{
  //                         display: "grid",
  //                         gridTemplateColumns: "1.5fr 1fr",
  //                         gap: "35px",
  //                         marginBottom: "5px",
  //                       }}
  //                     >
  //                       <Paper
  //                         sx={{
  //                           padding: "5%",
  //                           position: "relative", // Ensure the iframe takes the entire space
  //                           iframe: {
  //                             border: "none",
  //                             height: "46vh",
  //                             width: "72vh",
  //                           },
  //                           textAlign: "center",
  //                           boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
  //                         }}
  //                       >
  //                         <>
  //                           <h2>{t("pages.title.affiliatevideo")}</h2>
  //                           {currentLanguage === "fr-FR" ? (
  //                             <iframe
  //                               src="https://player.vimeo.com/video/911819353?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  //                               allow="autoplay; fullscreen; picture-in-picture"
  //                               title={t("pages.title.affiliatevideo")}
  //                             ></iframe>
  //                           ) : currentLanguage === "es-ES" ? (
  //                             <iframe
  //                               src="https://player.vimeo.com/video/911819398?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  //                               allow="autoplay; fullscreen; picture-in-picture"
  //                               title={t("pages.title.affiliatevideo")}
  //                             ></iframe>
  //                           ) : (
  //                             <iframe
  //                               src="https://player.vimeo.com/video/911819437?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
  //                               allow="autoplay; fullscreen; picture-in-picture"
  //                               title={t("pages.title.affiliatevideo")}
  //                             ></iframe>
  //                           )}
  //                         </>
  //                       </Paper>
  //                     </Stack>
  //                   </Grid>
  //                 </Grid>
  //               </div>
  //               <div className="spacer"></div>

  //               <div className="grid-box sales-bonus">
  //                 <h2>{t("pages.title.MySalesBonus")}</h2>
  //                 <p>{t("pages.title.personalactivesalesdetail")}</p>

  //                 <div className="spacer"></div>
  //                 <div className="spacer"></div>

  //                 <div className="current-payout">
  //                   <h3>{t("pages.title.MyCurrentPayout")} %</h3>
  //                   <div className="eb-box">
  //                     <h6>{t("pages.title.NIVEAU1")}</h6>
  //                     <h4>{dashboardData?.currentPayoutPer?.l1}%</h4>
  //                   </div>
  //                   <div className="eb-box">
  //                     <h6>{t("pages.title.NIVEAU2")}</h6>
  //                     <h4>{dashboardData?.currentPayoutPer?.l2}%</h4>
  //                   </div>
  //                 </div>
  //                 <div className="spacer"></div>
  //                 <div className="spacer"></div>
  //                 <div className="active-sales">
  //                   <h3>{t("pages.title.MyPersonalActiveSales")}</h3>
  //                   <div className="as-prog">
  //                     <div style={{ width: `${dashboardData?.progressBar}%` }}>
  //                       <span>
  //                         {dashboardData?.personalActiveSale?.activeSale}
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div>
  //               <div className="eb-box timer">
  //                 <h4>{t("pages.title.EndOfMonthin")}:</h4>
  //                 <div className="timer-box">
  //                   <h5>
  //                     {timeLeftOneMonth.days}{" "}
  //                     <span>{t("pages.title.Days")}</span>
  //                   </h5>
  //                   <span>:</span>
  //                   <h5>
  //                     {timeLeftOneMonth.hours}{" "}
  //                     <span>{t("pages.title.Hours")}</span>
  //                   </h5>
  //                   <span>:</span>
  //                   <h5>
  //                     {timeLeftOneMonth.minutes}{" "}
  //                     <span>{t("pages.title.Minutes")}</span>
  //                   </h5>
  //                 </div>
  //               </div>
  //               <div className="btn-grid as-btn-grid eb-box">
  //                 <button
  //                   onClick={() => navigate("/affiliate-links")}
  //                   className="btn-purple-light"
  //                 >
  //                   {t("pages.title.GotoMyAffiliateLinks")}
  //                 </button>
  //                 <button
  //                   className="btn-purple-outlined"
  //                   onClick={() => handleAgreement()}
  //                 >
  //                   {t("pages.title.AffiliateAgreement")}
  //                 </button>
  //                 <button
  //                   className="btn-purple-outlined"
  //                   onClick={() => handleLink()}
  //                 >
  //                   {t("pages.title.CompensationPlan")}
  //                 </button>
  //               </div>
  //               <div className="grid-box affiliate-stepper">
  //                 <Div sx={{ maxWidth: 400 }}>
  //                   <Modals
  //                     open={open}
  //                     setOpen={setOpen}
  //                     affiliateagreementopen={affiliateagreementopen}
  //                     setAffiliateAgreementOpen={setAffiliateAgreementOpen}
  //                     becomeaffiliateopen={becomeaffiliateopen}
  //                     setBecomeAffiliateOpen={setBecomeAffiliateOpen}
  //                     updatepayoutinformationopen={updatepayoutinformationopen}
  //                     setUpdatePayoutInformationOpen={
  //                       setUpdatePayoutInformationOpen
  //                     }
  //                     currentPayoutDetails={currentPayoutDetails}
  //                     setCurrentPayoutDetails={setCurrentPayoutDetails}
  //                   />
  //                   <Stepper activeStep={activeStep} orientation="vertical">
  //                     <Step>
  //                       <StepLabel>
  //                         {t("pages.title.becomeaffiliate")}
  //                       </StepLabel>
  //                       <StepContent>
  //                         <Typography>
  //                           <Stack direction="row" spacing={2}>
  //                             <Item>
  //                               {loginUserData?.user_type === "Normal" ? (
  //                                 <Button
  //                                   size="small"
  //                                   variant="contained"
  //                                   color="primary"
  //                                   onClick={() => setOpen(true)}
  //                                 >
  //                                   {t("pages.title.becomeaffiliate")}
  //                                 </Button>
  //                               ) : (
  //                                 <Button
  //                                   size="small"
  //                                   variant="contained"
  //                                   color="primary"
  //                                   disabled
  //                                 >
  //                                   {t("pages.title.youareaffiliate")}
  //                                 </Button>
  //                               )}
  //                             </Item>
  //                           </Stack>
  //                         </Typography>

  //                         <Div sx={{ mb: 2 }}>
  //                           <div>
  //                             <Button
  //                               variant="contained"
  //                               size="small"
  //                               color="success"
  //                               onClick={handleNext}
  //                               sx={{ mt: 1, mr: 1 }}
  //                               disabled={
  //                                 loginUserData?.user_type === "Distributor"
  //                                   ? false
  //                                   : true
  //                               }
  //                             >
  //                               {t("pages.title.continue")}
  //                             </Button>
  //                             <Button
  //                               disabled={true}
  //                               color="warning"
  //                               size="small"
  //                               onClick={handleBack}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.back")}
  //                             </Button>
  //                           </div>
  //                         </Div>
  //                       </StepContent>
  //                     </Step>
  //                     <Step>
  //                       <StepLabel>{t("pages.title.kycdocuments")}</StepLabel>
  //                       <StepContent>
  //                         <Typography>
  //                           {loginUserData?.kyc_status === "Unverified" ? (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               onClick={() => setBecomeAffiliateOpen(true)}
  //                             >
  //                               {t("pages.title.kyc")}
  //                             </Button>
  //                           ) : loginUserData?.kyc_status === "Uploaded" ? (
  //                             <Button
  //                               color="primary"
  //                               variant="contained"
  //                               onClick={() =>
  //                                 sweetAlerts(
  //                                   "info",
  //                                   "info",
  //                                   "You have already uploaded your documents. It will be verify soon. Thank You!"
  //                                 )
  //                               }
  //                             >
  //                               {t("pages.title.kyc")}
  //                             </Button>
  //                           ) : (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               disabled={true}
  //                             >
  //                               {t("pages.title.youverified")}
  //                             </Button>
  //                           )}
  //                         </Typography>
  //                         <Div sx={{ mb: 2 }}>
  //                           <div>
  //                             <Button
  //                               variant="contained"
  //                               size="small"
  //                               color="success"
  //                               onClick={handleNext}
  //                               sx={{ mt: 1, mr: 1 }}
  //                               disabled={
  //                                 loginUserData?.kyc_status === "Verified"
  //                                   ? false
  //                                   : true
  //                               }
  //                             >
  //                               {t("pages.title.continue")}
  //                             </Button>
  //                             <Button
  //                               disabled={false}
  //                               color="warning"
  //                               size="small"
  //                               onClick={handleBack}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.back")}
  //                             </Button>
  //                           </div>
  //                         </Div>
  //                       </StepContent>
  //                     </Step>
  //                     <Step>
  //                       <StepLabel
  //                         optional={
  //                           <Typography variant="caption">
  //                             {t("pages.title.laststep")}
  //                           </Typography>
  //                         }
  //                       >
  //                         {t("pages.title.payoutinformation")}
  //                       </StepLabel>
  //                       <StepContent>
  //                         <Typography>
  //                           {loginUserData?.bank_account_title === null &&
  //                             loginUserData?.wallet_address === null &&
  //                             loginUserData?.outside_bank_account_title ===
  //                             null &&
  //                             loginUserData?.porequestcount === 0 ? (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               onClick={() =>
  //                                 setUpdatePayoutInformationOpen(true)
  //                               }
  //                             >
  //                               {t("pages.title.updatepayoutinformation")}
  //                             </Button>
  //                           ) : loginUserData?.porequestcount > 0 ? (
  //                             <Button
  //                               color="primary"
  //                               variant="contained"
  //                               onClick={() =>
  //                                 sweetAlerts(
  //                                   "info",
  //                                   "info",
  //                                   "Your request already submitted to admin, it will be verify soon."
  //                                 )
  //                               }
  //                             >
  //                               {t("pages.title.updatepayoutinformation")}
  //                             </Button>
  //                           ) : (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               onClick={() => {
  //                                 setCurrentPayoutDetails(true);
  //                               }}
  //                             >
  //                               {t("pages.title.updatepayoutinformation")}
  //                             </Button>
  //                           )}
  //                         </Typography>
  //                         <Div sx={{ mb: 2 }}>
  //                           <div>
  //                             <Button
  //                               variant="contained"
  //                               size="small"
  //                               color="success"
  //                               onClick={handleNext}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.finish")}
  //                             </Button>
  //                             <Button
  //                               disabled={false}
  //                               color="warning"
  //                               size="small"
  //                               onClick={handleBack}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.back")}
  //                             </Button>
  //                           </div>
  //                         </Div>
  //                       </StepContent>
  //                     </Step>
  //                   </Stepper>
  //                 </Div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="grid-box">
  //             <div className="actual-payout">
  //               <h3>
  //                 {t("pages.title.YourActualPayout%isBasedontheFollowing")}:
  //               </h3>
  //               <div className="NVL-table">
  //                 <table>
  //                   <thead>
  //                     <tr>
  //                       <th>{t("pages.title.DirectActives")}</th>
  //                       {dashboardData?.uniLevelData?.unilevel?.map(
  //                         (data, index) => (
  //                           <th key={index}>{data?.number_of_users}</th>
  //                         )
  //                       )}
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>{t("pages.title.Niveau1")}</td>
  //                       {dashboardData?.uniLevelData?.unilevel?.map(
  //                         (data, index) => (
  //                           <td key={index}>
  //                             {data?.level1 === 0 ? 10 : data?.level1}%
  //                           </td>
  //                         )
  //                       )}
  //                     </tr>
  //                     <tr>
  //                       <td>{t("pages.title.Niveau2")}</td>
  //                       {dashboardData?.uniLevelData?.unilevel?.map(
  //                         (data, index) => (
  //                           <td key={index}>{data?.level2}%</td>
  //                         )
  //                       )}
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     ) : (
  //       <div className="affiliate">
  //         <div className="affiliate-grid">
  //           <div className="grid-box-1">
  //             <div className="eb-box lifetime-earnings">
  //               <h6>{t("pages.title.LifetimeEarnings")}</h6>
  //               <h4>
  //                 {dashboardData?.cSymbol}
  //                 {dashboardData?.lifeTImeEarning?.toFixed(2)}
  //               </h4>
  //             </div>
  //             <div className="eb-box">
  //               <h6>
  //                 {t("pages.title.LastMonthEarnings")} ({dashboardData?.cSymbol}
  //                 )
  //               </h6>
  //               <h4>
  //                 {dashboardData?.cSymbol}
  //                 {dashboardData?.lastMonthEarning?.toFixed(2)}
  //               </h4>
  //             </div>
  //             <div className="eb-box">
  //               <h6>{t("pages.title.TotalPayment")}</h6>
  //               <h4>
  //                 {dashboardData?.cSymbol}
  //                 {dashboardData?.totalPayment?.toFixed(2)}
  //               </h4>
  //             </div>
  //           </div>
  //           <div className="grid-box-2">
  //             <div>
  //               <div className="grid-box total-earnings">
  //                 <h6>
  //                   <span>{t("pages.title.Level1")}</span>
  //                   {dashboardData?.cSymbol}
  //                   {dashboardData?.totalEarning?.l1?.toFixed(2)}
  //                 </h6>
  //                 <span>+ </span>
  //                 <h6>
  //                   <span>{t("pages.title.Level2")}</span>
  //                   {dashboardData?.cSymbol}
  //                   {dashboardData?.totalEarning?.l2?.toFixed(2)}
  //                 </h6>
  //                 <span> + </span>
  //                 <h6>
  //                   <span>{t("pages.title.Bonus")}</span>
  //                   {dashboardData?.cSymbol}
  //                   {dashboardData?.totalEarning?.bonus?.toFixed(2)}
  //                 </h6>
  //                 <span> + </span>
  //                 <h6>
  //                   <span>{t("pages.title.Others")}</span>
  //                   {dashboardData?.cSymbol}
  //                   {dashboardData?.totalEarning?.others?.toFixed(2)}
  //                 </h6>
  //                 <span> = </span>
  //                 <h5>
  //                   <span>{t("pages.title.TotalEarnings")}</span>
  //                   {dashboardData?.cSymbol}
  //                   {(
  //                     dashboardData?.totalEarning?.l1 +
  //                     dashboardData?.totalEarning?.l2 +
  //                     dashboardData?.totalEarning?.bonus +
  //                     dashboardData?.totalEarning?.others
  //                   )?.toFixed(2)}
  //                 </h5>
  //               </div>
  //               <div className="spacer"></div>

  //               <div className="grid-box sales-bonus">
  //                 <h2>{t("pages.title.MySalesBonus")}</h2>
  //                 <p>{t("pages.title.personalactivesalesdetail")}</p>

  //                 <div className="spacer"></div>
  //                 <div className="spacer"></div>
  //                 <div className="current-payout">
  //                   <h3>{t("pages.title.MyCurrentPayout")} %</h3>
  //                   <div className="eb-box">
  //                     <h6>{t("pages.title.NIVEAU1")}</h6>
  //                     <h4>{dashboardData?.currentPayoutPer?.l1}%</h4>
  //                   </div>
  //                   <div className="eb-box">
  //                     <h6>{t("pages.title.NIVEAU2")}</h6>
  //                     <h4>{dashboardData?.currentPayoutPer?.l2}%</h4>
  //                   </div>
  //                 </div>
  //                 <div className="spacer"></div>
  //                 <div className="spacer"></div>
  //                 <div className="active-sales">
  //                   <h3>{t("pages.title.MyPersonalActiveSales")}</h3>
  //                   <div className="as-prog">
  //                     <div style={{ width: `${dashboardData?.progressBar}%` }}>
  //                       <span>
  //                         {dashboardData?.personalActiveSale?.activeSale}
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //             <div>
  //               <div className="eb-box timer">
  //                 <h4>{t("pages.title.EndOfMonthin")}:</h4>
  //                 <div className="timer-box">
  //                   <h5>
  //                     {timeLeftOneMonth.days}{" "}
  //                     <span>{t("pages.title.Days")}</span>
  //                   </h5>
  //                   <span>:</span>
  //                   <h5>
  //                     {timeLeftOneMonth.hours}{" "}
  //                     <span>{t("pages.title.Hours")}</span>
  //                   </h5>
  //                   <span>:</span>
  //                   <h5>
  //                     {timeLeftOneMonth.minutes}{" "}
  //                     <span>{t("pages.title.Minutes")}</span>
  //                   </h5>
  //                 </div>
  //               </div>
  //               <div className="btn-grid as-btn-grid eb-box">
  //                 <button
  //                   onClick={() => navigate("/affiliate-links")}
  //                   className="btn-purple-light"
  //                 >
  //                   {t("pages.title.GotoMyAffiliateLinks")}
  //                 </button>
  //                 <button
  //                   className="btn-purple-outlined"
  //                   onClick={() => handleAgreement()}
  //                 >
  //                   {t("pages.title.AffiliateAgreement")}
  //                 </button>
  //                 <button
  //                   className="btn-purple-outlined"
  //                   onClick={() => handleLink()}
  //                 >
  //                   {t("pages.title.CompensationPlan")}
  //                 </button>
  //               </div>
  //               <div className="grid-box affiliate-stepper">
  //                 <Div sx={{ maxWidth: 400 }}>
  //                   <Modals
  //                     open={open}
  //                     setOpen={setOpen}
  //                     affiliateagreementopen={affiliateagreementopen}
  //                     setAffiliateAgreementOpen={setAffiliateAgreementOpen}
  //                     becomeaffiliateopen={becomeaffiliateopen}
  //                     setBecomeAffiliateOpen={setBecomeAffiliateOpen}
  //                     updatepayoutinformationopen={updatepayoutinformationopen}
  //                     setUpdatePayoutInformationOpen={
  //                       setUpdatePayoutInformationOpen
  //                     }
  //                     currentPayoutDetails={currentPayoutDetails}
  //                     setCurrentPayoutDetails={setCurrentPayoutDetails}
  //                   />
  //                   <Stepper activeStep={activeStep} orientation="vertical">
  //                     <Step>
  //                       <StepLabel>
  //                         {t("pages.title.becomeaffiliate")}
  //                       </StepLabel>
  //                       <StepContent>
  //                         <Typography>
  //                           <Stack direction="row" spacing={2}>
  //                             <Item>
  //                               {loginUserData?.user_type === "Normal" ? (
  //                                 <Button
  //                                   size="small"
  //                                   variant="contained"
  //                                   color="primary"
  //                                   onClick={() => setOpen(true)}
  //                                 >
  //                                   {t("pages.title.becomeaffiliate")}
  //                                 </Button>
  //                               ) : (
  //                                 <Button
  //                                   size="small"
  //                                   variant="contained"
  //                                   color="primary"
  //                                   disabled
  //                                 >
  //                                   {t("pages.title.youareaffiliate")}
  //                                 </Button>
  //                               )}
  //                             </Item>
  //                           </Stack>
  //                         </Typography>

  //                         <Div sx={{ mb: 2 }}>
  //                           <div>
  //                             <Button
  //                               variant="contained"
  //                               size="small"
  //                               color="success"
  //                               onClick={handleNext}
  //                               sx={{ mt: 1, mr: 1 }}
  //                               disabled={
  //                                 loginUserData?.user_type === "Distributor"
  //                                   ? false
  //                                   : true
  //                               }
  //                             >
  //                               {t("pages.title.continue")}
  //                             </Button>
  //                             <Button
  //                               disabled={true}
  //                               color="warning"
  //                               size="small"
  //                               onClick={handleBack}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.back")}
  //                             </Button>
  //                           </div>
  //                         </Div>
  //                       </StepContent>
  //                     </Step>
  //                     <Step>
  //                       <StepLabel>{t("pages.title.kycdocuments")}</StepLabel>
  //                       <StepContent>
  //                         <Typography>
  //                           {loginUserData?.kyc_status === "Unverified" ? (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               onClick={() => setBecomeAffiliateOpen(true)}
  //                             >
  //                               {t("pages.title.kyc")}
  //                             </Button>
  //                           ) : loginUserData?.kyc_status === "Uploaded" ? (
  //                             <Button
  //                               color="primary"
  //                               variant="contained"
  //                               onClick={() =>
  //                                 sweetAlerts(
  //                                   "info",
  //                                   "info",
  //                                   "You have already uploaded your documents. It will be verify soon. Thank You!"
  //                                 )
  //                               }
  //                             >
  //                               {t("pages.title.kyc")}
  //                             </Button>
  //                           ) : (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               disabled={true}
  //                             >
  //                               {t("pages.title.youverified")}
  //                             </Button>
  //                           )}
  //                         </Typography>
  //                         <Div sx={{ mb: 2 }}>
  //                           <div>
  //                             <Button
  //                               variant="contained"
  //                               size="small"
  //                               color="success"
  //                               onClick={handleNext}
  //                               sx={{ mt: 1, mr: 1 }}
  //                               disabled={
  //                                 loginUserData?.kyc_status === "Verified"
  //                                   ? false
  //                                   : true
  //                               }
  //                             >
  //                               {t("pages.title.continue")}
  //                             </Button>
  //                             <Button
  //                               disabled={false}
  //                               color="warning"
  //                               size="small"
  //                               onClick={handleBack}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.back")}
  //                             </Button>
  //                           </div>
  //                         </Div>
  //                       </StepContent>
  //                     </Step>
  //                     <Step>
  //                       <StepLabel
  //                         optional={
  //                           <Typography variant="caption">
  //                             {t("pages.title.laststep")}
  //                           </Typography>
  //                         }
  //                       >
  //                         {t("pages.title.payoutinformation")}
  //                       </StepLabel>
  //                       <StepContent>
  //                         <Typography>
  //                           {loginUserData?.bank_account_title === null &&
  //                             loginUserData?.wallet_address === null &&
  //                             loginUserData?.outside_bank_account_title ===
  //                             null &&
  //                             loginUserData?.porequestcount === 0 ? (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               onClick={() =>
  //                                 setUpdatePayoutInformationOpen(true)
  //                               }
  //                             >
  //                               {t("pages.title.updatepayoutinformation")}
  //                             </Button>
  //                           ) : loginUserData?.porequestcount > 0 ? (
  //                             <Button
  //                               color="primary"
  //                               variant="contained"
  //                               onClick={() =>
  //                                 sweetAlerts(
  //                                   "info",
  //                                   "info",
  //                                   "Your request already submitted to admin, it will be verify soon."
  //                                 )
  //                               }
  //                             >
  //                               {t("pages.title.updatepayoutinformation")}
  //                             </Button>
  //                           ) : (
  //                             <Button
  //                               size="small"
  //                               variant="contained"
  //                               color="primary"
  //                               onClick={() => {
  //                                 setCurrentPayoutDetails(true);
  //                               }}
  //                             >
  //                               {t("pages.title.updatepayoutinformation")}
  //                             </Button>
  //                           )}
  //                         </Typography>
  //                         <Div sx={{ mb: 2 }}>
  //                           <div>
  //                             <Button
  //                               variant="contained"
  //                               size="small"
  //                               color="success"
  //                               onClick={handleNext}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.finish")}
  //                             </Button>
  //                             <Button
  //                               disabled={false}
  //                               color="warning"
  //                               size="small"
  //                               onClick={handleBack}
  //                               sx={{ mt: 1, mr: 1 }}
  //                             >
  //                               {t("pages.title.back")}
  //                             </Button>
  //                           </div>
  //                         </Div>
  //                       </StepContent>
  //                     </Step>
  //                   </Stepper>
  //                 </Div>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="grid-box">
  //             <div className="actual-payout">
  //               <h3>
  //                 {t("pages.title.YourActualPayout%isBasedontheFollowing")}:
  //               </h3>
  //               <div className="NVL-table">
  //                 <table>
  //                   <thead>
  //                     <tr>
  //                       <th>{t("pages.title.DirectActives")}</th>
  //                       {dashboardData?.uniLevelData?.unilevel?.map(
  //                         (data, index) => (
  //                           <th key={index}>{data?.number_of_users}</th>
  //                         )
  //                       )}
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr>
  //                       <td>{t("pages.title.Niveau1")}</td>
  //                       {dashboardData?.uniLevelData?.unilevel?.map(
  //                         (data, index) => (
  //                           <td key={index}>
  //                             {data?.level1 === 0 ? 10 : data?.level1}%
  //                           </td>
  //                         )
  //                       )}
  //                     </tr>
  //                     <tr>
  //                       <td>{t("pages.title.Niveau2")}</td>
  //                       {dashboardData?.uniLevelData?.unilevel?.map(
  //                         (data, index) => (
  //                           <td key={index}>{data?.level2}%</td>
  //                         )
  //                       )}
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="grid-box">
  //             <h2>{t("pages.title.WhatisCompanyRevenueSharing")}</h2>
  //             <p>{t("pages.title.Earnapourcentagedetail")}</p>
  //             <div className="spacer"></div>
  //             <div className="spacer"></div>
  //             <h3>{t("pages.title.QualificationCriteria")}</h3>
  //             <div className="medals-grid">
  //               <div className="medal-card">
  //                 <img src={stoneImg1} alt="" />
  //                 <h3>
  //                   {dashboardData?.qualificationCriteria?.pool[0]?.pool_name}{" "}
  //                   (1%)
  //                 </h3>
  //                 <p>
  //                   <span>{t("pages.title.Make")}</span>{" "}
  //                   <span>
  //                     {
  //                       dashboardData?.qualificationCriteria?.pool[0]
  //                         ?.number_of_users
  //                     }
  //                   </span>{" "}
  //                   <span>{t("pages.title.newsales")}</span>
  //                 </p>
  //               </div>
  //               <div className="medal-card">
  //                 <img src={stoneImg2} alt="" />
  //                 <h3>
  //                   {dashboardData?.qualificationCriteria?.pool[1]?.pool_name}{" "}
  //                   (1%)
  //                 </h3>
  //                 <p>
  //                   <p>
  //                     <span>{t("pages.title.Make")}</span>{" "}
  //                     <span>
  //                       {
  //                         dashboardData?.qualificationCriteria?.pool[1]
  //                           ?.number_of_users
  //                       }
  //                     </span>{" "}
  //                     <span>{t("pages.title.newsales")}</span>
  //                   </p>
  //                 </p>
  //               </div>
  //               <div className="medal-card">
  //                 <img src={stoneImg3} alt="" />
  //                 <h3>
  //                   {dashboardData?.qualificationCriteria?.pool[2]?.pool_name}{" "}
  //                   (1%)
  //                 </h3>
  //                 <p>
  //                   <p>
  //                     <span>{t("pages.title.Make")}</span>{" "}
  //                     <span>
  //                       {
  //                         dashboardData?.qualificationCriteria?.pool[2]
  //                           ?.number_of_users
  //                       }
  //                     </span>{" "}
  //                     <span>{t("pages.title.newsales")}</span>
  //                   </p>
  //                 </p>
  //               </div>
  //             </div>
  //             <div className="spacer"></div>
  //             <div className="spacer"></div>
  //             <div className="spacer"></div>
  //             <div className="current-status">
  //               <h3>
  //                 {dashboardData?.qualificationCriteria?.userPool[0]?.id ===
  //                   0 ? null : (
  //                   <span>
  //                     {t("pages.title.YourCurrentStatusis")}{" "}
  //                     {
  //                       dashboardData?.qualificationCriteria?.userPool[0]
  //                         ?.pool_name
  //                     }
  //                   </span>
  //                 )}

  //                 {dashboardData?.qualificationCriteria?.userPool[0]?.id ===
  //                   1 ? (
  //                   <img src={stoneImg1} alt="" />
  //                 ) : dashboardData?.qualificationCriteria?.userPool[0]?.id ===
  //                   2 ? (
  //                   <img src={stoneImg2} alt="" />
  //                 ) : dashboardData?.qualificationCriteria?.userPool[0]?.id ===
  //                   3 ? (
  //                   <img src={stoneImg3} alt="" />
  //                 ) : null}
  //               </h3>
  //               {dashboardData?.qualificationCriteria?.userPool[0]?.id < 3 ? (
  //                 <span>
  //                   <span>{t("pages.title.Make")}</span>{" "}
  //                   <span>
  //                     {dashboardData?.qualificationCriteria?.requiredUsers}
  //                   </span>{" "}
  //                   <span>{t("pages.title.moresales")}</span>{" "}
  //                   <span>
  //                     {
  //                       dashboardData?.qualificationCriteria?.pool[
  //                         dashboardData?.qualificationCriteria?.userPool[0]?.id
  //                       ]?.pool_name
  //                     }
  //                   </span>
  //                 </span>
  //               ) : null}
  //             </div>
  //             <div className="spacer"></div>
  //             <div className="spacer"></div>
  //             <div className="spacer"></div>

  //             <div className="willbe-paid-grid">
  //               <div className="willbe-paid-card">
  //                 <h4>
  //                   $
  //                   {dashboardData?.qualificationCriteria?.pool[0]?.amount?.toFixed(
  //                     2
  //                   )}
  //                 </h4>
  //                 <div className="flex">
  //                   <img src={stoneImg1} alt="" />
  //                   <h3>
  //                     {dashboardData?.qualificationCriteria?.pool[0]?.pool_name}{" "}
  //                     (1%) <span>{t("pages.title.willbepaidin")} </span>{" "}
  //                   </h3>
  //                 </div>
  //                 <div className="timer">
  //                   <div className="timer-box">
  //                     <h5>
  //                       {timeLeftOneMonth.days}{" "}
  //                       <span>{t("pages.title.Days")}</span>
  //                     </h5>
  //                     <span>:</span>
  //                     <h5>
  //                       {timeLeftOneMonth.hours}{" "}
  //                       <span>{t("pages.title.Hours")}</span>
  //                     </h5>
  //                     <span>:</span>
  //                     <h5>
  //                       {timeLeftOneMonth.minutes}{" "}
  //                       <span>{t("pages.title.Minutes")}</span>
  //                     </h5>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="willbe-paid-card">
  //                 <h4>
  //                   $
  //                   {dashboardData?.qualificationCriteria?.pool[1]?.amount?.toFixed(
  //                     2
  //                   )}
  //                 </h4>
  //                 <div className="flex">
  //                   <img src={stoneImg2} alt="" />
  //                   <h3>
  //                     {dashboardData?.qualificationCriteria?.pool[1]?.pool_name}{" "}
  //                     (1%) <span>{t("pages.title.willbepaidin")} </span>{" "}
  //                   </h3>
  //                 </div>
  //                 <div className="timer">
  //                   <div className="timer-box">
  //                     <h5>
  //                       {timeLeftThreeMonth.days}{" "}
  //                       <span>{t("pages.title.Days")}</span>
  //                     </h5>
  //                     <span>:</span>
  //                     <h5>
  //                       {timeLeftThreeMonth.hours}{" "}
  //                       <span>{t("pages.title.Hours")}</span>
  //                     </h5>
  //                     <span>:</span>
  //                     <h5>
  //                       {timeLeftThreeMonth.minutes}{" "}
  //                       <span>{t("pages.title.Minutes")}</span>
  //                     </h5>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="willbe-paid-card">
  //                 <h4>
  //                   $
  //                   {dashboardData?.qualificationCriteria?.pool[2]?.amount?.toFixed(
  //                     2
  //                   )}
  //                 </h4>
  //                 <div className="flex">
  //                   <img src={stoneImg3} alt="" />
  //                   <h3>
  //                     {dashboardData?.qualificationCriteria?.pool[2]?.pool_name}{" "}
  //                     (1%) <span>{t("pages.title.willbepaidin")} </span>{" "}
  //                   </h3>
  //                 </div>
  //                 <div className="timer">
  //                   <div className="timer-box">
  //                     <h5>
  //                       {timeLeftSixMonth.days}{" "}
  //                       <span>{t("pages.title.Days")}</span>
  //                     </h5>
  //                     <span>:</span>
  //                     <h5>
  //                       {timeLeftSixMonth.hours}{" "}
  //                       <span>{t("pages.title.Hours")}</span>
  //                     </h5>
  //                     <span>:</span>
  //                     <h5>
  //                       {timeLeftSixMonth.minutes}{" "}
  //                       <span>{t("pages.title.Minutes")}</span>
  //                     </h5>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>

  //             <div className="spacer"></div>
  //             <div className="spacer"></div>

  //             <h3>{t("pages.title.MyTotalNewSales")}</h3>
  //             <div className="new-sales-grid">
  //               {renderIcons(dashboardData?.totalNewSale?.sale)}
  //             </div>
  //           </div>
  //           <div className="grid-box statistics">
  //             <h2>{t("pages.title.Statistics")}</h2>
  //             <div className="box-head">
  //               <h3>{t("pages.title.NewCustomers")}</h3>
  //               <div className="btn-grid">
  //                 <Link
  //                   to={"/referrals"}
  //                   className="btn-purple-light"
  //                   style={{ textDecoration: "none" }}
  //                 >
  //                   {" "}
  //                   <GridOnIcon /> {t("pages.title.ViewAllCustomers")}
  //                 </Link>
  //                 <Link
  //                   to={"/personal-referrals-report"}
  //                   className="btn-purple-light"
  //                   style={{ textDecoration: "none" }}
  //                 >
  //                   {" "}
  //                   <ReorderIcon /> {t("pages.title.ViewAllCustomers")}
  //                 </Link>
  //               </div>
  //             </div>
  //             <div className="spacer"></div>
  //             <div className="NVL-table">
  //               <table>
  //                 <thead>
  //                   <tr>
  //                     <th></th>
  //                     <th>{t("pages.title.Name")}</th>
  //                     <th>{t("pages.title.Plan")}</th>
  //                     <th>{t("pages.title.Price")}</th>
  //                     <th>{t("pages.title.JoiningDate")}</th>
  //                     <th>{t("pages.title.SponsoredBy")}</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {dashboardData?.referralNewUserData?.referralUserData
  //                     ?.length === 0 ? (
  //                     <tr>
  //                       <td colSpan="6">
  //                         {t("pages.title.Noanynewsaleincurrentmonth")}
  //                       </td>
  //                     </tr>
  //                   ) : (
  //                     <>
  //                       {dashboardData?.referralNewUserData?.referralUserData?.map(
  //                         (userData, index) => (
  //                           <tr key={index}>
  //                             <td>
  //                               <StarOutlineIcon />
  //                             </td>
  //                             <td>
  //                               <div className="newcus-box">
  //                                 <img
  //                                   src={
  //                                     dashboardData?.referralNewUserData
  //                                       ?.pictureUrl + userData?.picture
  //                                   }
  //                                   alt=""
  //                                 />
  //                                 <div>
  //                                   <p>
  //                                     {userData?.firstname +
  //                                       " " +
  //                                       userData?.lastname}
  //                                   </p>
  //                                   <span>{userData?.email}</span>
  //                                 </div>
  //                               </div>
  //                             </td>
  //                             <td>{userData?.pkg_name}</td>
  //                             <td>{userData?.amount + userData?.currency}</td>
  //                             <td>{userData?.createdat}</td>
  //                             <td>{loginUserData?.username}</td>
  //                           </tr>
  //                         )
  //                       )}
  //                     </>
  //                   )}
  //                 </tbody>
  //               </table>
  //             </div>
  //             <div className="spacer"></div>
  //             <div className="spacer"></div>
  //             <div className="btn-grid">
  //               <div>
  //                 <h3>{t("pages.title.MonthlyNewSales")}</h3>
  //                 <BarChart
  //                   xAxis={[
  //                     {
  //                       scaleType: "band",
  //                       data: dashboardData?.newSaleGraph?.month || ["Jan"],
  //                     },
  //                   ]}
  //                   series={[
  //                     {
  //                       label: "EUR",
  //                       data: dashboardData?.newSaleGraph?.eur || [0],
  //                       color: "rgba(252, 183, 70, 0.25)",
  //                     },
  //                     {
  //                       label: "USD",
  //                       data: dashboardData?.newSaleGraph?.usd || [0],
  //                       color: "rgba(252, 183, 70, 0.65)",
  //                     },
  //                   ]}
  //                   height={300}
  //                 />
  //               </div>
  //               <div>
  //                 <h3>{t("pages.title.MonthlyActiveCustomers")}</h3>
  //                 <BarChart
  //                   xAxis={[
  //                     {
  //                       scaleType: "band",
  //                       data: dashboardData?.activeCustomersGraph?.month || [
  //                         "Jan",
  //                       ],
  //                     },
  //                   ]}
  //                   series={[
  //                     {
  //                       label: "Active",
  //                       data: dashboardData?.activeCustomersGraph?.active || [
  //                         0,
  //                       ],
  //                       color: "rgba(107, 33, 168, 0.25)",
  //                     },
  //                     {
  //                       label: "Renewed",
  //                       data: dashboardData?.activeCustomersGraph?.renewed || [
  //                         0,
  //                       ],
  //                       color: "rgba(107, 33, 168, 0.65)",
  //                     },
  //                   ]}
  //                   height={300}
  //                 />
  //               </div>
  //               <div className="monthly-earnings">
  //                 <div className="box-head">
  //                   <h3>{t("pages.title.MonthlyEarnings")}</h3>
  //                   <button
  //                     onClick={() => navigate("/level-bonus-report")}
  //                     className="btn-purple-light"
  //                   >
  //                     {t("pages.title.SeeFullReport")}
  //                   </button>
  //                 </div>
  //                 <BarChart
  //                   xAxis={[
  //                     {
  //                       scaleType: "band",
  //                       data: dashboardData?.earningGraph?.month || ["Jan"],
  //                     },
  //                   ]}
  //                   series={[
  //                     {
  //                       label: "EUR",
  //                       data: dashboardData?.earningGraph?.eur || [0],
  //                       color: "#e4e4e7",
  //                     },
  //                     {
  //                       label: "USD",
  //                       data: dashboardData?.earningGraph?.usd || [0],
  //                       color: "#52525b",
  //                     },
  //                   ]}
  //                   height={400}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     )}
  //   </>
  // );
};

export default Crypto;

import React, { useContext, useState } from "react";
import { Grid, Button, Paper, Typography, Icon, Box } from "@mui/material";
import VideoIcon from "@mui/icons-material/VideoLibrary";
import { CampaignContext } from "./context/campaignContext";
import axios from "axios";
import Campaign from "./campaign";
import { API_BASE_URL } from "backendServices/ApiCalls";
import { useEffect } from "react";
import { useJumboTheme } from "@jumbo/hooks";
import { useTranslation } from "react-i18next";

const Review = ({ showMessage }) => {
  const { t } = useTranslation();

  const [showIntervalCampaign, setShowIntervalCampaign] = useState(false);
  const {
    groupDetail,
    interval,
    message,
    setGroupDetail,
    setmessage,
    setInterval,
    messageName,
    userIds,
  } = useContext(CampaignContext);
  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);

  const handleNextClick = async () => {
    try {
      const response = await axios.post(API_BASE_URL + "/compaigns", {
        time_interval: interval,
        user_id: 1,
        group_id: groupDetail.id,
        message_id: message,
      });

      setGroupDetail({});
      setmessage("");
      setInterval("");
      setShowIntervalCampaign(true);
    } catch (error) {}
  };

  const handleBack = () => {
    showMessage();
  };

  return (
    <>
      {!showIntervalCampaign ? (
        <Paper elevation={3} style={{ padding: "2rem 4rem", width: "50%" }}>
          <Grid container flexDirection="column" gap={2}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                container
                sx={{ textAlign: "center" }}
                fontSize="1.6rem"
                marginBottom="1.5rem"
                color="primary"
              >
                {t("pages.title.review_lunch")}
              </Typography>
              <Typography
                variant="h6"
                container
                sx={{ textAlign: "center" }}
                color="grey"
              >
                {t("pages.title.target_label")}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid container flexDirection="column" gap={2}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="baseline"
                    justifyContent="center"
                    gap={1}
                  >
                    <Typography variant="h5">
                      {t("pages.title.total")}
                    </Typography>
                    <Box
                      sx={{
                        background: "#f5f5f5",
                        color: "#ab47bc",
                        padding: ".2rem 1rem",
                        borderRadius: 4,
                      }}
                    >
                      {groupDetail.numPeopleInGroup}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="baseline"
                    justifyContent="center"
                    gap={1}
                  >
                    <Typography variant="h5">
                      {t("pages.title.interval")}
                    </Typography>
                    <Box
                      sx={{
                        background: "#f5f5f5",
                        color: "#ab47bc",
                        padding: ".2rem 1rem",
                        borderRadius: 4,
                      }}
                    >
                      {interval}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="baseline"
                    justifyContent="center"
                    gap={1}
                  >
                    {" "}
                    <Typography variant="h5">
                      {t("pages.title.message_send")}
                    </Typography>
                    <Box
                      sx={{
                        background: "#f5f5f5",
                        color: "#ab47bc",
                        padding: ".2rem 1rem",
                        borderRadius: 4,
                      }}
                    >
                      {messageName}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ mt: 4 }}>
              <Grid
                container
                gap=".5rem"
                justifyContent="center"
                alignItems="center"
                xs={12}
                margin="1rem 0"
              >
                <Icon color="primary">
                  <VideoIcon />
                </Icon>{" "}
                <a
                  href={
                    storedLocale === "fr-FR"
                      ? "https://player.vimeo.com/video/867896920?badge=0&autopause=0&player_id=0&app_id=58479"
                      : "https://player.vimeo.com/video/867930211?badge=0&autopause=0&player_id=0&app_id=58479"
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    {storedLocale === "fr-FR"
                      ? "VOIR LA VIDEO EXPLICATIVE"
                      : "Watch Tutorial Video"}
                  </span>
                </a>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "lightgrey",
                    color: "white",
                    ":hover": {
                      backgroundColor: "darkgrey",
                    },
                  }}
                  onClick={handleBack}
                >
                  <span>{t("pages.title.back")}</span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  id="submit-campaign"
                  attr-data={JSON.stringify({
                    time_interval: interval,
                    group_id: groupDetail.id,
                    message_id: message,
                    userIds: userIds,
                  })}
                >
                  <span>{t("pages.title.submit")}</span>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : (
        ""
      )}
      {showIntervalCampaign ? <Campaign /> : ""}
    </>
  );
};

export default Review;

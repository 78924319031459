import { useContext, useEffect } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Modal } from "@mui/material";
import "./assets/css/popup.css";
import { useTranslation } from "react-i18next";
import React from "react";

export const UpdateExtensionModelState = ({isOpen,setIsOpen,values}) => {
  const [open, setOpen] = React.useState(isOpen);
  const handleClose = () => {
    setIsOpen(false)
  };
  const { loginUserData } = useContext(CustomProvider);
  const { t } = useTranslation();
  const token = localStorage.getItem("token")
 
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  if(!token){
    return null
  } 

  const buttonClick = (e) => {
    e.preventDefault();
    const updateExtensionEvent = new CustomEvent('UpdateExtension', { detail: {} });
    window.dispatchEvent(updateExtensionEvent);
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="promo-modal-title"
      aria-describedby="promo-modal-description"
      className="version-pop-main" 
      style={{display:"none"}}
      onClick= {handleClose}
       id="update-extension-bar"
      data-version={values?.extension_version}
    >
      <div      
>
        <div className="version-pop-warper ctm-scroll" onClick={stopPropagation}>
          <div className="version-pop-content">
            <div className="version-pop-title">
              👋{" "}
              <span className="version-pop-username">
                {loginUserData?.firstname}
              </span>
              , {t("pages.title.A NEW VERSION IS AVAILABLE")}!
            </div>
            <div className="version-pop-video-wraper">
              <iframe
                className="version-pop-video-content"
                width="560"
                height="315"
                src={t("pages.title.update_extension_url")+"&autoplay=1&muted=1"}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
            <button type="button" className="version-pop-btn" id="update_ext_btn" onClick={buttonClick}>
              {t("pages.title.update_now")}
              <svg
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.9"
                  d="M1 10.7148L5.71429 6.00056L1 1.28627"
                  stroke="white"
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  opacity="0.9"
                  d="M7.28516 10.7148L11.9994 6.00056L7.28516 1.28627"
                  stroke="white"
                  strokeWidth="1.75"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

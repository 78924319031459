import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import PopularArticles from "app/shared/widgets/PopularArticles";
import Div from "@jumbo/shared/Div/Div";
import { getnews } from "backendServices/ApiCalls";

const News = () => {
  const [loader, setloader] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");

  const getNewsFunction = () => {
    setloader(true);
    getnews(
      (response) => {
        setNewsData(response?.data?.news);
        setImageUrl(response?.data?.imageUrl);
        setloader(false);
      },
      (error) => {
        setloader(false);
      }
    );
  };

  useEffect(() => {
    // Assuming you have the user's email in some variable, e.g., userEmail
    const userEmail = "zeeshan@domain.com";

    // Set a global variable to make it accessible in your index.html
    window.userEmail = userEmail;
  }, []);

  useEffect(() => {
    getNewsFunction();
  }, []);

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12}>
        <PopularArticles newsData={newsData} imageUrl={imageUrl} />
      </Grid>
    </Grid>
  );
};

export default News;

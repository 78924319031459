import { ASSET_IMAGES } from "app/utils/constants/paths";
import { useTranslation } from "react-i18next";

export function FifthStep() {
  const { t } = useTranslation();
  return (
    <div className="bordered-cards">
      <img
        className="welcome-img"
        src={`${ASSET_IMAGES}/welcome-img.png`}
        alt="Welcome Image"
        loading="lazy"
      />
      <span className="gredient-title">
        {t("pages.title.congratulations")}!
      </span>
      <p className="gray-text">
        {t("pages.title.one_click_away")} <br />
        {t("pages.title.finding_new_leads")}
      </p>
      <button
        className="bordered-btn bordered-btn-bg w-100"
        id="start-novayla-connect"
      >
        {t("pages.title.start_with_novalya")}!
      </button>
    </div>
  );
}

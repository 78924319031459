import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { getsinglemessage } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MessageDetails = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { messageid } = useParams();
  const nav = useNavigate();
  const [messagedata, setMessageData] = useState([]);
  const MessageData = () => {
    let params = {
      messageid,
    };
    setLoading(true);
    getsinglemessage(
      params,
      (response) => {
        setLoading(false);
        setMessageData(response?.data?.data[0]);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    MessageData();
  }, [nav]);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <JumboDemoCard
      title={t("pages.title.messagedetails")}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <Typography variant="h4" mt={3} align="center">
          {messagedata.title}
        </Typography>
        <Typography variant="body">{messagedata.message}</Typography>
      </Box>
    </JumboDemoCard>
  );
};

export default MessageDetails;

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { SubscriptionReportApi } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";

const VISIBLE_FIELDS = ["sr", "sender", "amount", "date"];
const SubscriptionReport = () => {
  const theme = useTheme();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [subscriptionreport, setSubscriptionReport] = useState([]);
  const convertTimestampToDate = (timestamp) => {
    const dateObject = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    return dateObject.toISOString(); // Convert to ISO 8601 date string format (e.g., "2023-07-21T14:36:12.000Z")
  };

  const SubscriptionReportData = () => {
    setLoading(true);
    SubscriptionReportApi(
      (response) => {
        setLoading(false);
        const formattedData = response?.data?.data?.enteries.map((entry) => ({
          ...entry,
          activatedAt: convertTimestampToDate(entry.activatedAt),
          nextBillingAt: convertTimestampToDate(entry.nextBillingAt),
        }));
        setSubscriptionReport(formattedData);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    SubscriptionReportData();
  }, []);

  const rowsWithIndex = subscriptionreport.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "index",
      headerName: "#",
      width: 80,
      valueGetter: (params) => params.row.id,
    },
    // {
    //     field: "id",
    //     headerName: "ID",
    //     dataGeneratorUniquenessEnabled: true,
    //     width: 200,
    //     editable: true,
    //     groupable: false,
    //     aggregable: false,
    // },
    {
      field: "planid",
      headerName: "Package",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => `€${params.value}`,
    },
    {
      field: "activatedAt",
      headerName: "Activated At",
      dataGeneratorUniquenessEnabled: true,
      width: 215,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        const { value } = params;
        const date = new Date(value); // Convert UNIX timestamp to milliseconds
        const options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZoneName: "short",
        };
        return (
          <TableCell>
            {new Intl.DateTimeFormat("default", options).format(date)}
          </TableCell>
        );
      },
    },
    {
      field: "nextBillingAt",
      headerName: "Next Billing At",
      dataGeneratorUniquenessEnabled: true,
      width: 215,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        const { value } = params;
        const date = new Date(value); // Convert UNIX timestamp to milliseconds
        const options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZoneName: "short",
        };
        return (
          <TableCell>
            {new Intl.DateTimeFormat("default", options).format(date)}
          </TableCell>
        );
      },
    },
  ];

  return (
    <JumboDemoCard
      title={t("pages.title.subscriptionreport")}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={rowsWithIndex}
          columns={columns}
          // slots={{ toolbar: GridToolbar }}
          slots={{
            toolbar: (props) => (
              <GridToolbar
                {...props}
                sx={{
                  backgroundColor:
                    theme.palette.mode === "dark" ? "#424242" : "#fff",
                  "& .MuiIconButton-root": {
                    color: theme.palette.mode === "dark" ? "#fff" : "#000",
                  },
                }}
              />
            ),
          }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
};

export default SubscriptionReport;

import React, { useContext, useEffect, useRef, useState } from "react";
import "./tree.css";
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  DialogContentText,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  DialogTitle,
  Slide,
  DialogActions,
  Dialog,
} from "@mui/material";
import MuiPopper from "@mui/material/Popper";
import {
  getBinaryTreeData,
  getSingleUserBinaryTreeData,
} from "backendServices/ApiCalls";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useNavigate } from "react-router-dom";
import Div from "@jumbo/shared/Div/Div";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { styled } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import StarIcon from "@mui/icons-material/Star";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { getAssetPath } from "app/utils/appHelpers";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import SearchUser from "./SearchUser";
import { useTranslation } from "react-i18next";
import Popover from "@mui/material/Popover";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Popper = styled(MuiPopper, {
  shouldForwardProp: (prop) => prop !== "arrow",
})(({ theme, arrow }) => ({
  zIndex: 1,
  "& > div": {
    position: "relative",
  },
  '&[data-popper-placement*="bottom"]': {
    "& > div": {
      marginTop: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
  },
  '&[data-popper-placement*="top"]': {
    "& > div": {
      marginBottom: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="right"]': {
    "& > div": {
      marginLeft: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
  },
  '&[data-popper-placement*="left"]': {
    "& > div": {
      marginRight: arrow ? 2 : 0,
    },
    "& .MuiPopper-arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const BinaryTree = () => {
  const { t } = useTranslation();
  const [userHierarchyData, setUserHierarchyData] = useState();
  const [topUserData, setTopUserData] = useState({});
  const { loginUserData } = useContext(CustomProvider);
  const [loading, setLoading] = useState(false);
  const [popoverLoading, setPopoverLoading] = useState(false);
  const [randomCode, setRandomCode] = useState(loginUserData?.randomcode);
  const [open, setOpen] = useState(false);
  // State for popover anchor element
  const [singleUserData, setSingleUserData] = useState({});
  const containerRef = useRef(null); // Ref to the container element
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [detailsPop, setDetailsPop] = React.useState(false);

  const getSingleUserTreeData = (id, topid, event) => {
    setOpen(true);
    handlePopoverOpen(event);
    setPopoverLoading(true);
    let params = {
      id: id,
      topid: topid,
    };
    getSingleUserBinaryTreeData(
      params,
      (response) => {
        setSingleUserData(response?.data?.data);
        setPopoverLoading(false);
      },
      (error) => {
        setPopoverLoading(false);
      }
    );
  };

  const HierArchyData = () => {
    setLoading(true);
    let params;
    let localRandomCode = localStorage.getItem("randomCode");
    if (localRandomCode) {
      params = {
        userrandomcode: localRandomCode,
      };
      localStorage.removeItem("randomCode");
    } else {
      params = {
        userrandomcode: loginUserData?.randomcode,
      };
    }
    getBinaryTreeData(
      params,
      (response) => {
        setUserHierarchyData(response?.data?.data);
        setTopUserData(response?.data?.data?.user1?.userRankSponsorData);
        setLoading(false);
      },
      (error) => {}
    );
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    HierArchyData();
  }, [loginUserData, randomCode]);

  const handleTreeView = (randomcode) => {
    localStorage.setItem("randomCode", randomcode);
    setRandomCode(randomcode);
    navigate("/binary-tree");
  };

  const openPop = Boolean(anchorEl);

  let rankColors = [
    "red",
    "yellow",
    "blue",
    "orange",
    "pink",
    "green",
    "purple",
    "maroon",
    "gold",
  ];

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Grid container spacing={3.75}>
      <Grid item xs={12} md={12} lg={12}>
        <Button
          sx={{ marginLeft: 2 }}
          onClick={() => setDetailsPop(true)}
          type="button"
          color="primary"
          variant="contained"
          size="small"
        >
          {t("pages.title.details")}
        </Button>
      </Grid>
      <Grid item xs={12} md={6} lg={12}>
        <Grid sx={{ textAlign: "center" }} container spacing={3.75}>
          <Grid item xs={12} md={4} lg={4}>
            {randomCode !== loginUserData?.randomcode ? (
              <Button
                onClick={() => handleTreeView(loginUserData?.randomcode)}
                type="button"
                color="primary"
                variant="contained"
                endIcon={<KeyboardDoubleArrowUpIcon />}
              >
                {t("pages.title.backto_Top")}
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <SearchUser setRandomCode={setRandomCode} />
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            {randomCode !== loginUserData?.randomcode ? (
              <Button
                onClick={() => handleTreeView(topUserData?.levelUpRandomCode)}
                type="button"
                color="primary"
                variant="contained"
                endIcon={<KeyboardDoubleArrowUpIcon />}
              >
                {t("pages.title.oneLevel_Up")}
              </Button>
            ) : null}
          </Grid>
        </Grid>

        <section
          className="management-hierarchy"
          id="tree"
          style={{ backgroundColor: "secondary" }}
        >
          <Box>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={openPop}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {popoverLoading ? (
                <Div
                  sx={{
                    display: "flex",
                    minWidth: 0,
                    alignItems: "center",
                    alignContent: "center",
                    height: 300,
                    width: 300,
                  }}
                >
                  <CircularProgress sx={{ m: "-40px auto 0" }} />
                </Div>
              ) : (
                <Div sx={{ width: 380 }}>
                  <Typography variant="h5" sx={{ padding: 1 }} color="initial">
                    {"Details of " +
                      singleUserData?.userRankSponsorData?.username}
                  </Typography>
                  <Box sx={{ textAlign: "center" }}>
                    {/* <Button onClick={() => handleTreeView(singleUserData?.userRankSponsorData?.randomcode)} type='button' color='primary' variant="contained" size='small'>{t('pages.title.viewtree')}</Button> */}
                    {/* <Button sx={{ marginLeft: 2 }} onClick={() => setOpen(false)} type='button' color='primary' variant="contained" size='small'>{t('pages.title.close')}</Button> */}
                    {/* <Button component={Link} to={popoverContent.link} color='primary' variant="contained" size='small'>View Tree</Button> */}
                  </Box>
                  <List disablePadding sx={{ mb: 2 }}>
                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.username")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData?.username}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <AccountCircleIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.fullname")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData
                                  ?.firstname +
                                  " " +
                                  singleUserData?.userRankSponsorData?.lastname}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        {!singleUserData?.crown ? (
                          <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                          >
                            <ListItemIcon
                              sx={{ minWidth: 36, color: "text.secondary" }}
                            >
                              <GroupAddIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  {t("pages.title.sponsorname")}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {
                                    singleUserData?.userRankSponsorData
                                      ?.sponsor_name
                                  }
                                </Typography>
                              }
                            />
                          </ListItem>
                        ) : null}
                      </Grid>
                      <Grid item md={12} lg={12} sm={12}>
                        {singleUserData?.crown ? (
                          <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                          >
                            <ListItemIcon
                              sx={{ minWidth: 36, color: "text.secondary" }}
                            >
                              <MarkEmailReadIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  {t("pages.title.email")}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {singleUserData?.userRankSponsorData?.email}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <GroupAddIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.affiliate")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData
                                  ?.user_type === "Distributor"
                                  ? "Yes"
                                  : "No"}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={6} lg={6} sm={6}>
                        {singleUserData?.crown ? (
                          <ListItem
                            alignItems="flex-start"
                            sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                          >
                            <ListItemIcon
                              sx={{ minWidth: 36, color: "text.secondary" }}
                            >
                              <PhoneInTalkIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  color="text.secondary"
                                >
                                  {t("pages.title.mobile")}
                                </Typography>
                              }
                              secondary={
                                <Typography
                                  variant="body1"
                                  color="text.primary"
                                >
                                  {singleUserData?.userRankSponsorData?.phone}
                                </Typography>
                              }
                            />
                          </ListItem>
                        ) : null}
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <MilitaryTechIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.payoutrank")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.userRankSponsorData?.rank_name}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                      <Grid item md={6} lg={6} sm={6}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <MilitaryTechIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.lifetimerank")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {
                                  singleUserData?.userRankSponsorData
                                    ?.life_time_rank_name
                                }
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <MilitaryTechIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.novafreerank")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {
                                  singleUserData?.userRankSponsorData
                                    ?.nova_rank_name
                                }
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <GroupAddIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.activepersonalLR")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.activeReferrals
                                  ?.personalActiveLeftCount +
                                  "/" +
                                  singleUserData?.activeReferrals
                                    ?.personalActiveRightCount}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>

                    <Grid sx={{ textAlign: "center" }} container>
                      <Grid item md={12} lg={12} sm={12}>
                        <ListItem
                          alignItems="flex-start"
                          sx={{ p: (theme) => theme.spacing(0.3, 2) }}
                        >
                          <ListItemIcon
                            sx={{ minWidth: 36, color: "text.secondary" }}
                          >
                            <AccountBalanceWalletIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body1"
                                color="text.secondary"
                              >
                                {t("pages.title.current_month_volumeLR")}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body1" color="text.primary">
                                {singleUserData?.binaryPoints
                                  ?.organizationLeftPointsCount +
                                  "/" +
                                  singleUserData?.binaryPoints
                                    ?.organizationRightPointsCount}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </List>
                </Div>
              )}
            </Popover>
          </Box>

          <div className="hv-container">
            <div id="wrapper" className="hv-wrapper">
              {/* start */}
              {/* Key component */}
              <div
                id="container"
                className="hv-item custom_zoom"
                ref={containerRef}
              >
                <div className="hv-item-parent">
                  {userHierarchyData?.user1?.crown ? (
                    <img
                      style={{
                        width: 40,
                        height: 25,
                        marginRight: -50,
                        float: "left",
                      }}
                      src={getAssetPath(`${ASSET_IMAGES}/crown.png`)}
                    />
                  ) : null}
                  <div
                    style={{ textDecoration: "none" }}
                    className="person"
                    onClick={() =>
                      userHierarchyData?.user1 !== null
                        ? handleTreeView(
                            userHierarchyData?.user1?.userRankSponsorData
                              ?.randomcode
                          )
                        : null
                    }
                  >
                    <img
                      src={
                        userHierarchyData?.user1?.userRankSponsorData?.picture
                          ? userHierarchyData?.imageUrl +
                            userHierarchyData?.user1?.userRankSponsorData
                              ?.picture
                          : userHierarchyData?.imageUrl + "profile.png"
                      }
                      alt=""
                      style={{
                        marginBottom: 5,
                        width: 80,
                        height: 80,
                        borderColor: "purple",
                      }}
                    />
                    <p
                      aria-owns={openPop ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      className="name"
                      style={{
                        backgroundColor: "#7352C7",
                        color: "white",
                        width: 175,
                      }}
                      onMouseEnter={(event) =>
                        userHierarchyData?.user1 !== null
                          ? getSingleUserTreeData(
                              userHierarchyData?.user1?.userRankSponsorData?.id,
                              userHierarchyData?.user1?.userRankSponsorData?.id,
                              event
                            )
                          : null
                      }
                      onMouseLeave={handlePopoverClose}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          background: userHierarchyData?.user1?.color,
                          borderRadius: "50%",
                          width: "10px",
                          height: "10px",
                          marginRight: 3,
                        }}
                      ></span>
                      {userHierarchyData?.user1?.userRankSponsorData
                        ?.username || "empty"}
                      <br />
                      {userHierarchyData?.user1?.userRankSponsorData
                        ?.rank_name === "Starter"
                        ? "No Rank"
                        : userHierarchyData?.user1?.userRankSponsorData
                            ?.rank_name}
                      {/* <span style={{
                        display: 'inline-block',
                        background: rankColors[userHierarchyData?.user1?.userRankSponsorData?.rank],
                        borderRadius: '50%',
                        width: '10px',
                        height: '10px',
                        marginLeft: 3
                      }}></span> */}
                      <br />
                      {/* {userHierarchyData?.user1?.userRankSponsorData?.nova_rank_name} */}
                      {userHierarchyData?.user1?.userRankSponsorData
                        ?.novarank === 1 ? (
                        <>
                          {
                            userHierarchyData?.user1?.userRankSponsorData
                              ?.nova_rank_name
                          }{" "}
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                        </>
                      ) : userHierarchyData?.user1?.userRankSponsorData
                          ?.novarank === 2 ? (
                        <>
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                        </>
                      ) : userHierarchyData?.user1?.userRankSponsorData
                          ?.novarank === 3 ? (
                        <>
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                          <StarIcon style={{ color: "gold", fontSize: 17 }} />
                        </>
                      ) : null}
                    </p>
                  </div>
                </div>
                <div className="hv-item-children">
                  <div className="hv-item-child">
                    {/* Key component */}
                    <div className="hv-item">
                      <div className="hv-item-parent">
                        {userHierarchyData?.user2?.crown ? (
                          <img
                            style={{
                              width: 40,
                              height: 25,
                              marginRight: -50,
                              float: "left",
                            }}
                            src={getAssetPath(`${ASSET_IMAGES}/crown.png`)}
                          />
                        ) : null}
                        <div
                          style={{ textDecoration: "none" }}
                          className="person"
                          onClick={() =>
                            userHierarchyData?.user2 !== null
                              ? handleTreeView(
                                  userHierarchyData?.user2?.userRankSponsorData
                                    ?.randomcode
                                )
                              : null
                          }
                        >
                          <img
                            src={
                              userHierarchyData?.user2?.userRankSponsorData
                                ?.picture
                                ? userHierarchyData?.imageUrl +
                                  userHierarchyData?.user2?.userRankSponsorData
                                    ?.picture
                                : userHierarchyData?.imageUrl + "profile.png"
                            }
                            alt=""
                            style={{
                              marginBottom: 5,
                              width: 80,
                              height: 80,
                              borderColor: "purple",
                            }}
                          />
                          {userHierarchyData?.user2 !== null ? (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                              onMouseEnter={(event) =>
                                userHierarchyData?.user2 !== null
                                  ? getSingleUserTreeData(
                                      userHierarchyData?.user2
                                        ?.userRankSponsorData?.id,
                                      userHierarchyData?.user1
                                        ?.userRankSponsorData?.id,
                                      event
                                    )
                                  : null
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  background: userHierarchyData?.user2?.color,
                                  borderRadius: "50%",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: 3,
                                }}
                              ></span>
                              {
                                userHierarchyData?.user2?.userRankSponsorData
                                  ?.username
                              }
                              <br />
                              {userHierarchyData?.user2?.userRankSponsorData
                                ?.rank_name === "Starter"
                                ? "No Rank"
                                : userHierarchyData?.user2?.userRankSponsorData
                                    ?.rank_name}
                              {/* <span style={{
                                  display: 'inline-block',
                                  background: rankColors[userHierarchyData?.user2?.userRankSponsorData?.rank],
                                  borderRadius: '50%',
                                  width: '10px',
                                  height: '10px',
                                  marginLeft: 3
                                }}></span> */}
                              <br />
                              {/* {userHierarchyData?.user2?.userRankSponsorData?.nova_rank_name} */}
                              {userHierarchyData?.user2?.userRankSponsorData
                                ?.novarank === 1 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user2?.userRankSponsorData
                                  ?.novarank === 2 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user2?.userRankSponsorData
                                  ?.novarank === 3 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : null}
                            </p>
                          ) : (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                            >
                              {t("pages.title.empty")}
                              <br></br>
                              N/A
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="hv-item-children">
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div className="hv-item">
                              <div className="">
                                {userHierarchyData?.user4?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user4 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user4
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.user4
                                        ?.userRankSponsorData?.picture
                                        ? userHierarchyData?.imageUrl +
                                          userHierarchyData?.user4
                                            ?.userRankSponsorData?.picture
                                        : userHierarchyData?.imageUrl +
                                          "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "purple",
                                    }}
                                  />
                                  {userHierarchyData?.user4 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={(event) =>
                                        userHierarchyData?.user4 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user4
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id,
                                              event
                                            )
                                          : null
                                      }
                                      onMouseLeave={handlePopoverClose}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            userHierarchyData?.user4?.color,
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginRight: 3,
                                        }}
                                      ></span>
                                      {userHierarchyData?.user4
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {userHierarchyData?.user4
                                        ?.userRankSponsorData?.rank_name ===
                                      "Starter"
                                        ? "No Rank"
                                        : userHierarchyData?.user4
                                            ?.userRankSponsorData?.rank_name}
                                      {/* <span style={{
                                          display: 'inline-block',
                                          background: rankColors[userHierarchyData?.user4?.userRankSponsorData?.rank],
                                          borderRadius: '50%',
                                          width: '10px',
                                          height: '10px',
                                          marginLeft: 3
                                        }}></span> */}
                                      <br />
                                      {/* {userHierarchyData?.user4?.userRankSponsorData?.nova_rank_name} */}
                                      {userHierarchyData?.user4
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user4
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user4
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                      <br></br>
                                      N/A
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div
                              className="hv-item"
                              style={{ marginBottom: 100 }}
                            >
                              <div className="">
                                {userHierarchyData?.user5?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user5 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user5
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.user5
                                        ?.userRankSponsorData?.picture
                                        ? userHierarchyData?.imageUrl +
                                          userHierarchyData?.user5
                                            ?.userRankSponsorData?.picture
                                        : userHierarchyData?.imageUrl +
                                          "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "purple",
                                    }}
                                  />
                                  {userHierarchyData?.user5 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={(event) =>
                                        userHierarchyData?.user5 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user5
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id,
                                              event
                                            )
                                          : null
                                      }
                                      onMouseLeave={handlePopoverClose}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            userHierarchyData?.user5?.color,
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginRight: 3,
                                        }}
                                      ></span>
                                      {userHierarchyData?.user5
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {userHierarchyData?.user5
                                        ?.userRankSponsorData?.rank_name ===
                                      "Starter"
                                        ? "No Rank"
                                        : userHierarchyData?.user5
                                            ?.userRankSponsorData?.rank_name}
                                      {/* <span style={{
                                          display: 'inline-block',
                                          background: rankColors[userHierarchyData?.user5?.userRankSponsorData?.rank],
                                          borderRadius: '50%',
                                          width: '10px',
                                          height: '10px',
                                          marginLeft: 3
                                        }}></span> */}
                                      <br />
                                      {/* {userHierarchyData?.user5?.userRankSponsorData?.nova_rank_name} */}
                                      {userHierarchyData?.user5
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user5
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user5
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                      <br></br>
                                      N/A
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hv-item-child">
                    {/* Key component */}
                    <div className="hv-item">
                      <div className="hv-item-parent">
                        {userHierarchyData?.user3?.crown ? (
                          <img
                            style={{
                              width: 40,
                              height: 25,
                              marginRight: -50,
                              float: "left",
                            }}
                            src={getAssetPath(`${ASSET_IMAGES}/crown.png`)}
                          />
                        ) : null}
                        <div
                          style={{ textDecoration: "none" }}
                          className="person"
                          onClick={() =>
                            userHierarchyData?.user3 !== null
                              ? handleTreeView(
                                  userHierarchyData?.user3?.userRankSponsorData
                                    ?.randomcode
                                )
                              : null
                          }
                        >
                          <img
                            src={
                              userHierarchyData?.user3?.userRankSponsorData
                                ?.picture
                                ? userHierarchyData?.imageUrl +
                                  userHierarchyData?.user3?.userRankSponsorData
                                    ?.picture
                                : userHierarchyData?.imageUrl + "profile.png"
                            }
                            alt=""
                            style={{
                              marginBottom: 5,
                              width: 80,
                              height: 80,
                              borderColor: "purple",
                            }}
                          />
                          {userHierarchyData?.user3 !== null ? (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                              onMouseEnter={(event) =>
                                userHierarchyData?.user3 !== null
                                  ? getSingleUserTreeData(
                                      userHierarchyData?.user3
                                        ?.userRankSponsorData?.id,
                                      userHierarchyData?.user1
                                        ?.userRankSponsorData?.id,
                                      event
                                    )
                                  : null
                              }
                              onMouseLeave={handlePopoverClose}
                            >
                              <span
                                style={{
                                  display: "inline-block",
                                  background: userHierarchyData?.user3?.color,
                                  borderRadius: "50%",
                                  width: "10px",
                                  height: "10px",
                                  marginRight: 3,
                                }}
                              ></span>
                              {
                                userHierarchyData?.user3?.userRankSponsorData
                                  ?.username
                              }
                              <br />
                              {userHierarchyData?.user3?.userRankSponsorData
                                ?.rank_name === "Starter"
                                ? "No Rank"
                                : userHierarchyData?.user3?.userRankSponsorData
                                    ?.rank_name}
                              {/* <span style={{
                                  display: 'inline-block',
                                  background: rankColors[userHierarchyData?.user3?.userRankSponsorData?.rank],
                                  borderRadius: '50%',
                                  width: '10px',
                                  height: '10px',
                                  marginLeft: 3
                                }}></span> */}
                              <br />
                              {/* {userHierarchyData?.user3?.userRankSponsorData?.nova_rank_name} */}
                              {userHierarchyData?.user3?.userRankSponsorData
                                ?.novarank === 1 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user3?.userRankSponsorData
                                  ?.novarank === 2 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : userHierarchyData?.user3?.userRankSponsorData
                                  ?.novarank === 3 ? (
                                <>
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                  <StarIcon
                                    style={{ color: "gold", fontSize: 17 }}
                                  />
                                </>
                              ) : null}
                            </p>
                          ) : (
                            <p
                              className="name"
                              style={{
                                backgroundColor: "#7352C7",
                                color: "white",
                                width: 175,
                              }}
                            >
                              {t("pages.title.empty")}
                              <br></br>
                              N/A
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="hv-item-children">
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div className="hv-item">
                              <div className="">
                                {userHierarchyData?.user6?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user6 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user6
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.user6
                                        ?.userRankSponsorData?.picture
                                        ? userHierarchyData?.imageUrl +
                                          userHierarchyData?.user6
                                            ?.userRankSponsorData?.picture
                                        : userHierarchyData?.imageUrl +
                                          "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "purple",
                                    }}
                                  />
                                  {userHierarchyData?.user6 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={(event) =>
                                        userHierarchyData?.user6 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user6
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id,
                                              event
                                            )
                                          : null
                                      }
                                      onMouseLeave={handlePopoverClose}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            userHierarchyData?.user6?.color,
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginRight: 3,
                                        }}
                                      ></span>
                                      {userHierarchyData?.user6
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {userHierarchyData?.user6
                                        ?.userRankSponsorData?.rank_name ===
                                      "Starter"
                                        ? "No Rank"
                                        : userHierarchyData?.user6
                                            ?.userRankSponsorData?.rank_name}
                                      {/* <span style={{
                                          display: 'inline-block',
                                          background: rankColors[userHierarchyData?.user6?.userRankSponsorData?.rank],
                                          borderRadius: '50%',
                                          width: '10px',
                                          height: '10px',
                                          marginLeft: 3
                                        }}></span> */}
                                      <br />
                                      {/* {userHierarchyData?.user6?.userRankSponsorData?.nova_rank_name} */}
                                      {userHierarchyData?.user6
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user6
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user6
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                      <br></br>
                                      N/A
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                        <div className="hv-item-child">
                          <div className="hv-item-child">
                            {/* Key component */}
                            <div className="hv-item">
                              <div className="">
                                {userHierarchyData?.user7?.crown ? (
                                  <img
                                    style={{
                                      width: 40,
                                      height: 25,
                                      marginRight: -50,
                                      float: "left",
                                    }}
                                    src={getAssetPath(
                                      `${ASSET_IMAGES}/crown.png`
                                    )}
                                  />
                                ) : null}
                                <div
                                  style={{ textDecoration: "none" }}
                                  className="person"
                                  onClick={() =>
                                    userHierarchyData?.user7 !== null
                                      ? handleTreeView(
                                          userHierarchyData?.user7
                                            ?.userRankSponsorData?.randomcode
                                        )
                                      : null
                                  }
                                >
                                  <img
                                    src={
                                      userHierarchyData?.user7
                                        ?.userRankSponsorData?.picture
                                        ? userHierarchyData?.imageUrl +
                                          userHierarchyData?.user7
                                            ?.userRankSponsorData?.picture
                                        : userHierarchyData?.imageUrl +
                                          "profile.png"
                                    }
                                    alt=""
                                    style={{
                                      marginBottom: 5,
                                      width: 80,
                                      height: 80,
                                      borderColor: "purple",
                                    }}
                                  />
                                  {userHierarchyData?.user7 !== null ? (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                      onMouseEnter={(event) =>
                                        userHierarchyData?.user7 !== null
                                          ? getSingleUserTreeData(
                                              userHierarchyData?.user7
                                                ?.userRankSponsorData?.id,
                                              userHierarchyData?.user1
                                                ?.userRankSponsorData?.id,
                                              event
                                            )
                                          : null
                                      }
                                      onMouseLeave={handlePopoverClose}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          background:
                                            userHierarchyData?.user7?.color,
                                          borderRadius: "50%",
                                          width: "10px",
                                          height: "10px",
                                          marginRight: 3,
                                        }}
                                      ></span>
                                      {userHierarchyData?.user7
                                        ?.userRankSponsorData?.username ||
                                        "empty"}
                                      <br />
                                      {userHierarchyData?.user7
                                        ?.userRankSponsorData?.rank_name ===
                                      "Starter"
                                        ? "No Rank"
                                        : userHierarchyData?.user7
                                            ?.userRankSponsorData?.rank_name}
                                      {/* <span style={{
                                          display: 'inline-block',
                                          background: rankColors[userHierarchyData?.user7?.userRankSponsorData?.rank],
                                          borderRadius: '50%',
                                          width: '10px',
                                          height: '10px',
                                          marginLeft: 3
                                        }}></span> */}
                                      <br />
                                      {/* {userHierarchyData?.user7?.userRankSponsorData?.nova_rank_name} */}
                                      {userHierarchyData?.user7
                                        ?.userRankSponsorData?.novarank ===
                                      1 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user7
                                          ?.userRankSponsorData?.novarank ===
                                        2 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : userHierarchyData?.user7
                                          ?.userRankSponsorData?.novarank ===
                                        3 ? (
                                        <>
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                          <StarIcon
                                            style={{
                                              color: "gold",
                                              fontSize: 17,
                                            }}
                                          />
                                        </>
                                      ) : null}
                                    </p>
                                  ) : (
                                    <p
                                      className="name"
                                      style={{
                                        backgroundColor: "#7352C7",
                                        color: "white",
                                        width: 175,
                                      }}
                                    >
                                      {t("pages.title.empty")}
                                      <br></br>
                                      N/A
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end */}
            </div>
          </div>
        </section>

        <Div>
          <Dialog
            open={detailsPop}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setDetailsPop(false)}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{t("pages.title.treedetails")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                  {t("pages.title.crownh")}
                </Typography>
                <ul style={{ color: "#666362", marginBottom: 10 }}>
                  <li>
                    <img
                      style={{ width: 25, height: 15 }}
                      src={getAssetPath(`${ASSET_IMAGES}/crown.png`)}
                    />
                    {t("pages.title.crownp")}
                  </li>
                </ul>
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                  {t("pages.title.paymenth")}
                </Typography>
                <ul style={{ color: "#666362", marginBottom: 10 }}>
                  <li>
                    <span
                      style={{
                        display: "inline-block",
                        background: "green",
                        borderRadius: "50%",
                        width: "12px",
                        height: "12px",
                        marginRight: 3,
                      }}
                    ></span>
                    {t("pages.title.paymentp1")}
                  </li>
                  <li>
                    <span
                      style={{
                        display: "inline-block",
                        background: "red",
                        borderRadius: "50%",
                        width: "12px",
                        height: "12px",
                        marginRight: 3,
                      }}
                    ></span>
                    {t("pages.title.paymentp2")}
                  </li>
                  <li>
                    <span
                      style={{
                        display: "inline-block",
                        background: "orange",
                        borderRadius: "50%",
                        width: "12px",
                        height: "12px",
                        marginRight: 3,
                      }}
                    ></span>
                    {t("pages.title.paymentp3")}
                  </li>
                  <li>
                    <span
                      style={{
                        display: "inline-block",
                        background: "blue",
                        borderRadius: "50%",
                        width: "12px",
                        height: "12px",
                        marginRight: 3,
                      }}
                    ></span>
                    {t("pages.title.paymentp4")}
                  </li>
                </ul>
                <Typography sx={{ fontWeight: "bold" }} variant="h5">
                  {t("pages.title.novah")}
                </Typography>
                <ul style={{ color: "#666362", marginBottom: 10 }}>
                  <li>
                    <StarIcon style={{ color: "gold", fontSize: 17 }} />
                    {t("pages.title.novap1")}
                  </li>
                  <li>
                    <StarIcon style={{ color: "gold", fontSize: 17 }} />
                    <StarIcon style={{ color: "gold", fontSize: 17 }} />
                    {t("pages.title.novap2")}
                  </li>
                  <li>
                    <StarIcon style={{ color: "gold", fontSize: 17 }} />
                    <StarIcon style={{ color: "gold", fontSize: 17 }} />
                    <StarIcon style={{ color: "gold", fontSize: 17 }} />
                    {t("pages.title.novap3")}
                  </li>
                </ul>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ marginTop: -4 }}>
              <Button onClick={() => setDetailsPop(false)}>
                {t("pages.title.close")}
              </Button>
            </DialogActions>
          </Dialog>
        </Div>
      </Grid>
    </Grid>
  );
};

export default BinaryTree;

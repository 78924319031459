import Box from "@mui/material/Box";

export const hiddenScrollSx = {
  overflow: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",
  "::-webkit-scrollbar": {
    display: "none",
  },
};

export function HiddenScroll({ children, sx = {}, ...props }) {
  return (
    <Box
      sx={{
        ...hiddenScrollSx,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

import React from 'react';
import { agents } from './data'
import AgentDetail from "./AgentDetail";
import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


const PopularAgents = ({ picturelink, data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    return (
        <React.Fragment>
            <Button onClick={() => navigate('/personal-referrals-report')} size='small' variant='contained'>List View</Button>
            <Typography variant={"h4"} sx={{ mb: 2, mt: 4 }}>{t("widgets.title.referralUsers")}</Typography>
            <Grid container spacing={3.75}>
                {
                    data?.map((agent, index) => {
                        let color;

                        if (agent?.subscription_status === 'Active' ||
                            agent?.subscription_status === 'subscription_renewed') {

                            // Convert the timestamp to a Date object
                            const timestamp = agent?.nextBillingAt;
                            const date = new Date(timestamp * 1000); // JavaScript uses milliseconds, so multiply by 1000

                            // Get the year, month, and day from the Date object
                            const year = date.getFullYear();
                            const month = date.getMonth() + 1; // Months are zero-based, so add 1
                            const day = date.getDate();

                            // Get the current date
                            const currentDate = new Date();
                            const currentYear = currentDate.getFullYear();
                            const currentMonth = currentDate.getMonth() + 1;
                            const currentDay = currentDate.getDate();

                            // Compare the day from the timestamp with the current day
                            if (currentDay <= day) {
                                color = 'blue';
                            } else {
                                color = 'green';
                            }

                        }
                        if (
                            agent?.subscription_status === 'subscription_cancelled' ||
                            agent?.subscription_status === 'payment_refunded'
                        ) {
                            color = 'red';
                        }
                        if (agent?.subscription_status === 'payment_failed') {
                            color = 'orange';
                        }

                        return (
                            <Grid item xs={12} sm={6} lg={4} key={index}>
                                <AgentDetail picturelink={picturelink} referraldetails={agent} color={color} />
                            </Grid>
                        );
                    })
                }

            </Grid>
        </React.Fragment>
    );
};
export default PopularAgents;

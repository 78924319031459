import {config} from "../../../app/config/main";

export const storeToken = (token) => {
    localStorage.setItem('token', token);
    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    // else
    //     //config.authSetting.axiosObject.defaults.headers.common['authorization'] = 'Bearer ' + token;
};

export const removeToken = () => {
    localStorage.removeItem('token');
    document.cookie = 'authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'user_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    if(!config?.authSetting?.axiosObject)
        throw Error("axiosObject need to be set under authSettings inside app/config/main.js");
    //else
        //delete config.authSetting.axiosObject.defaults.headers.common['authorization'];
};
import jwtAuthAxios from "./auth/jwtAuth";

const authServices = {};
function updateAuthorizationHeader() {
    const token = localStorage.getItem('token');
    jwtAuthAxios.defaults.headers.common['authorization'] = 'Bearer ' + token;
  }
  
authServices.getCurrentUser = async () => {
    try{
        updateAuthorizationHeader()
        const response = await jwtAuthAxios.post("/userdata/");
        return response.data
    } catch (e) {
        return {
            hasError: true,
            error: "Some error received from the api",
        };
    }
};

//loginCreds must be {email: "abc@example.com", password: "ABC123DEF"}
authServices.signIn = async (loginCreds) => {
    try {
        return await jwtAuthAxios.post('/login/', loginCreds);
    } catch (e) {
        return e.response;
    }

};

//login based on access token
authServices.manualSignIn = async (accesstoken) => {
    try {
        return await jwtAuthAxios.post('/manualsignin', accesstoken);
    } catch (e) {
        return e.response;
    }

};

export default authServices;
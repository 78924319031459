import { useCallback, useState, useMemo, useEffect } from "react";

export function useModalState(
  { delay = 0, closeOnEscape = false, startOpen = false } = {
    startOpen: false,
    closeOnEscape: false,
  }
) {
  const [rawIsOpen, setIsOpen] = useState(startOpen);
  const [scrollPosition, setScrollPosition] = useState(null);
  const isOpen = rawIsOpen && delay === 0 ? true : rawIsOpen;
  const onOpen = useCallback(() => {
    isClient(() => {
      setScrollPosition(window.pageYOffset);
    });

    setIsOpen(true);
  }, [setIsOpen]);

  function useValue(map) {
    return useMemo(() => map, Object.values(map));
  }
  function useKeydown({ isActive = true, el, callback }, memoArray = []) {
    useEffect(() => {
      if (isActive) {
        const target = el || window.document;

        target.addEventListener("keydown", callback);

        return () => target.removeEventListener("keydown", callback);
      }
    }, [isActive, callback, el, ...memoArray]);

    return null;
  }

  function isClient(callback) {
    const isClient = typeof window !== "undefined";

    return isClient && callback ? callback() : isClient;
  }

  function stopClick(e) {
    e?.preventDefault();
    e?.stopPropagation();
  }

  const onClose = useCallback(
    (e) => {
      stopClick(e);
      setIsOpen(false);

      if (isClient() && scrollPosition !== null) {
        setTimeout(() => {
          window.scrollTo(0, scrollPosition);

          setScrollPosition(null);
        }, 200);
      }
    },
    [scrollPosition, setIsOpen]
  );
  const toggle = useCallback(
    (e) => {
      stopClick(e);

      rawIsOpen ? onClose() : onOpen();
    },
    [onClose, onOpen, rawIsOpen]
  );
  const onKeydown = useCallback(
    (e) => {
      switch (e.key) {
        case "Escape":
          return onClose();
      }
    },
    [onClose]
  );

  useKeydown({ isActive: !!isOpen && closeOnEscape, callback: onKeydown });

  return useValue({ isOpen: !!isOpen, setIsOpen, onClose, onOpen, toggle });
}

import React, { useEffect } from "react";
import List from "@mui/material/List";
import { Box, Grid, Typography } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useState } from "react";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { getSinglePlans } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import CheckOutPlansDetails from "./CheckOutPlansDetails";
import Spinner from "app/shared/Spinner/Spinner";
import { deploy_user } from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { validate_coupon } from "backendServices/ApiCalls";

const validationSchema = yup.object({
  cardNumber: yup
    .string("Enter card number")
    .required("card number is required"),
});

const Checkoutform = (props) => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [singlePlan, setSinglePlan] = useState({});
  const [couponData, setcouponData] = useState({});
  const [discountText, setdiscountText] = useState(false);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate();

  const onSubmitForm = (data, setSubmitting) => {
    var strArray = data.expiry.split("/");
    let expiryMonth = strArray[0];
    let expiryYear = strArray[1];

    let params = {
      sponsorid: props?.regData?.sponsorid,
      username: props?.regData?.username,
      firstname: props?.regData?.firstname,
      lastname: props?.regData?.lastname,
      email: props?.regData?.email,
      password: props?.regData?.password,
      country: props?.regData?.country,
      company: props?.regData?.company,
      address1: props?.regData?.address,
      zipcode: props?.regData?.zipCode,
      city: props?.regData?.city,
      language: props?.regData?.language,
      mobile: props?.regData?.mobile,
      birthday: "",
      item_price_id: singlePlan?.id,
      // unit_price: singlePlan?.price,
      cardnumber: data.cardNumber,
      expirymonth: expiryMonth,
      expiryyear: expiryYear,
      cardcvc: data.cvv,
      coupon_code: data.coupon === "" ? "empty" : data.coupon,
    };
    deploy_user(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again later.",
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setSubmitting(false);
          navigate("/login");
        }
      },
      (error) => {}
    );
  };

  const callSinglePlan = () => {
    setLoader(false);
    let params = {
      itemid: localStorage.getItem("planId"),
    };
    getSinglePlans(
      params,
      (response) => {
        setSinglePlan(response.data.data.item_price);
        setLoader(false);
      },
      (error) => {}
    );
  };

  const handleCouponBlur = (e) => {
    let couponCode = e.target.value;
    if (couponCode === "" || couponCode === null) {
      couponCode = "empty";
    }
    let params = {
      couponcode: couponCode,
    };
    validate_coupon(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          setcouponData(response?.data?.coupondata);
          setdiscountText(true);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong. Please try again later.",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };
  useEffect(() => {
    callSinglePlan();
  }, []);

  if (loader) {
    return <Spinner />;
  }

  return (
    <JumboCardQuick title={t("pages.title.paymentinformation")}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid container>
        <Grid item xs={12} lg={7}>
          <List disablePadding>
            <Formik
              validateOnChange={true}
              enableReinitialize="true"
              initialValues={{
                cardNumber: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                onSubmitForm(data, setSubmitting);
              }}
            >
              {({ isSubmitting, values }) => (
                <Form noValidate autoComplete="off">
                  <Grid sm={12} xs={12} sx={{ my: 8 }}>
                    <Grid item sm={8} xs={12} sx={{ m: "auto" }}>
                      <JumboTextField
                        fullWidth
                        label="Card Number"
                        name="cardNumber"
                        type="text"
                      />
                    </Grid>

                    {discountText ? (
                      <Grid item sm={12} sx={{ width: { xs: "100%" } }}>
                        <Typography color={"red"} variant="h6">
                          Regular amount is: €
                          {(singlePlan?.price / 100)
                            .toFixed(2)
                            .replace(".", ",")}
                        </Typography>
                        <Typography color={"#2EB5C9"} variant="h6">
                          Net amount is: €
                          {(
                            (singlePlan?.price - couponData?.discount_amount) /
                            100
                          )
                            .toFixed(2)
                            .replace(".", ",")}
                        </Typography>
                      </Grid>
                    ) : null}

                    <Grid item sm={4} xs={12} sx={{ m: "auto", pt: 2 }}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        Pay €
                        {discountText
                          ? (
                              (singlePlan?.price -
                                couponData?.discount_amount) /
                              100
                            )
                              .toFixed(2)
                              .replace(".", ",")
                          : (singlePlan?.price / 100)
                              .toFixed(2)
                              .replace(".", ",")}
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </List>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Box sx={{ padding: "20px", marginTop: "-30px" }}>
            <CheckOutPlansDetails
              singlePlan={singlePlan}
              username={props?.regData?.username}
              email={props?.regData?.email}
            />
          </Box>
        </Grid>
      </Grid>
    </JumboCardQuick>
  );
};

export default Checkoutform;

import React from "react";
import List from "@mui/material/List";
import {
  Grid,
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Typography,
} from "@mui/material";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import {
  fetchMessageList,
  fetchSectionList,
  singleMessage,
  updateMessage,
} from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import "./message.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const validationSchema = yup.object({
  name: yup.string("Enter Message").required("Message is required"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 300,
    },
  },
};
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const CreateMessage = () => {
  const { t } = useTranslation();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [typefor, setTypefor] = React.useState();

  const [personName, setPersonName] = React.useState([]);
  const theme = useTheme();
  const [sectionData, setSectionData] = React.useState([]);
  const { messageId } = useParams();
  const [editMessageData, setEditMessageData] = React.useState();
  const [messageData, setMessageData] = React.useState([]);
  const [currentMessageName, setCurrentMessageName] = React.useState(""); // State to store the current edited message name

  const FetchSectionData = () => {
    fetchSectionList(
      {},
      (response) => {
        setSectionData(response?.data?.data);
      },
      (error) => {}
    );
  };
  const isNameUnique = (name) => {
    const existingNames = messageData.map((message) => message.name); // Get all existing message names
    const index = existingNames.indexOf(name); // Check if the entered name already exists
    return index === -1 || existingNames[index] === currentMessageName; // If the name doesn't exist or it's the current edited name, return true
  };
  function getMessageData() {
    singleMessage(
      { messageId },
      (response) => {
        setEditMessageData(response?.data?.data);
        const sectiondata = response?.data?.data?.Sections ?? [];
        const tagData = response?.data?.data?.MessageDataTypes ?? [];
        setTypefor(tagData.map((item) => item.type));
        const sectionIds = sectiondata.map((item) => item.id);
        setPersonName(sectionIds);
        setCurrentMessageName(response?.data?.data?.name);
      },
      (error) => {}
    );
  }

  React.useEffect(() => {
    FetchSectionData();
    getMessageData();
    FetchMessageData();
  }, []);
  const FetchMessageData = () => {
    fetchMessageList(
      {},
      (response) => {
        if (response?.data?.data) setMessageData(response?.data?.data);
      },
      (error) => {}
    );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };
  const navigate = useNavigate();

  const onSubmitForm = (data, setSubmitting) => {
    const trimmedName = data.name.trim();

    if (!trimmedName) {
      setalertData({
        show: true,
        message: "Name cannot be empty",
        variant: "error",
      });
      setSubmitting(false);

      return;
    }
    if (!isNameUnique(trimmedName)) {
      setalertData({
        show: true,
        message: "Name already exists",
        variant: "error",
      });
      setSubmitting(false);

      return;
    }
    let params = {
      name: data.name,
      sections: personName,
      messageId: messageId,
      types: typefor,
    };

    if (personName.length < 2) {
      setalertData({
        show: true,
        message: (
          <span> {t("pages.title.Please select atleast two sections")}</span>
        ),
        variant: "error",
      });
      setSubmitting(false);

      return;
    }
    if (!typefor || !typefor.length) {
      setalertData({
        show: true,
        message: <span>{t("pages.title.Please select atleast one tags")}</span>,
        variant: "error",
      });
      setSubmitting(false);

      return;
    }
    updateMessage(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: <span>{t("pages.title.Updated Successfully")}</span>,
            variant: "success",
          });
          setSubmitting(false);
          navigate("/messages");
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  const type = [
    { value: "connect", text: "Prospection", icon: <FacebookIcon /> },
    {
      value: "instagram-connect",
      text: "Prospection",
      icon: <InstagramIcon />,
    },
    { value: "crm", text: "Crm", icon: <FacebookIcon /> },
    { value: "instagram-crm", text: "Crm", icon: <InstagramIcon /> },
    { value: "birthday", text: "Birthday", icon: <FacebookIcon /> },
    { value: "request", text: "Request", icon: <FacebookIcon /> },
  ];
  return (
    <Box className="outer-contaier message-container edit-message-main message-container-create-new">
      <Box sx={{ m: 3 }}>
        <div>
          {alertData.show ? (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          ) : (
            ""
          )}
        </div>

        <Box className="message-header" sx={{}}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t("pages.title.edit_Message")}
          </Typography>
          <Button variant="outlined" onClick={() => navigate("/messages")}>
            <span>
              <ArrowBackIosOutlinedIcon sx={{ height: "50px" }} />
            </span>
          </Button>
        </Box>
        <List disablePadding className="message-wraper">
          <Formik
            validateOnChange={true}
            enableReinitialize="true"
            initialValues={{
              name: editMessageData?.name,
            }}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);
              onSubmitForm(data, setSubmitting);
            }}
            id="form-section"
          >
            {({ isSubmitting, values }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} className="half-field">
                    <p className="msg-label">Name of your Message</p>

                    <JumboTextField
                      fullWidth
                      placeholder={t("pages.title.message_name")}
                      name="name"
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="full-field">
                    <p className="msg-label">Select the Sections</p>

                    <FormControl fullWidth>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        fullWidth
                        value={personName}
                        onChange={handleChange}
                        input={<OutlinedInput label="Section" />}
                        MenuProps={MenuProps}
                        renderValue={(selected) => {
                          const selectedNames = selected
                            .map(
                              (value) =>
                                sectionData.find((item) => item.id === value)
                                  ?.section
                            )
                            .filter(
                              (name) => name !== null && name !== undefined
                            );
                          return (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selectedNames.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          );
                        }}
                      >
                        <MenuItem value="" disabled>
                          <span>Select Sections</span>
                        </MenuItem>
                        {sectionData.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id}
                            style={getStyles(item.section, personName, theme)}
                          >
                            <span>{item.section}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <p className="msg-label msg-label-space">Select Type</p>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: "8px !important",
                      backgroundColor: "#F6F6F6",
                    }}
                  >
                    <ToggleButtonGroup
                      color="primary"
                      value={typefor}
                      onChange={(event, value) => setTypefor(value)}
                      aria-label="Platform"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 1,
                        "& .Mui-selected": {
                          backgroundColor: "#41CE81 !important",
                          color: "white !important",
                        },
                        button: {
                          borderRadius: "8px !important",
                        },
                      }}
                    >
                      {type.map((v, index) => (
                        <ToggleButton
                          value={v.value}
                          key={index}
                          sx={{ gap: 1 }}
                        >
                          <span>{v.icon}</span>
                          <span>{v.text}</span>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid
                    className="button-footer"
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <LoadingButton
                      fullWidth
                      id="form-section"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      <span>{t("pages.title.update")}</span>
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </List>
      </Box>
    </Box>
  );
};

export default CreateMessage;

import React, { useContext, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
  Grid,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { uploadKycData } from "backendServices/ApiCalls";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Spinner from "app/shared/Spinner/Spinner";
import { useTranslation } from "react-i18next";

let validationSchema = yup.object({});
let initialValues = {};

const KYC = ({ setBecomeAffiliateOpen }) => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [imageFront, setImageFront] = useState(null);
  const [imageBack, setImageBack] = useState(null);
  const [selectedValue, setSelectedValue] = useState("Select Type");
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setImageBack(null);
    setImageFront(null);
    if (value === "Passport") {
      validationSchema = yup.object({
        identityType: yup.string().required("Indentity Type Fee is required"),
        idcardFront: yup.mixed().required("Passport Front is required"),
        residentialAddress: yup
          .string()
          .required("Residential Address is required"),
      });
      initialValues = {
        identityType: "Select Type",
        idcardFront: null,
        residentialAddress: "",
      };
    } else if (value === "Identity Card") {
      validationSchema = yup.object({
        identityType: yup.string().required("Identity Type Fee is required"),
        idcardFront: yup.mixed().required("Id Card Front Page is required"),
        idcardBack: yup.mixed().required("Id Card Back Page is required"),
        residentialAddress: yup
          .string()
          .required("Residential Address is required"),
      });
      initialValues = {
        identityType: "Select Type",
        idcardFront: null,
        idcardBack: null,
        residentialAddress: "",
      };
    }
  };

  const handleUpload = (e, field) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      if (selectedFile.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result; // Extract base64 encoded string
          if (base64Data) {
            if (field === "idcardFront") {
              setImageFront(base64Data);
            } else if (field === "idcardBack") {
              setImageBack(base64Data);
            }
          } else {
          }
        };
        reader.readAsDataURL(selectedFile); // Read the selected file as data URL
      } else {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
      }
    }
  };

  const handleSubmit = (data, setSubmitting, resetForm) => {
    const formData = new FormData();
    formData.append("residentialAddress", data.residentialAddress);
    formData.append("identityType", data.identityType);
    if (selectedValue === "Passport") {
      if (!imageFront) {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
        return; // Don't proceed with form submission
      } else {
        formData.append("idcardFront", imageFront);
      }
    } else if (selectedValue === "Identity Card") {
      if (!imageFront || !imageBack) {
        setalertData({
          show: true,
          message: "Invalid file type. Please select an image file.",
          variant: "error",
        });
        return; // Don't proceed with form submission
      } else {
        formData.append("idcardFront", imageFront);
        formData.append("idcardBack", imageBack);
      }
    }

    uploadKycData(
      formData,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setSubmitting(false);
          resetForm();
          setBecomeAffiliateOpen(false);
          setImageBack(null);
          setImageFront(null);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });

          setloginUserData((prevState) => ({
            ...prevState,
            kyc_status: "Uploaded",
          }));
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        setSubmitting(false);
        setalertData({
          show: true,
          message: "Something went wrong please try again",
          variant: "error",
        });
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      p={2}
      alignItems="center"
      justifyContent="center"
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}

      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Formik
            initialValues={initialValues}
            validateOnChange={true}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleSubmit(data, setSubmitting, resetForm);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Div>
                  <Div>{t("pages.title.requireddocuments")}</Div>
                  <Div>
                    <Div>
                      <p style={{ color: "red" }}>{t("pages.title.format")}</p>
                    </Div>
                    <Field
                      as={TextField}
                      select
                      id="identityType"
                      name="identityType"
                      label="Identity Type"
                      fullWidth
                      variant="outlined"
                      value={selectedValue}
                      onChange={(e) => {
                        handleSelectChange(e);
                        setFieldValue("identityType", e.target.value);
                      }}
                    >
                      <MenuItem value="Select Type">
                        {t("pages.title.select_Type")}
                      </MenuItem>
                      <MenuItem value="Passport">
                        {t("pages.title.passport")}
                      </MenuItem>
                      <MenuItem value="Identity Card">
                        {t("pages.title.identitycard")}
                      </MenuItem>
                    </Field>
                    {selectedValue === "Passport" && (
                      <>
                        <Div>
                          <ListItem sx={{ px: 0, pb: 0 }}>
                            <ListItemText
                              primary={
                                <>
                                  <label>
                                    {t("pages.title.passportfront")}
                                  </label>
                                  <TextField
                                    onChange={(event) => {
                                      handleUpload(event, "idcardFront");
                                      setFieldValue(
                                        "idcardFront",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    required
                                    name="idcardFront"
                                    type="file"
                                    margin="normal"
                                    sx={{ marginBottom: "0px", width: "100%" }}
                                    InputProps={{
                                      endAdornment: (
                                        <CameraAltIcon
                                          fontSize={"small"}
                                          color={"success"}
                                        />
                                      ),
                                    }}
                                  />

                                  <br />
                                  {imageFront && (
                                    <img
                                      src={imageFront}
                                      alt="Passport Front Preview"
                                      style={{
                                        maxWidth: "100px",
                                        marginTop: "10px",
                                      }}
                                    />
                                  )}
                                </>
                              }
                            />
                          </ListItem>
                        </Div>
                        <label>{t("pages.title.residentialaddress")}</label>
                        <Div sx={{ mt: 1 }}>
                          <JumboTextField
                            fullWidth
                            name="residentialAddress"
                            type="text"
                            multiline
                            rows={4}
                          />
                        </Div>
                      </>
                    )}
                    {selectedValue === "Identity Card" && (
                      <>
                        <Div sx={{ mt: 1 }}>
                          <ListItem sx={{ px: 0, pb: 0 }}>
                            <ListItemText
                              primary={
                                <>
                                  <label>{t("pages.title.idfront")}</label>
                                  <TextField
                                    onChange={(event) => {
                                      handleUpload(event, "idcardFront");
                                      setFieldValue(
                                        "idcardFront",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    required
                                    name="idcardFront"
                                    type="file"
                                    margin="normal"
                                    sx={{ marginBottom: "0px", width: "100%" }}
                                    InputProps={{
                                      endAdornment: (
                                        <CameraAltIcon
                                          fontSize={"small"}
                                          color={"success"}
                                        />
                                      ),
                                    }}
                                  />
                                  <br />
                                  {imageFront && (
                                    <img
                                      src={imageFront}
                                      alt="ID Front Preview"
                                      style={{
                                        maxWidth: "100px",
                                        marginTop: "10px",
                                      }}
                                    />
                                  )}
                                </>
                              }
                            />
                          </ListItem>
                        </Div>
                        <Div sx={{ mt: 1 }}>
                          <ListItem sx={{ px: 0, pb: 0 }}>
                            <ListItemText
                              primary={
                                <>
                                  <label>{t("pages.title.idback")}</label>
                                  <TextField
                                    onChange={(event) => {
                                      handleUpload(event, "idcardBack");
                                      setFieldValue(
                                        "idcardBack",
                                        event.currentTarget.files[0]
                                      );
                                    }}
                                    required
                                    name="idcardBack"
                                    type="file"
                                    margin="normal"
                                    sx={{ marginBottom: "0px", width: "100%" }}
                                    InputProps={{
                                      endAdornment: (
                                        <CameraAltIcon
                                          fontSize={"small"}
                                          color={"success"}
                                        />
                                      ),
                                    }}
                                  />
                                  <br />
                                  {imageBack && (
                                    <img
                                      src={imageBack}
                                      alt="ID Front Preview"
                                      style={{
                                        maxWidth: "100px",
                                        marginTop: "10px",
                                      }}
                                    />
                                  )}
                                </>
                              }
                            />
                          </ListItem>
                        </Div>
                        <label>{t("pages.title.residentialaddress")}</label>
                        <Div sx={{ mt: 1 }}>
                          <JumboTextField
                            fullWidth
                            name="residentialAddress"
                            type="text"
                            multiline
                            rows={4}
                          />
                        </Div>
                      </>
                    )}
                    <Div sx={{ mt: 1 }}>
                      {selectedValue !== "Select Type" && (
                        <LoadingButton
                          fullWidth
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{ mb: 3 }}
                          loading={isSubmitting}
                        >
                          {t("pages.title.upload")}
                        </LoadingButton>
                      )}
                    </Div>
                  </Div>
                </Div>
              </Form>
            )}
          </Formik>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default KYC;

import React from "react";

import { AiCommentChart } from "./Chart/AiCommentChart";
import { MessageChart } from "./Chart/MessageChart";
import { ContactChart } from "./Chart/ContactChart";

export function Chart() {
  return (
    <>
    <div className="sp-statWraper">
      <MessageChart />
      <AiCommentChart />
      <ContactChart/>
      </div>
    </>
  );
}

import {
    Avatar,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    Typography,
} from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import React, { useContext, useEffect, useMemo, useState } from "react";

export default function RefCustomerCard(props) {
    const { loginUserData } = useContext(CustomProvider);
    const [refRevenue, setRefRevenue] = useState({ per1: 0, per2: 0 });

    const { refCustomer, totalCount } = props;

    const color = {
        "In trial": "#2c73ff",
        "Active": "#22C55E",
        "subscription_activated": "#22C55E",
        "To Be Collected": "#FFC804",
        "Payment Due": "#FF7A00",
        "Cancelled": "#FF0000"
    };

    const formatDate = (dateInput, isUnix = false) => {
        const date = isUnix ? new Date(dateInput * 1000) : new Date(dateInput);
        return date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const memoizedFormatDate = useMemo(
        () => (dateInput, isUnix = false) => formatDate(dateInput, isUnix),
        []
    );



    const calcRevenue = () => {
        const count = totalCount ?? 0;
        let revenue = { per1: 0, per2: 0 };

        if (count >= 1 && count < 3) revenue = { per1: 20, per2: 0 };
        else if (count >= 3 && count < 10) revenue = { per1: 20, per2: 20 };
        else if (count >= 10 && count < 20) revenue = { per1: 22.5, per2: 22.5 };
        else if (count >= 20 && count < 30) revenue = { per1: 25, per2: 25 };
        else if (count >= 30 && count < 50) revenue = { per1: 30, per2: 25 };
        else if (count >= 50) revenue = { per1: 30, per2: 30 };

        return revenue
    };

    const calEstRevenue = (refCustomer, revenue) => {
        if (!refCustomer || !revenue) return 0;
        if (Number(refCustomer.sponsorid) === loginUserData?.user_id) {
            return (refCustomer.plan_price * revenue.per1) / 100 || 0;
        } else if (Number(refCustomer.l2_sponsorid) === loginUserData?.user_id) {
            return (refCustomer.plan_price * revenue.per2) / 100 || 0;
        }
        return 0;
    };

    const calculatedRevenue = useMemo(() => calcRevenue(totalCount ?? 0), [totalCount]);

    useEffect(() => {
        setRefRevenue(calculatedRevenue);
    }, [calculatedRevenue]);

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={
                    <Avatar
                        alt="Avatar Image"
                        src={
                            refCustomer?.pictureUrl && refCustomer?.picture ? `${refCustomer?.pictureUrl}${refCustomer?.picture}` : null
                        }
                        sx={{ width: 44, height: 44 }}
                    >
                        {refCustomer?.firstname
                            ? refCustomer.firstname.charAt(0).toUpperCase()
                            : ''}
                    </Avatar>
                }
                title={
                    <Typography >
                        {refCustomer?.firstname + " " + refCustomer?.lastname}
                    </Typography>
                }
            />
            <Divider variant="middle" />
            <CardContent>
                <table>
                    <tbody>
                        {Number(refCustomer?.sponsorid) === loginUserData?.user_id ? <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Email
                            </th>
                            <td
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                {refCustomer?.email}
                            </td>
                        </tr> : null}

                        <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Plan
                            </th>
                            <td
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                {refCustomer?.plan_pkg === "Unlimited_new" ? "Unlimited" : refCustomer?.plan_pkg}
                            </td>
                        </tr>
                        <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Price
                            </th>
                            <td
                                style={{
                                    textAlign: "left",

                                }}
                            >
                                {`${refCustomer?.plan_price} ${refCustomer?.currency === "USD" ? "$" : "€"}`}
                            </td>
                        </tr>
                        <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Est Revenue
                            </th>
                            <td
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                {`${calEstRevenue(refCustomer, refRevenue).toFixed(2)} ${refCustomer?.currency === "USD" ? "$" : "€"}`}
                            </td>
                        </tr>
                        <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Joining Date
                            </th>
                            <td
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                {refCustomer?.createdat && memoizedFormatDate(refCustomer?.createdat)}
                            </td>
                        </tr>
                        <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Activation On
                            </th>
                            <td
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                {
                                    refCustomer?.trial_end === 0
                                        ? memoizedFormatDate(refCustomer?.createdat)
                                        : memoizedFormatDate(refCustomer?.trial_end, true)
                                }
                            </td>
                        </tr>
                        <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Sponsored by{" "}
                            </th>
                            <td
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                {Number(refCustomer?.sponsorid) === loginUserData?.user_id ? 'You' : refCustomer?.sponsor_name}
                            </td>
                        </tr>
                        <tr>
                            <th
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                Status
                            </th>
                            <td
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                {refCustomer?.subscription_status === 'subscription_activated'
                                    ? <Chip label="New" sx={{
                                        backgroundColor: color[refCustomer?.subscription_status] || '#ccc',
                                        color: '#fff',
                                        width: '80px',
                                        textAlign: 'center',
                                    }} />
                                    : null
                                }
                            </td>
                        </tr>

                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}

import React, { useContext, useEffect, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress, IconButton } from "@mui/material";
import { fetchLostList } from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";
import FacebookIcon from "@mui/icons-material/Facebook";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";

const Lostlist = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState([]);

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  let params = {};
  const FetchGroupData = () => {
    fetchLostList(
      params,
      (response) => {
        setLoading(false);
        if (response?.data?.data) setGroupData(response?.data?.data ?? []);
      },
      (error) => {}
    );
  };
  const { loginUserData } = useContext(CustomProvider);

  useEffect(() => {
    FetchGroupData();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const gridDesign = {
    "& .MuiDataGrid-main": {
      height: "320px",
    },
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
    "& .MuiDataGrid-columnHeaders": {
      background: "#ffffff",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    "& .MuiDataGrid-row": {
      marginTop: 2,
      background: "#ffffff",
      borderRadius: 2,
    },
    "& .MuiDataGrid-footerContainer": {
      background: "#ffffff",
    },
    "& .MuiTextField-root": {
      position: "absolute",
      right: 30,
      top: -55,
    },
  };

  const columns = [
    {
      field: "user_name",
      headerName: "Member",
      dataGeneratorUniquenessEnabled: true,
      width: 250, // Adjust the width as needed
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton target="_blank" href={params.row.profile}>
            <FacebookIcon />
          </IconButton>
          <img
            src={params.row.image}
            alt={params.row.user_name}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              marginRight: "10px",
            }}
          />
          {params.row.user_name}
        </div>
      ),
    },
    {
      field: "mutual_friend",
      headerName: "# Mutual Friends",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) =>
        params.row.mutual_friend ? params.row.mutual_friend : 0,
    },
    // {
    //   field: "reactions",
    //   headerName: "# Reactions",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) => (params.row.reactions ? params.row.reactions : 0),
    // },
    // {
    //   field: "comments",
    //   headerName: "#Comments",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) => (params.row.comments ? params.row.comments : 0),
    // },
    // {
    //   field: "messages",
    //   headerName: "# Messages",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) =>
    //   params.row.messages ? params.row.messages : 0,
    // },
    {
      field: "gender",
      headerName: "Gender",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (params.row.gender ? params.row.gender : "-"),
    },
    {
      field: "lived",
      headerName: "Lives In",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      renderCell: (params) => (params.row.lived ? params.row.lived : ""),
    },
    // {
    //   field: "tier",
    //   headerName: "Tier",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) =>
    //     params.row.tier ? params.row.tier : "Tier1",
    // },
    // {
    //   field: "has_conversection",
    //   headerName: "Has Conversection",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150, // Adjust the width as needed
    //   renderCell: (params) =>
    //     params.row.has_conversection === 0 ? (
    //       <Chip label="Yes" color="success" variant="outlined" />
    //     ) : (
    //       <Chip label="No" color="secondary" variant="outlined" />
    //       )

    // },
    // {
    //   field: "status",
    //   headerName: "Is Active",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150, // Adjust the width as needed
    //   renderCell: (params) =>
    //     params.row.status === 0 ? (
    //       <Chip label="Yes" color="success" variant="outlined" />
    //     ) : (
    //       <Chip label="No" color="secondary" variant="outlined" />
    //     ),
    // },
  ];
  //if (loginUserData?.unfollow_status != "On")
  //   return (
  //     <Paper
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         p: 4,
  //       }}
  //     >
  //       <div>
  //         <Typography variant="h5">Upgrade Your Plan</Typography>
  //         <Typography variant="body2" paragraph>
  //           You do not have access to this section. Please upgrade your plan to
  //           unlock.
  //         </Typography>
  //         <Button variant="contained" color="primary">
  //           Upgrade Now
  //         </Button>
  //       </div>
  //     </Paper>
  //   );
  //   return (
  //     <>
  //       {alertData.show && (
  //         <SweetAlert alertData={alertData} setalertData={setalertData} />
  //       )}
  //       <JumboDemoCard
  //         title={t("pages.title.List of Blacklist Friend")}
  //         sx={{
  //           borderRadius: "0%",
  //           borderTopLeftRadius: 10,
  //           borderTopRightRadius: 10,
  //         }}
  //         wrapperSx={{
  //           backgroundColor: "background.paper",
  //           pt: 0,
  //           borderRadius: 0,
  //         }}
  //       ></JumboDemoCard>
  //       <DataGrid rows={groupData} columns={columns} sx={gridDesign}         slots={{ toolbar: GridToolbarQuickFilter }}
  // />
  //     </>
  //   );
  return (
    <JumboDemoCard
      title={t("pages.title.coming_soon")}
      sx={{
        borderRadius: "0%",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        textAlign: "center",
      }}
      wrapperSx={{
        backgroundColor: "background.paper",
        pt: 0,
        borderRadius: 0,
      }}
    ></JumboDemoCard>
  );
};

export default Lostlist;

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export function InstagramExceedLimitModal({
  exceedLimitModal,
  title,
  subtitle,
}) {
  return (
    <Dialog
      open={exceedLimitModal.isOpen}
      onClose={exceedLimitModal.onClose}
      sx={{
        ".MuiDialog-container": {
          alignItems: "flex-start",
        },
        "& .MuiDialog-paper": { mt: 12, width: "80%", p: 5 },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>{title}</DialogTitle>
      <DialogContent sx={{ pt: "8px !important", textAlign: "center" }}>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          {subtitle}
        </Typography>
        <Button variant="contained" href="/upgrade-price-plan">
          Upgrade Your Plan
        </Button>
      </DialogContent>
    </Dialog>
  );
}

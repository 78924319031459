import React, { useContext } from "react";
import "./selectConnection.css";
import stepCon from "../../../../assets/img/sep-connection.png";
import fb_con from "../../../../assets/img/sep-fb.svg";
import insta_com from "../../../../assets/img/sep-ig.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { updateUserLimits } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";


const SelectConnection = ({loginUserData,setSelectPop}) => {
  const { setIsLimit } = useContext(CustomProvider);
    
    const [loading, setLoading] = React.useState(false);
    const [alertData, setalertData] = React.useState({
        show: false,
        message: "",
        variant: "",
      });
   const handleConnection = (conn)=>{
    setLoading(true)
     let params;
     if(conn === "fb"){
        params={
        fb_messages:loginUserData?.users_limits?.fb_messages,
        insta_messages:0,
        userid:loginUserData?.users_limits?.userid,
        }
     }else{
        params={
            fb_messages:0,
      insta_messages:loginUserData?.users_limits?.insta_messages,
        userid:loginUserData?.users_limits?.userid,
        }
   }

   updateUserLimits(
    params,
    (response) => {
      if (response?.data?.status === "error") {
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "error",
        });
       
      } else if (response?.data?.status === "success") {
        setSelectPop(false)
        setIsLimit(true)
        setalertData({
          show: true,
          message: response?.data?.message,
          variant: "success",
        });
     
      } else {
        setalertData({
          show: true,
          message: "Something went wrong. Please try again later",
          variant: "error",
        });
      }
      setLoading(false)
    },
    (error) => {
        setLoading(false)
    }
  );
}
   
  return (
    <div class="sep-connection-popup">
      <div class="sep-connection-popup-inner">
        <div class="sep-connection-content connection-scroll">
        {alertData.show && (
            <SweetAlert alertData={alertData} setalertData={setalertData} />
          )}
          <div class="sep-connection-text">
            <div class="sep-connection-text-left">
              <img src={stepCon} />
              <span>
                Select your
                <br />
                connection
                <br />
                account
              </span>
            </div>
            <div class="sep-connection-text-right">
              <div class="sep-connection-btnBox">
                <button class="sep-connection-fb-btn" onClick={()=>handleConnection("fb")}>
                  <img src={fb_con}   />
                  Facebook
                </button>
                <button class="sep-connection-ig-btn" onClick={()=>handleConnection("insta")}>
                  <img src={insta_com} />
                  Instagram
                </button>
            {  loading &&  <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  top: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                {<CircularProgress />}
              </Box>}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectConnection;

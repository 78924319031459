import React, { useContext, useState } from "react";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import {
  Alert,
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  TextField,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { LoadingButton } from "@mui/lab";
import { Formik, Form, Field } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import * as yup from "yup";
import { countries } from "../../components/mui/AutoCompletes/data";
import { UpdatePayoutDetailsApi } from "backendServices/ApiCalls";
import SweetAlert from "../../components/mui/Alerts/SweetAlert";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { useTranslation } from "react-i18next";

let validationSchema = yup.object({});
let initialValues = {};

const Updatepayoutinformation = ({ setUpdatePayoutInformationOpen }) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState("");
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [isUploadButtonVisible, setIsUploadButtonVisible] = useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  let currencyArray = ["USD", "EUR", "AED"];

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setIsUploadButtonVisible(value !== "");
    if (value === "Bank") {
      validationSchema = yup.object({
        country: yup.mixed().required("Bank Account Country is required"),
        bankAccountName: yup.mixed().required("Your Full Name is required"),
        bankAccountIBAN: yup.string().required("Bank Account IBAN is required"),
        bankAccountBIC: yup.string().required("Bank Account BIC is required"),
        address: yup.string().required("Address is required"),
        city: yup.string().required("City is required"),
        zip_code: yup.string().required("Zip Code is required"),
        country: yup.mixed().required("Country is required"),
      });
      initialValues = {
        type: "Bank",
        country: "",
        bankAccountName: "",
        bankAccountIBAN: "",
        bankAccountBIC: "",
        address: "",
        city: "",
        zip_code: "",
        pcountry: "",
      };
    } else if (value === "Crypto") {
      validationSchema = yup.object({
        cryptoUSDT: yup.string().required("Wallet Address is required"),
      });
      initialValues = {
        type: "Crypto",
        cryptoUSDT: "",
      };
    } else if (value === "Bank_out_ue") {
      validationSchema = yup.object({
        bankAccountName: yup.mixed().required("Your Full Name is required"),
        country: yup.mixed().required("Bank Account Country is required"),
        bankAccountNumber: yup
          .string()
          .required("Bank Account Number is required"),
        bankAccountSwiftCode: yup
          .string()
          .required("Bank Account Swift Code is required"),
        address: yup.string().required("Address is required"),
        city: yup.string().required("City is required"),
        zip_code: yup.string().required("Zip Code is required"),
        pcountry: yup.mixed().required("Country is required"),
      });
      initialValues = {
        type: "Bank_out_ue",
        bankAccountName: "",
        country: "",
        bankAccountNumber: "",
        bankAccountSwiftCode: "",
        bankAccountRouting: "",
        address: "",
        city: "",
        zip_code: "",
        pcountry: "",
      };
    }
  };

  const handleSubmit = (data, setSubmitting) => {
    let params = {};

    if (selectedValue === "Bank") {
      params = {
        type: selectedValue,
        country: data.country.label,
        bank_account_name: data.bankAccountName,
        bank_account_bic: data.bankAccountBIC,
        bank_account_iban: data.bankAccountIBAN,
        bank_account_address: data.address,
        bank_account_city: data.city,
        bank_account_zip_code: data.zip_code,
        payment_country: data.pcountry.label,
      };
    }
    if (selectedValue === "Bank_out_ue") {
      params = {
        type: selectedValue,
        country: data.country.label,
        bank_account_name: data.bankAccountName,
        bank_account_number: data.bankAccountNumber,
        bank_account_swift_code: data.bankAccountSwiftCode,
        bank_account_routing: data.bankAccountRouting,
        bank_account_address: data.address,
        bank_account_city: data.city,
        bank_account_zip_code: data.zip_code,
        payment_country: data.pcountry.label,
      };
    }
    if (selectedValue === "Crypto") {
      params = {
        type: selectedValue,
        wallet_address: data.cryptoUSDT,
      };
    }

    UpdatePayoutDetailsApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setSubmitting(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setSubmitting(false);
          setUpdatePayoutInformationOpen(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
          if (selectedValue === "Bank_out_ue") {
            setloginUserData((prevState) => ({
              ...prevState,
              outside_bank_account_country: data.country.label,
              outside_bank_account_title: data.bankAccountName,
              outside_bank_account_number: data.bankAccountNumber,
              outside_bank_account_swift_code: data.bankAccountSwiftCode,
              outside_bank_account_routing: data.bankAccountRouting,
              outside_bank_account_address: data.address,
              outside_bank_account_city: data.city,
              outside_bank_account_zip_code: data.zip_code,
              outside_payout_country: data.pcountry.label,
              porequestcount: 1,
            }));
          }
          if (selectedValue === "Bank") {
            setloginUserData((prevState) => ({
              ...prevState,
              bank_account_country: data.country.label,
              bank_account_title: data.bankAccountName,
              bank_account_bic: data.bankAccountBIC,
              bank_account_iban: data.bankAccountIBAN,
              bank_account_address: data.address,
              bank_account_city: data.city,
              bank_account_zip_code: data.zip_code,
              payout_country: data.pcountry.label,
              porequestcount: 1,
            }));
          }
          if (selectedValue === "Crypto") {
            setloginUserData((prevState) => ({
              ...prevState,
              wallet_address: data.cryptoUSDT,
              porequestcount: 1,
            }));
          }
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {
        setSubmitting(false);
        setalertData({
          show: true,
          message: "Something went wrong please try again",
          variant: "error",
        });
      }
    );
  };

  return (
    <Grid
      container
      fullWidth
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="center"
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Grid item sm={12} xs={12}>
        <JumboDemoCard
          wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
        >
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={(data, { setSubmitting }) => {
              handleSubmit(data, setSubmitting);
              setSubmitting(true);
            }}
          >
            {({ isSubmitting, setFieldValue }) => (
              <Form>
                <Div>
                  {selectedValue === "Crypto" ? (
                    <Div sx={{ marginBottom: 3 }}>
                      <Alert severity="warning">
                        {t("pages.title.cryptowarning")}
                      </Alert>
                    </Div>
                  ) : null}
                  <Field
                    as={TextField}
                    select
                    id="paymentMethod"
                    name="paymentMethod"
                    label={t("pages.title.paymentmethod")}
                    variant="outlined"
                    value={selectedValue}
                    onChange={(e) => {
                      handleSelectChange(e);
                      setFieldValue("paymentMethod", e.target.value);
                    }}
                    sx={{ width: selectedValue === "" ? 200 : "100%" }}
                  >
                    <MenuItem value="Select Method">
                      {t("pages.title.select_Type")}
                    </MenuItem>
                    <MenuItem value="Bank">
                      {t("pages.title.bankwire")}
                    </MenuItem>
                    <MenuItem value="Bank_out_ue">
                      {t("pages.title.bankwire_out_ue")}
                    </MenuItem>
                    {/* <MenuItem value="Crypto">
                      {t("pages.title.cryptoUSDT")}
                    </MenuItem> */}
                  </Field>

                  {selectedValue === "Bank_out_ue" && (
                    <>
                      <Div sx={{ mt: 1, color: "red" }}>
                        <span style={{ padding: 3 }}>
                          <Alert severity="info">
                            {t("pages.title.paymentusd")}
                          </Alert>
                        </span>
                      </Div>
                      <Div>
                        <Autocomplete
                          sx={{ width: "100%" }}
                          component={TextField}
                          onChange={(event, newValue) => {
                            setFieldValue("country", newValue);
                          }}
                          id="bankAccountName"
                          options={countries}
                          filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter((option) =>
                              option.label.toLowerCase().startsWith(inputValue)
                            );
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                style={{ width: 25 }}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <JumboTextField
                              name="country"
                              fullWidth
                              {...params}
                              label={t("pages.title.ba_country")}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Div>
                      <Div>
                        <JumboTextField
                          name="bankAccountName"
                          label={t("pages.title.ba_full_name")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>

                      <Div>
                        <JumboTextField
                          name="bankAccountNumber"
                          label={t("pages.title.ba_number")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>
                      <Div>
                        <JumboTextField
                          name="bankAccountSwiftCode"
                          label={t("pages.title.ba_swift_code")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>

                      <Div>
                        <JumboTextField
                          name="bankAccountRouting"
                          label={t("pages.title.ba_routing")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2, mb: 2 }}
                        />
                      </Div>

                      <Div>
                        <JumboTextField
                          name="address"
                          label={t("pages.title.p_address")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>

                      <Div>
                        <JumboTextField
                          name="city"
                          fullWidth
                          label={t("pages.title.ba_city")}
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>

                      <Div>
                        <JumboTextField
                          name="zip_code"
                          fullWidth
                          label={t("pages.title.ba_zip_code")}
                          type="text"
                          sx={{ mt: 2, mb: 2 }}
                        />
                      </Div>

                      <Div>
                        <Autocomplete
                          sx={{ width: "100%" }}
                          component={TextField}
                          onChange={(event, newValue) => {
                            setFieldValue("pcountry", newValue);
                          }}
                          id="bankAccountName"
                          options={countries}
                          filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter((option) =>
                              option.label.toLowerCase().startsWith(inputValue)
                            );
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                style={{ width: 25 }}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <JumboTextField
                              name="pcountry"
                              fullWidth
                              {...params}
                              label={t("pages.title.p_country")}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Div>
                    </>
                  )}

                  {selectedValue === "Bank" && (
                    <>
                      <Div sx={{ mt: 1, color: "red" }}>
                        <span style={{ padding: 3 }}>
                          <Alert severity="info">
                            {t("pages.title.paymenteuros")}
                          </Alert>
                        </span>
                      </Div>
                      <Div>
                        <Autocomplete
                          sx={{ width: "100%" }}
                          component={TextField}
                          onChange={(event, newValue) => {
                            setFieldValue("country", newValue);
                          }}
                          id="bankAccountName"
                          options={countries}
                          filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter((option) =>
                              option.label.toLowerCase().startsWith(inputValue)
                            );
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                style={{ width: 25 }}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <JumboTextField
                              name="country"
                              fullWidth
                              {...params}
                              label={t("pages.title.ba_country")}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Div>
                      <Div>
                        <JumboTextField
                          name="bankAccountName"
                          label={t("pages.title.ba_full_name")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>
                      <Div>
                        <JumboTextField
                          name="bankAccountIBAN"
                          label={t("pages.title.ba_iban")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>
                      <Div>
                        <JumboTextField
                          name="bankAccountBIC"
                          fullWidth
                          label={t("pages.title.ba_bic")}
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>
                      <Div>
                        <JumboTextField
                          name="address"
                          label={t("pages.title.p_address")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>

                      <Div>
                        <JumboTextField
                          name="city"
                          fullWidth
                          label={t("pages.title.ba_city")}
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>

                      <Div>
                        <JumboTextField
                          name="zip_code"
                          fullWidth
                          label={t("pages.title.ba_zip_code")}
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>

                      <Div>
                        <Autocomplete
                          sx={{ width: "100%" }}
                          component={TextField}
                          onChange={(event, newValue) => {
                            setFieldValue("pcountry", newValue);
                          }}
                          id="bankAccountName"
                          options={countries}
                          filterOptions={(options, state) => {
                            const inputValue = state.inputValue.toLowerCase();
                            return options.filter((option) =>
                              option.label.toLowerCase().startsWith(inputValue)
                            );
                          }}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                style={{ width: 25 }}
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label} ({option.code}) +{option.phone}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <JumboTextField
                              name="pcountry"
                              fullWidth
                              {...params}
                              label={t("pages.title.p_country")}
                              inputProps={{
                                ...params.inputProps,
                              }}
                            />
                          )}
                        />
                      </Div>
                    </>
                  )}

                  {selectedValue === "Crypto" && (
                    <>
                      <Div sx={{ mt: 1, color: "red" }}>
                        <span style={{ padding: 3 }}>
                          <Alert severity="info">
                            {t("pages.title.cryptofee")}
                          </Alert>
                        </span>
                      </Div>
                      <Div>
                        <JumboTextField
                          name="cryptoUSDT"
                          placeholder={t("pages.title.wallet_address_trc20")}
                          fullWidth
                          type="text"
                          sx={{ mt: 2 }}
                        />
                      </Div>
                    </>
                  )}
                  <Div>
                    {isUploadButtonVisible && (
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mt: 3 }}
                        loading={isSubmitting}
                      >
                        {t("pages.title.submitrequest")}
                      </LoadingButton>
                    )}
                  </Div>
                </Div>
              </Form>
            )}
          </Formik>
        </JumboDemoCard>
      </Grid>
    </Grid>
  );
};

export default Updatepayoutinformation;

import React from "react";
import { Avatar, Card, CardContent, Rating, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

const AgentDetail = ({ referraldetails, picturelink, color }) => {
  function convertUnixTimestampToYMDWithTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ymdWithTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return ymdWithTime;
  }

  const unixTimestamp = referraldetails?.nextBillingAt;
  const result = convertUnixTimestampToYMDWithTime(unixTimestamp);

  return (
    <Card sx={{ overflow: "visible", mt: 4 }}>
      <CardContent>
        <Avatar
          alt={referraldetails?.username}
          picturelink
          src={picturelink + referraldetails?.picture}
          sx={{ boxShadow: 26, width: 60, height: 60, mb: 2, mt: "-54px" }}
        />
        <Typography variant={"h6"}>{referraldetails?.username}</Typography>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          {/* <FiberManualRecordIcon style={{ color: color }} /> */}
          <Typography
            component={"div"}
            variant={"body1"}
            color={"text.secondary"}
            sx={{
              display: "flex",
              fontSize: 12,
            }}
          >
            {referraldetails?.firstname + " " + referraldetails?.lastname}
            {/* {'Mobile: '+referraldetails?.username} */}
            {/* <Divider orientation="vertical" flexItem sx={{mx: 1, my: .5}}/> */}
            {/* {referraldetails?.country} */}
          </Typography>
        </Stack>

        <Typography variant="p">
          <span style={{ fontWeight: "bold" }}> Email: </span> <br></br>{" "}
          {referraldetails?.email}
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="p">
          <span style={{ fontWeight: "bold" }}> Subscription: </span> <br></br>{" "}
          {referraldetails?.pkg_name}
        </Typography>
        <br></br>
        <br></br>
        <Typography variant="p">
          <span style={{ fontWeight: "bold" }}> Renewal Date: </span> <br></br>{" "}
          {result}
        </Typography>
        {/* <br></br>
                <br></br> */}
        {/* <Typography variant='p'>
                    <span style={{ fontWeight: 'bold' }}> Leg: </span>  {referraldetails?.leg_position === "L" ? "Left" : "Right"}
                </Typography> */}
      </CardContent>
    </Card>
  );
};
/* Todo agent detail prop */
export default AgentDetail;

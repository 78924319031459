import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { PayoutReportDataApi } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { ObjectCountRevenue } from "app/shared/metrics/ObjectCountCards";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import EuroIcon from "@mui/icons-material/Euro";

const VISIBLE_FIELDS = ["sr", "sender", "amount", "date"];
const PayoutReport = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [obj, setObj] = useState(null);
  const [payoutreportdata, setPayoutReportData] = useState([]);
  const InvestmentData = () => {
    setLoading(true);
    PayoutReportDataApi(
      (response) => {
        setPayoutReportData(response?.data?.data);
        setObj(response?.data?.obj);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    InvestmentData();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const rowsWithIndex = payoutreportdata?.map((row, index) => ({
    ...row,
    id: index + 1,
  }));

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "id",
      headerName: "ID",
      dataGeneratorUniquenessEnabled: true,
      width: 80,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "approvedat",
      headerName: "Period",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      // Add a valueGetter function to compute the previous month name
      valueGetter: function (params) {
        const dateString = params.value; // Assuming createdat is the field that contains the date
        const date = new Date(dateString);
        date.setMonth(date.getMonth() - 1);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        return monthNames[date.getMonth()];
      },
    },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "amount",
      headerName: "Withdrawal Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => `€${params.value}`,
    },
    {
      field: "final_amount",
      headerName: "Final Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => `€${params.value}`,
    },
    {
      field: "fee",
      headerName: "Fee",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => {
        if (params.row.payoutmethod === "Bank") {
          return `€${params.row.payout_fee}`;
        } else {
          return `${params.rwo.payout_fee}%`;
        }
      },
    },
    {
      field: "payoutmethod",
      headerName: "Method",
      dataGeneratorUniquenessEnabled: true,
      width: 80,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "wallet_address",
      headerName: "Wallet Address",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "bank_account_title",
      headerName: "Bank Account Title",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "bank_account_iban",
      headerName: "Bank Account Iban",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
      // Add a render function to convert the value to uppercase
      renderCell: (params) => {
        return <span>{params?.value?.toUpperCase()}</span>;
      },
    },
    {
      field: "bank_account_bic",
      headerName: "Bank Account BIC",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return <span>{params?.value?.toUpperCase()}</span>;
      },
    },
    {
      field: "bank_account_country",
      headerName: "Bank Account Country",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    // {
    //   field: "approvedat",
    //   headerName: "Date",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 200,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // }
  ];

  return (
    <>
      <Grid container>
        <Grid item sm={12} lg={12} md={12}>
          <Typography variant="h3">{t("pages.title.payoutreport")}</Typography>
        </Grid>
        <Grid pt={3} pb={4} pr={2} item sm={12} lg={4} md={4}>
          <ObjectCountRevenue
            value={"€" + obj?.allTimePayout}
            title="allTimePayout"
            color="primary.main"
            icon={<EuroIcon fontSize="large" />}
            vertical={true}
          />
        </Grid>
        <Grid pt={3} pb={4} pl={2} item sm={12} lg={4} md={4}>
          <ObjectCountRevenue
            value={obj?.rankData?.name + " + €" + obj?.rankData?.residuel}
            title="rank"
            color="primary.main"
            icon={<EmojiEventsIcon fontSize="large" />}
            vertical={true}
          />
        </Grid>
      </Grid>
      <JumboDemoCard
        // title={t('pages.title.payoutreport')}
        wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
      >
        <Box sx={{ height: 460, width: 1 }}>
          <DataGrid
            initialState={{
              initialState,
              pagination: { paginationModel: { pageSize: 6 } },
            }}
            rows={rowsWithIndex}
            columns={columns}
            // slots={{ toolbar: GridToolbar }}
            slots={{
              toolbar: (props) => (
                <GridToolbar
                  {...props}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#424242" : "#fff",
                    "& .MuiIconButton-root": {
                      color: theme.palette.mode === "dark" ? "#fff" : "#000",
                    },
                  }}
                />
              ),
            }}
            sx={gridDesign}
            pageSizeOptions={[6, 12, 18, 24, 30]}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </JumboDemoCard>
    </>
  );
};

export default PayoutReport;

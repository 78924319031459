import { debounce } from './debounce';

const originalResizeObserver = ResizeObserver;

class PatchedResizeObserver {
  constructor(callback) {
    this.observer = new originalResizeObserver(debounce(callback, 100));
  }

  observe(...args) {
    return this.observer.observe(...args);
  }

  unobserve(...args) {
    return this.observer.unobserve(...args);
  }

  disconnect(...args) {
    return this.observer.disconnect(...args);
  }
}

window.ResizeObserver = PatchedResizeObserver;

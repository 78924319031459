import "../instagramprospection.css";

export function ForthStep() {
  return (
    <div className="col-wraper col-1-sm ">
      <div className="">
        <span className="cards-title text-center">Select Action</span>
        <div className="bordered-cards">
          <select className="step-select">
            <option>Group</option>
            <option>Group</option>
            <option>Welcome Group</option>
            <option>Sobinder New Group</option>
            <option>Distribution</option>
            <option>New year group</option>
            <option>Test</option>
          </select>
          <select className="step-select">
            <option>Group Stage</option>
            <option>Group Stage</option>
            <option>Group Stage</option>
          </select>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useState } from "react";
import { Button, Grid } from "@mui/material";
import { getSinglePlans } from "backendServices/ApiCalls";
import * as yup from "yup";
import Div from "@jumbo/shared/Div/Div";
import { useEffect } from "react";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { affiliate } from "backendServices/ApiCalls";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { useTranslation } from "react-i18next";

const validationSchema = yup.object({
  annual_fee: yup.string(),
  card_number: yup.string(),
});

const Becomeaffiliate = ({ setOpen }) => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [affiliateData, setAffiliateData] = useState({});
  const [loader, setLoader] = useState(false);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const Swal = useSwalWrapper();

  const onSubmitForm = () => {
    let params = {
      customerid: loginUserData?.customerid,
      item_price_id: "Affiliate-Fee-EUR-Yearly",
    };
    affiliate(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          window.location.replace(response?.data?.data?.hosted_page?.url);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  const callSinglePlan = () => {
    setLoader(true);
    let params = {
      itemid: "Affiliate-Fee-EUR-Yearly",
    };
    getSinglePlans(
      params,
      (response) => {
        setAffiliateData(response?.data?.data?.item_price);
        setLoader(false);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    callSinglePlan();
  }, []);

  return (
    <Grid item sm={12} xs={12}>
      <p
        style={{
          fontSize: "18px",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {t("pages.title.become_affiliate")}
      </p>
      <Div sx={{ pt: 1 }}>
        <Button
          sx={{ ml: 15, justifyContent: "center" }}
          variant="contained"
          onClick={onSubmitForm}
        >
          {t("pages.title.yes")}
        </Button>
        <Button
          sx={{ ml: 2 }}
          variant="contained"
          onClick={() => setOpen(false)}
        >
          {t("pages.title.no")}
        </Button>
      </Div>
    </Grid>
  );
};

export default Becomeaffiliate;

import React from "react";
import "./video.css";

import { useTranslation } from "react-i18next";
const TrainingVideo = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="main-video-box">
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
        <div className="main-video-items">
          <div className="video-frame">
            <iframe
              width="853"
              height="480"
              src={t("pages.title.comment_video")}
              title="Novalya - Dashboard tour"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <h5 className="video-title-new">Title</h5>
        </div>
      </div>
    </>
  );
};

export default TrainingVideo;

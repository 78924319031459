import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import {
  fetchSectionList,
  deleteSection,
  duplicateSection,
} from "backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import { useModalState } from "app/hooks/use-modal-state";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import "./section.css";
import {
  CustomPagination,
  Datatable,
} from "app/pages/components/mui/Datatable";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
import { libraryButtonsConfig } from "./LibraryTabData";
import { GridToolbarQuickFilter, useGridApiRef } from "@mui/x-data-grid";
import TopHeader from "app/pages/components/mui/TopHeader/TopHeader";

const SectionList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [sectionData, setSectionData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [duplicateId, setDuplicateId] = useState(null);
  const apiRef = useGridApiRef();

  const navigate = useNavigate();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  let params = {};
  const FetchSectionData = () => {
    fetchSectionList(
      params,
      (response) => {
        setLoading(false);
        setSectionData(response?.data?.data);
      },
      (error) => {}
    );
  };
  const deleteModal = useModalState();
  const duplicateModal = useModalState();
  useEffect(() => {
    FetchSectionData();
  }, []);

  function handleDelete() {
    deleteSection(
      { deleteId },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Deleted Successfully")}`,
          variant: "success",
        });
        deleteModal.onClose();
        FetchSectionData();
      },
      (error) => {}
    );
  }

  function handleDuplicate() {
    duplicateSection(
      { duplicateId },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Duplicated Successfully")}`,
          variant: "success",
        });
        duplicateModal.onClose();
        FetchSectionData();
      },
      (error) => {}
    );
  }
  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const columns = [
    {
      field: "section",
      headerName: "Name",
      dataGeneratorUniquenessEnabled: true,
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: 36,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => navigate(`/edit/sections/${params.row.id}`)}
              sx={{
                height: 36,
                ml: 2,
                backgroundColor: "#D1EDE5",
                color: "#007867",
                border: "solid 1px #007867",
                "&:hover": {
                  backgroundColor: "#007867",
                  color: "#D1EDE5",
                  border: "solid 1px #D1EDE5",
                },
              }}
              variant="outlined"
            >
              <EditCalendarIcon sx={{ mr: "5px", height: "20px" }} />
              {t("pages.title.edit")}
            </Button>
            <Button
              onClick={() => {
                setDuplicateId(params.row.id);
                duplicateModal.onOpen();
              }}
              sx={{
                height: 36,
                pl: 3,
                pr: 3,
                ml: 2,
                backgroundColor: "#D1EFF6",
                color: "#006C9C",
                border: "solid 1px #006C9C",
                "&:hover": {
                  backgroundColor: "#006C9C",
                  color: "#D1EFF6",
                  border: "solid 1px #D1EFF6",
                },
              }}
              variant="outlined"
            >
              <FileCopyOutlinedIcon sx={{ mr: "5px", height: "20px" }} />
              {t("pages.title.duplicate")}
            </Button>
            <Button
              color="error"
              onClick={() => {
                setDeleteId(params.row.id);
                deleteModal.onOpen();
              }}
              sx={{
                height: 36,
                ml: 2,
                pr: 3,
                backgroundColor: "#FAE0DB",
                color: "#FF0202",
                border: "solid 1px #FF0202",
                "&:hover": {
                  backgroundColor: "#FF0202",
                  color: "#FAE0DB",
                  border: "solid 1px #D1EFF6",
                },
              }}
              variant="outlined"
            >
              <DeleteOutlineOutlinedIcon sx={{ mr: "5px", height: "20px" }} />
              {t("pages.title.delete")}
            </Button>
          </Box>
        );
      },
    },
  ];

  function QuickSearchToolbar() {
    const [showActionMenu, setShowActionMenu] = useState(null);
    const [selectedMenuItem, setSelectedMenuItem] = useState("");

    const handleSort = (field, order) => {
      const sortModel = [
        {
          field,
          sort: order,
        },
      ];
      apiRef.current.setSortModel(sortModel);
      if (order === null) {
        setSelectedMenuItem("");
      } else {
        setSelectedMenuItem(order === "asc" ? "Ascending" : "Descending");
      }
      setShowActionMenu(null);
    };

    useEffect(() => {
      const sortModel = apiRef.current.getSortModel();
      if (sortModel.length > 0) {
        const order = sortModel[0].sort;
        setSelectedMenuItem(order === "asc" ? "Ascending" : "Descending");
      } else {
        setSelectedMenuItem("");
      }
    }, [apiRef]);

    return (
      <Box
        sx={{
          px: 0.5,
          py: 1.5,
          "& .MuiInputBase-root": {
            "&:after": {
              border: "none",
            },
          },
          display: "flex",
        }}
      >
        <GridToolbarQuickFilter className="custom-search-class-nova" />
      </Box>
    );
  }

  return (
    <>
      <div className="library-tabs">
        <TopHeader
          title={t("pages.title.Your_Message_Sections")}
          url={t("pages.title.section_url")}
        />
        <TabMenu buttonsConfig={libraryButtonsConfig} />
      </div>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Typography sx={{ color: "#170F49" }} variant="h2"></Typography>
      <div className="nova-container">
        <div className="nova-box library-box-only library-section">
          <Box className="nova-btn-card btn-group-friends-list">
            <Button
              variant="contained"
              onClick={() => navigate("/create/sections")}
              sx={{
                backgroundColor: "#22C55E",
                color: "white",
                "&:hover": {
                  backgroundColor: "#22C55E",
                  color: "white",
                },
              }}
              startIcon={
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.21098 8.51251L8.21098 11.0624C8.21116 11.3453 7.9814 11.5751 7.69849 11.5749C7.41548 11.5748 7.1861 11.3455 7.1861 11.0624L7.1861 8.51252L4.63619 8.51252L4.63614 8.51252C4.35304 8.51234 4.12363 8.28302 4.1236 7.99998L8.21098 8.51251ZM8.21098 8.51251L10.7609 8.51251C11.0442 8.51251 11.2737 8.28314 11.2733 7.99998C11.2733 7.717 11.0439 7.48765 10.7609 7.48765L8.21098 7.48765V4.93773C8.21098 4.65468 7.98156 4.42533 7.69844 4.42515H7.69839C7.41536 4.42515 7.18596 4.65455 7.18596 4.93758L7.18596 7.48749L4.63609 7.48749C4.3532 7.48731 4.12345 7.71704 4.1236 7.99994L8.21098 8.51251ZM12.7013 2.9973C9.93839 0.234358 5.45871 0.234358 2.69577 2.9973C-0.0673305 5.7604 -0.0671737 10.2399 2.69577 13.0029C5.45886 15.766 9.93823 15.766 12.7013 13.0029C15.4643 10.2399 15.4644 5.7604 12.7013 2.9973ZM3.41081 12.2878C1.05215 9.92917 1.06159 6.08105 3.42057 3.72207C5.77938 1.36326 9.61775 1.36326 11.9766 3.72207C14.3354 6.08087 14.3354 9.91926 11.9766 12.2781C9.61761 14.637 5.76946 14.6465 3.41081 12.2878Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.15"
                  ></path>
                </svg>
              }
            >
              {t("pages.title.create_Section")}
            </Button>
          </Box>
          <Datatable
            apiRef={apiRef}
            className="nova-table-ctm"
            rows={sectionData}
            columns={columns}
            slots={{
              toolbar: QuickSearchToolbar,
              pagination: CustomPagination,
            }}
          />
        </div>
      </div>
      <Dialog
        maxWidth="xs"
        open={deleteModal.isOpen}
        sx={{
          ".MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& .MuiDialog-paper": { mt: 12, width: "80%", maxHeight: 435 },
        }}
      >
        <DialogContent>
          <Typography color="grey.600" mt={3} variant="body2">
            {t("pages.title.delete_data")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteModal.onClose} variant="contained">
            {t("pages.title.cancel")}
          </Button>
          <Button
            onClick={handleDelete}
            variant="contained"
            sx={{
              backgroundColor: "#F44336 !important",
              "&:hover": { backgroundColor: "red !important" },
            }}
          >
            {t("pages.title.delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="xs"
        open={duplicateModal.isOpen}
        sx={{
          ".MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& .MuiDialog-paper": { mt: 12, width: "80%", maxHeight: 435 },
        }}
      >
        <DialogContent>
          <Typography color="grey.600" mt={3} variant="body2">
            {t("pages.title.duplicate_data")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={duplicateModal.onClose}>
            {t("pages.title.cancel")}
          </Button>
          <Button onClick={handleDuplicate} variant="contained">
            {t("pages.title.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SectionList;

import React from "react";
import { Box, CircularProgress } from "@mui/material";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

import { ASSET_IMAGES } from "../../../utils/constants/paths";

import * as yup from "yup";

import Alert from "@mui/material/Alert";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useState } from "react";
import { useBasicAuth } from "app/auth-providers/BasicAuth/hooks";
import { useEffect } from "react";
import { getCompanyLogo, verifyemailaccount } from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";
import "./assets/css/style.css";
import { useFormik } from "formik";
import jwtAuthAxios from "app/services/auth/jwtAuth";

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

const validationSchema = yup.object({
  username: yup.string("Enter your username").required("Username is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = ({ disableSmLogin }) => {
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Get the URLSearchParams object from the current URL
  const urlSearchParams = new URLSearchParams(window.location.search);

  const hostedId = urlSearchParams.get("id");
  const hostedState = urlSearchParams.get("state");
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [randomQuote, setRandomQuote] = useState("");
  const { signIn, manualSignIn } = useBasicAuth();
  const navigate = useNavigate();
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const [validToken, setValidToken] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDomainLoading, setIsDomainLoading] = React.useState(true);
  const [companyData, setCompanyData] = useState(null);
  const [finalDomain, setFinalDomain] = useState(null);

  useEffect(() => {
    if (window.location.hostname === "localhost") {
      var testDomainText = "admin-app.com";
      var testDomain = testDomainText.split(".");
    } else {
      var testDomain = window.location.hostname.split(".");
    }
    const domainParts = testDomain;

    if (domainParts.length >= 1 && domainParts[0]) {
      let final_domain;
      if (domainParts[0].includes("-")) {
        const parts = domainParts[0].split("-");
        final_domain = parts[1];
      } else {
        final_domain = domainParts[0];
      }

      if (final_domain === "app" || final_domain === "stagingapp" || final_domain === "localhost") {
        final_domain = "";
      }

      if (final_domain) {
        setFinalDomain(final_domain); // Store the value in state

        getCompanyLogo({ domain: final_domain }).then((response) => {

          setIsDomainLoading(true);
          setCompanyData(response.data.data);

          const favicon = document.querySelector('link[rel="icon"]');

          if (favicon && response.data.data.logo_val) {
            favicon.href = response.data.data.fav_icon_val;
          }

          if (response.data.data.company) {
            var capitalizedCompany =
              response.data.data.company.charAt(0).toUpperCase() +
              response.data.data.company.slice(1);
            document.title = capitalizedCompany + "";
          }
          setIsDomainLoading(false);
        });
      } else {
        setIsDomainLoading(false);
      }
    }

    const getRandomQuote = () => {
      const randomIndex = Math.floor(Math.random() * quotes.length);
      const quote = quotes[randomIndex];
      setRandomQuote(quote);
    };
    getRandomQuote();
  }, []); // Run only once when the component mounts

 

  const { token, email } = useParams();

  const [showPassword, setShowPassword] = React.useState(false);
  const quotes = [
    "Psalm 119:11 “I have stored up Your Word in my heart, that I might not sin against You”.",
    "“Faith expects from God what is beyond all expectation.” (Andrew Murray)",
    "“If we cannot believe God when circumstances seem be against us, we do not believe Him at all.” (Charles Spurgeon)",
    "“For we walk by faith, not by sight” (Paul, 2 Corinthians 5:7)",
    "“Faith is not the belief that God will do what you want.  It is the belief that God will do what is right.” (Max Lucado)",
    "“Faith consists in believing what reason cannot.” (Voltaire)",
    '"Faith is the gaze of a soul upon a saving God." (A.W. Tozer)',
    '"If by faith you are in Christ, God is as much for you in Christ as He will ever be or could ever be" (John Piper)',
    "“Faith is the strength by which a shattered world shall emerge into the light.” (Helen Keller)",
    "“Faith never knows where it is being led, but it loves and knows the One who is leading.” (Oswald Chambers)",
    "“True faith manifests itself through our actions.” (Francis Chan)",
    "“Joy is the infallible proof of the presence of God.”—Madeleine",
    "“For prayer is nothing else than being on terms of friendship with God.”—Saint Teresa of Ávila",
    "“What God intended for you goes far beyond anything you can imagine.”—Oprah Winfrey",
  ];

  const onSignIn = (username, password, setSubmitting) => {
    const ipaddress = "127.0.0.1";
    const website = finalDomain;
    const cookies = document.cookie.split(";");
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' + ".brevo.com";
    }
    signIn(
      username,
      password,
      ipaddress,
      website,
      (response) => {
        if (response?.status === "error") {
          setalertData({
            show: true,
            message: response?.message,
            variant: "error",
          });
          setSubmitting(false);
          setIsLoading(false);
        } else if (response?.status === "success") {
          const authToken = response.token; // Assuming this is your authentication token
          const userId = response?.user?.id || 0;
          const expirationYears = 10; // 10 years
          const expirationDate = new Date();
          expirationDate.setFullYear(
            expirationDate.getFullYear() + expirationYears
          );

          document.cookie = `authToken=${authToken}; expires=${expirationDate.toUTCString()}; path=/`;
          document.cookie = `user_id=${userId}; expires=${expirationDate.toUTCString()}; path=/`;

          setIsLoading(false);
          navigate("/software-dashboard");
        } else {
          setIsLoading(false);
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {}
    );
  };

  function checkToken(token, email) {
   

    if (token && email) {
      sessionStorage.setItem("iSAdmin","true")
      if (email === "manual") {
        manualSignIn(
          token,
          (response) => {
            if (response?.status === "error") {
              setalertData({
                show: true,
                message: response?.message,
                variant: "error",
              });
              setIsLoading(false);
            } else if (response?.status === "success") {
              const authToken = response.token; // Assuming this is your authentication token
              const userId = response?.user?.id || 0;
              const expirationYears = 10; // 10 years
              const expirationDate = new Date();
              expirationDate.setFullYear(
                expirationDate.getFullYear() + expirationYears
              );

              document.cookie = `authToken=${authToken}; expires=${expirationDate.toUTCString()}; path=/`;
              document.cookie = `user_id=${userId}; expires=${expirationDate.toUTCString()}; path=/`;

              setIsLoading(false);

              setIsLoading(false);
              navigate("/software-dashboard");
            } else {
              setIsLoading(false);
              setalertData({
                show: true,
                message: "Something went wrong please try again later",
                variant: "error",
              });
            }
          },
          (error) => {}
        );
      } else {
        verifyemailaccount(
          token,
          email,
          (response) => {
            if (response?.data?.status === "success") {
              setValidToken(true);
              setIsLoading(false);
            } else {
              setValidToken(false);
              setIsLoading(false);
            }
          },
          (error) => {}
        );
      }
    }
  }

  const validateHostedId = () => {
    setIsLoading(true);
    setalertData({
      show: true,
      message:
        "You have subscribe successfully. We are validating your registration. You can login within one minute. Thank you!",
      variant: "success",
    });
    navigate("/login");
    setIsLoading(false);
  };

  useEffect(() => {
    checkToken(token, email);
    if (hostedState && hostedId) {
      validateHostedId();
    }
  }, []);

  const initialValues = {
    username: "",
    password: "",
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: validationSchema,
      onSubmit: (data, { setSubmitting }) => {
        setSubmitting(true);
        onSignIn(data.username, data.password, setSubmitting);
      },
    });

  if (isLoading || isDomainLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 16px" }} />
        {/* <h4>We are validating your request</h4> */}
      </Box>
    );
  }
  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: 9999 }}
      >
        <DialogTitle sx={{ fontSize: "40px", m: "auto", color: "red" }}>
          {t("pages.title.alert")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ fontSize: "19px" }}
          >
            {t("pages.title.message")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} sx={{ fontSize: "19px" }}>
            {t("pages.title.login")}
          </Button>
        </DialogActions>
      </Dialog>
      {validToken === true ? (
        <Alert severity="success">{t("pages.title.verifiedaccount")} </Alert>
      ) : validToken === false ? (
        <Alert severity="error">{t("pages.title.invalidverification")} </Alert>
      ) : null}

      <section className="login-main">
        <div className="grid-container">
          <div className="left-panel">
            <div className="logo">
              {/* <img src={`${ASSET_IMAGES}/logo-white.svg`} alt="logo" /> */}
              <img
                src={
                  companyData && companyData.logo
                    ? companyData.logo_val
                    : `${ASSET_IMAGES}/logo-white.png`
                }
                alt="logo"
              />
            </div>
            <h3 className="title-login">
              #1{t("pages.title.Marketing_Platform")}
              <br />
              {t("pages.title.for_Organic_Lead_Generation")}
            </h3>
          </div>
          <div className="login-wraper">
            <h3 className="login-text"> {t("pages.title.signin")}</h3>
            <form onSubmit={handleSubmit}>
              <label className="login-label">{t("pages.title.username")}</label>
              <div className="input-group">
                <input
                  type="text"
                  name="username"
                  placeholder="Enter your name*"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                />
                {errors.username && touched.username ? (
                  <p className="text-danger">{errors.username}</p>
                ) : null}

                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.9"
                    d="M12.598 6.71753L17.8762 11.9958L17.1271 12.7444L11.8489 7.46612L12.598 6.71753ZM5.4414 6.74029L6.18999 7.48888L0.948814 12.7301L0.200226 11.9836L5.4414 6.74029Z"
                    fill="#989898"
                  />
                  <path
                    d="M8.99993 9.7117C8.20581 9.7117 7.35875 9.39406 6.7764 8.75876L0.158752 2.19406L0.899929 1.45288L7.51758 8.07053C8.36464 8.91759 9.68816 8.91759 10.5352 8.07053L17.1529 1.45288L17.894 2.19406L11.2235 8.81171C10.6411 9.39406 9.79405 9.7117 8.99993 9.7117Z"
                    fill="#989898"
                  />
                  <path
                    d="M16.4118 14.0001H1.58824C0.688235 14.0001 0 13.3118 0 12.4118V1.82359C0 0.923587 0.688235 0.235352 1.58824 0.235352H16.4118C17.3118 0.235352 18 0.923587 18 1.82359V12.4118C18 13.3118 17.3118 14.0001 16.4118 14.0001ZM1.58824 1.29418C1.27059 1.29418 1.05882 1.50594 1.05882 1.82359V12.4118C1.05882 12.7295 1.27059 12.9412 1.58824 12.9412H16.4118C16.7294 12.9412 16.9412 12.7295 16.9412 12.4118V1.82359C16.9412 1.50594 16.7294 1.29418 16.4118 1.29418H1.58824Z"
                    fill="#989898"
                  />
                </svg>
              </div>
              <label className="login-label">
                {t("pages.title.password")}*
              </label>
              <div className="input-group">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="*****"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password ? (
                  <p className="text-danger">{errors.password}</p>
                ) : null}
                {showPassword ? (
                  <svg
                    width="16"
                    height="11"
                    viewBox="0 0 16 11"
                    fill="none"
                    cursor="pointer"
                    onClick={handleClickShowPassword}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 5.5C16 5.5 13 0 8 0C3 0 0 5.5 0 5.5C0 5.5 3 11 8 11C13 11 16 5.5 16 5.5ZM1.173 5.5C1.65578 4.76459 2.21197 4.08008 2.833 3.457C4.12 2.168 5.88 1 8 1C10.12 1 11.879 2.168 13.168 3.457C13.789 4.08008 14.3452 4.76459 14.828 5.5C14.7707 5.58667 14.7057 5.68267 14.633 5.788C14.298 6.268 13.803 6.908 13.168 7.543C11.879 8.832 10.119 10 8 10C5.881 10 4.121 8.832 2.832 7.543C2.21097 6.91992 1.65578 6.23541 1.173 5.5Z"
                      fill="#908D95"
                    />
                    <path
                      d="M8 3C7.33696 3 6.70107 3.26339 6.23223 3.73223C5.76339 4.20107 5.5 4.83696 5.5 5.5C5.5 6.16304 5.76339 6.79893 6.23223 7.26777C6.70107 7.73661 7.33696 8 8 8C8.66304 8 9.29893 7.73661 9.76777 7.26777C10.2366 6.79893 10.5 6.16304 10.5 5.5C10.5 4.83696 10.2366 4.20107 9.76777 3.73223C9.29893 3.26339 8.66304 3 8 3ZM4.5 5.5C4.5 4.57174 4.86875 3.6815 5.52513 3.02513C6.1815 2.36875 7.07174 2 8 2C8.92826 2 9.8185 2.36875 10.4749 3.02513C11.1313 3.6815 11.5 4.57174 11.5 5.5C11.5 6.42826 11.1313 7.3185 10.4749 7.97487C9.8185 8.63125 8.92826 9 8 9C7.07174 9 6.1815 8.63125 5.52513 7.97487C4.86875 7.3185 4.5 6.42826 4.5 5.5Z"
                      fill="#908D95"
                    />
                  </svg>
                ) : (
                  <svg
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    cursor="pointer"
                    onClick={handleClickShowPassword}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.72727 1.96762L2.65818 1L14.8182 13.739L13.8945 14.7143L11.6545 12.3676C10.8182 12.6571 9.93091 12.8095 9 12.8095C5.36364 12.8095 2.25818 10.44 1 7.09524C1.50182 5.75429 2.30182 4.57333 3.32 3.63619L1.72727 1.96762ZM9 4.80952C9.57865 4.80952 10.1336 5.05034 10.5428 5.47899C10.9519 5.90765 11.1818 6.48903 11.1818 7.09524C11.1822 7.35472 11.1404 7.61237 11.0582 7.85714L8.27273 4.93905C8.50638 4.85294 8.75232 4.80914 9 4.80952ZM9 1.38095C12.6364 1.38095 15.7418 3.75048 17 7.09524C16.4061 8.67462 15.3975 10.0456 14.0909 11.0495L13.0582 9.96C14.064 9.2312 14.8751 8.24503 15.4145 7.09524C14.8267 5.83799 13.9139 4.77877 12.7799 4.03798C11.646 3.2972 10.3364 2.90458 9 2.90476C8.20727 2.90476 7.42909 3.0419 6.70182 3.28571L5.58182 2.12C6.62909 1.64762 7.78545 1.38095 9 1.38095ZM2.58545 7.09524C3.17334 8.35248 4.08615 9.41171 5.2201 10.1525C6.35405 10.8933 7.66364 11.2859 9 11.2857C9.50182 11.2857 9.99636 11.2324 10.4545 11.1257L8.79636 9.38095C8.2903 9.32413 7.81805 9.08752 7.45816 8.7105C7.09827 8.33347 6.87243 7.83873 6.81818 7.30857L4.34545 4.71048C3.62545 5.3581 3.02182 6.16571 2.58545 7.09524Z"
                      fill="#908D95"
                      stroke="#F4F1FA"
                      strokeWidth="0.5"
                    />
                  </svg>
                )}
              </div>
              <div className="forget-wraper">
                <MyLink to={"/forget-password"}>
                  {t("pages.title.forgotpassword")}?
                </MyLink>
              </div>
              <div className="remember-wraper">
                <input type="checkbox" name="" />
                <label>{t("pages.title.remember_me")}</label>
              </div>
              <input
                className="submit-btn"
                type="submit"
                value={t("pages.title.sign_in")}
              />
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;

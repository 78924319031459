import { CircularProgress, Grid, Paper, Stack } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import CopyLink from "./CopyLink";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Steps from "./Steps";
import { CheckAffiliateHostedPageApi } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GetStarted = () => {
  const [loader, setloader] = useState(false);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const hostedId = urlSearchParams.get("id");
  const hostedState = urlSearchParams.get("state");
  const navigate = useNavigate();
  const { loading } = useContext(CustomProvider);
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const { t } = useTranslation();

  const getCheckAffiliateHostedApi = () => {
    setloader(true);
    let params = {
      hostedId: hostedId,
    };
    CheckAffiliateHostedPageApi(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          localStorage.setItem("affiliate_status", "yes");
          setloader(false);
          navigate("/get-started");
        } else {
          setalertData({
            show: true,
            message: "Server error occurred. Please try again later.",
            variant: "error",
          });
        }
        setloader(false);
      },
      (error) => {
        setloader(false);
      }
    );
  };

  useEffect(() => {
    if (hostedState && hostedId) {
      getCheckAffiliateHostedApi();
    }
  }, []);

  if (loading || loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }
  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Grid container fullWidth spacing={2}>
        <Grid item sm={5} xs={12}>
          <CopyLink />

          <Grid sx={{ marginTop: 1 }} container fullWidth spacing={2}>
            <Grid item sm={12} xs={12}>
              <Stack
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1.5fr 1fr",
                  gap: "35px",
                  marginBottom: "45px",
                }}
              >
                <Paper
                  sx={{
                    padding: "5%",
                    position: "relative", // Ensure the iframe takes the entire space
                    iframe: {
                      border: "none",
                      height: "36vh",
                      width: "55vh",
                    },
                    textAlign: "center",
                    boxShadow: "0 0 15px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <>
                    <h3>{t("pages.title.affiliatevideo")}</h3>
                    <iframe
                      src="https://player.vimeo.com/video/867415580?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="Paramétrage et Démonstration Novalya - Dashboard - French"
                    ></iframe>
                  </>
                </Paper>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={7} xs={12}>
          <Steps />
        </Grid>
      </Grid>
    </>
  );
};

export default GetStarted;

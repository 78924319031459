import React, {  useMemo} from 'react';
import {  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@mui/material';

export default function PaymentTable({payoutreportdata}) {

    // const [currentPage, setCurrentPage] = useState(1);
    // const [currentUsers, setCurrentUsers] = useState([]);
    // const [itemsPerPage, setItemsPerPage] = useState(10);

    // const totalPages = itemsPerPage === 0 ? 1 : Math.ceil(refferedUsers?.length / itemsPerPage);  // Handle 'View All' case

    // const indexOfLastUser = itemsPerPage === 0 ? refferedUsers.length : currentPage * itemsPerPage;
    // const indexOfFirstUser = itemsPerPage === 0 ? 0 : indexOfLastUser - itemsPerPage;

    const color = {
        "Approved": "#22C55E",  // Green color for Approved
        "Pending": "#FFC107",   // Yellow color for Pending
        null: "#22C55E" 
    };

    const memoizedFormatDate = useMemo(() => (dateInput, isUnix = false) => {
        const date = isUnix ? new Date(dateInput * 1000) : new Date(dateInput);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    }, []);


    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <div className='oct-payment-table'>
                <TableContainer className='sep-AffiliateTable-main' component={Paper} elevation={3} >
                    <Table sx={{ minWidth: 650 }} aria-label="payout report table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#f4f6f8' }}>
                                <TableCell><Typography variant="subtitle2">#</Typography></TableCell>
                                <TableCell><Typography variant="subtitle2">Period</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle2">Fee</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle2">Withdrawal Amount</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle2">Final Amount</Typography></TableCell>
                                <TableCell align="center"><Typography variant="subtitle2">Status</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!payoutreportdata?.length ? (
                                <TableRow>
                                    <TableCell colSpan={6} align="center" sx={{ padding: '16px' }}>
                                        <Typography variant="body1" color="textSecondary">No payout data available</Typography>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                payoutreportdata.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            backgroundColor: index % 2 === 0 ? '#f6f6f6' : 'inherit',
                                            '&:hover': { backgroundColor: '#f1f3f5' },
                                            transition: 'background-color 0.3s ease',
                                        }}
                                    >
                                        <TableCell align="left">{index + 1}</TableCell>
                                        <TableCell align="left">
                                            <Typography variant="body2" sx={{ fontWeight: '500' }}>{memoizedFormatDate(row.approvedat)}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body2">{row.payout_fee + " " + row.currency}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body2">
                                                {`${row.amount?.toFixed(2)} ${row.currency}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body2">
                                                {`${row.final_amount?.toFixed(2)} ${row.currency}`}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Chip label={row.status || "Approved"} sx={{
                                                backgroundColor: color[row.status],
                                                color: '#fff',
                                                padding: "3px 11px",
                                                borderRadius: "6px",
                                                textAlign: 'center',
                                                width: '140px',
                                            }} />
                                        </TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                
                {/* <CustomerTablePagination
                    skip={indexOfFirstUser}
                    limit={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    itemsPerPage={itemsPerPage}
                    page={currentPage}
                    totalPages={totalPages}
                    onPageChange={paginate}
                /> */}
            </div>

        </>
    );
}

const PaymentTablePagination = ({ page, totalPages, onPageChange, setItemsPerPage, itemsPerPage }) => {

    const handleNext = () => {
        if (page < totalPages) onPageChange(page + 1);
    };

    const handlePrev = () => {
        if (page > 1) onPageChange(page - 1);
    };

    const handlePageClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const renderPageNumbers = () => {
        const pages = [];
        if (totalPages <= 10) {
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            pages.push(1, 2);
            if (page > 4) pages.push('...');
            const start = Math.max(3, page - 2);
            const end = Math.min(totalPages - 2, page + 2);
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }
            if (page < totalPages - 3) pages.push('...');
            pages.push(totalPages - 1, totalPages);
        }
        return pages;
    };

    const handleItemsPerPageChange = (event) => {
        const value = event.target.value === 'View All' ? 0 : Number(event.target.value);
        setItemsPerPage(value);
        onPageChange(1); // Reset to first page
    };

    const btnStyle = {
        backgroundColor: "#fff",
        color: "grey",
        padding: "5px 15px",
        margin: "0 5px",
        borderRadius: "5px",
    };

    return (
        <div style={{ padding: '10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                width: "290px"
            }}>
                <label htmlFor="items-per-page" style={{
                    width: "110px"
                }}>Items per page:</label>
                <select
                    id="items-per-page"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    style={{
                        padding: '5px 10px',
                        borderRadius: '5px',
                        width: 'auto',
                        minWidth: "80px"
                    }}
                >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="0">View All</option>
                </select>
            </div>


            <div>
                {
                    itemsPerPage !== 0 && <div>
                        <button
                            onClick={handlePrev}
                            disabled={page === 1}
                            style={btnStyle}
                        >
                            {'<'}
                        </button>

                        {renderPageNumbers().map((pageNumber, index) => (
                            <button
                                key={index}
                                onClick={() => pageNumber !== '...' && handlePageClick(pageNumber)}
                                disabled={pageNumber === page || pageNumber === '...'}
                                style={{
                                    ...btnStyle,
                                    backgroundColor: pageNumber === page ? '#2c73ff' : '#f4f5f5',
                                    color: pageNumber === page ? 'white' : 'black',
                                    cursor: pageNumber === '...' ? 'default' : 'pointer',
                                }}
                            >
                                {pageNumber}
                            </button>
                        ))}

                        <button
                            onClick={handleNext}
                            disabled={page === totalPages}
                            style={btnStyle}
                        >
                            {'>'}
                        </button>
                    </div>
                }
            </div>
        </div>
    );
};


export default function upgardeHelper(plan,period){
    let result ={
        basic_1:true,
        basic_3:true,
        basic_12:true,
        buissness_1:true,
        buissness_3:true,
        buissness_12:true,
        unlimited_1:true,
        unlimited_3:true,
        unlimited_12:true,
        month_1:true,
        month_3:true,
        month_12:true,
        deafultTF:"quater",
      }
      
if (plan === "Basic" && period === "1_month") {
    result.basic_1=false;
}else if (plan === "Basic" && period === "3_month"){
    result.basic_3=false;
    result.unlimited_1=false;
    result.buissness_1=false;
    result.month_1=false;
    result.deafultTF="year";
   
}
else if (plan === "Basic" && period === "1_year"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:true,
        unlimited_1:false,
        unlimited_3:false,
        unlimited_12:true,
        month_1:false,
        month_3:false,
        month_12:true,
        deafultTF:"year",
      }
}

else if (plan === "Business" && period === "1_month"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:true,
        buissness_12:true,
        unlimited_1:true,
        unlimited_3:true,
        unlimited_12:true,
        month_1:false,
        month_3:true,
        month_12:true,
        deafultTF:"quater"
      }
}

else if (plan === "Business" && period === "3_month"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:true,
        unlimited_1:false,
        unlimited_3:true,
        unlimited_12:true,
        month_1:false,
        month_3:true,
        month_12:true,
        deafultTF:"year"
      }
}

else if (plan === "Business" && period === "1_year"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:false,
        unlimited_1:false,
        unlimited_3:false,
        unlimited_12:true,
        month_1:false,
        month_3:false,
        month_12:true,
        deafultTF:"year"
      }
}

else if (plan === "Unlimited_new" && period === "1_month"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:false,
        unlimited_1:false,
        unlimited_3:true,
        unlimited_12:true,
        month_1:false,
        month_3:true,
        month_12:true,
        deafultTF:"quater"
      }
}

else if (plan === "Unlimited_new" && period === "3_month"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:false,
        unlimited_1:false,
        unlimited_3:false,
        unlimited_12:true,
        month_1:false,
        month_3:false,
        month_12:true,
        deafultTF:"year"
      }
}

else if (plan === "Unlimited_new" && period === "1_year"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:false,
        unlimited_1:false,
        unlimited_3:false,
        unlimited_12:false,
        month_1:false,
        month_3:false,
        month_12:false,
        deafultTF:"year"
      }
}

else if (plan === "Starter" && period === "1_month"){
    result ={
        basic_1:true,
        basic_3:true,
        basic_12:true,
        buissness_1:true,
        buissness_3:true,
        buissness_12:true,
        unlimited_1:true,
        unlimited_3:true,
        unlimited_12:true,
        month_1:true,
        month_3:true,
        month_12:true,
        deafultTF:"quater"
      }
}

else if (plan === "Starter" && period === "1_year"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:true,
        buissness_1:false,
        buissness_3:false,
        buissness_12:true,
        unlimited_1:false,
        unlimited_3:true,
        unlimited_12:true,
        month_1:false,
        month_3:true,
        month_12:true,
        deafultTF:"year"
      }
}

else if (plan === "Pro" && period === "1_month"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:true,
        buissness_1:false,
        buissness_3:true,
        buissness_12:true,
        unlimited_1:true,
        unlimited_3:true,
        unlimited_12:true,
        month_1:true,
        month_3:true,
        month_12:true,
        deafultTF:"quater"
      }
}
else if (plan === "Pro" && period === "1_year"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:true,
        unlimited_1:false,
        unlimited_3:false,
        unlimited_12:true,
        month_1:false,
        month_3:false,
        month_12:true,
        deafultTF:"year"
      }
}
else if (plan === "Unlimited" && period === "1_month"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:true,
        buissness_3:true,
        buissness_12:true,
        unlimited_1:true,
        unlimited_3:true,
        unlimited_12:true,
        month_1:true,
        month_3:true,
        month_12:true,
        deafultTF:"quater"
      }
}
else if (plan === "Unlimited" && period === "1_year"){
    result ={
        basic_1:false,
        basic_3:false,
        basic_12:false,
        buissness_1:false,
        buissness_3:false,
        buissness_12:true,
        unlimited_1:false,
        unlimited_3:false,
        unlimited_12:true,
        month_1:false,
        month_3:false,
        month_12:true,
        deafultTF:"year"
      }
}
return {...result};

}
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";


const Transition = React.forwardRef((props, ref) => {
    return <Slide direction="down" ref={ref} {...props} />;
});
const DialogForNormal = () => {
    const [open, setOpen] = React.useState(true);
    const navigate = useNavigate();


    return (

        <Div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Alert!"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        This page is only for affiliate, you can become an affiliate by clicking on "Become An Affiliate"
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => navigate("/get-started")}>Become An Affiliate</Button>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </Div>

    );
};

export default DialogForNormal;
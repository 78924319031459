import React from "react";
import { useEffect, useState } from "react";
import "./instagramprospection.css";
import { FirstStep } from "./step/first-step";
import { SecondStep } from "./step/second-step";
import { ThirdStep } from "./step/third-step";
import { ForthStep } from "./step/fourth-step";
import { FifthStep } from "./step/fifth-step";
import { SixthStep } from "./step/sixth-step";
import {
  fetchInstagramGroupList,
  fetchInstagramKeywordList,
  fetchInstagramMessageList,
} from "backendServices/ApiCalls";
const stepCount = {
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
  step5: 5,
  step6: 6,
};

const Instagramprospection = () => {
  const [step, setStep] = useState(stepCount.step1);
  const [group, setGroup] = useState([]);
  const [messageData, setMessageData] = useState([]);
  const [keywordData, setKeywordData] = useState([]);

  const renderStepContent = () => {
    switch (step) {
      case stepCount.step1:
        return (
          <FirstStep
            group={group}
            messageData={messageData}
            fetchGroupData={fetchGroupData}
          />
        );

      case stepCount.step2:
        return <SecondStep />;

      case stepCount.step3:
        return <ThirdStep keywordData={keywordData} />;

      case stepCount.step4:
        return <ForthStep />;

      case stepCount.step5:
        return <FifthStep />;

      case stepCount.step6:
        return <SixthStep />;
      default:
        return null;
    }
  };

  const handleBackClick = () => {
    if (step > stepCount.step1) {
      setStep(step - 1);
    }
  };

  const handleNextClick = () => {
    if (step < stepCount.step6) {
      setStep(step + 1);
    }
  };

  function fetchGroupData() {
    fetchInstagramGroupList(
      {},
      (response) => {
        if (response?.data?.data) setGroup(response?.data?.data);
      },
      (error) => {}
    );
  }

  function fetchKeywordLists() {
    fetchInstagramKeywordList(
      {},
      (response) => {
        if (response?.data?.data) setKeywordData(response?.data?.data ?? []);
      },
      (error) => {}
    );
  }

  function fetchMessageLists() {
    let params = { type: "connect" };
    fetchInstagramMessageList(
      params,
      (response) => {
        if (response?.data?.data) setMessageData(response?.data?.data);
      },
      (error) => {}
    );
  }
  useEffect(() => {
    fetchGroupData();
    fetchMessageLists();
    fetchKeywordLists();
  }, []);

  return (
    <section>
      <div className="container">
        <div className="step-header">
          <h2 className="step-title">easily connect with new prospects</h2>
          <button className="tutorial-btn">
            <span className="tutorial-btn-icon">
              <span>
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.0051 3.04396C20.8824 2.59062 20.6431 2.17732 20.3111 1.84522C19.979 1.51313 19.5657 1.27382 19.1123 1.15112C17.4527 0.698486 10.7731 0.698486 10.7731 0.698486C10.7731 0.698486 4.0931 0.712187 2.43343 1.16482C1.98009 1.28752 1.56679 1.52685 1.23471 1.85896C0.902623 2.19107 0.663337 2.60439 0.540677 3.05775C0.0386653 6.00666 -0.156074 10.5001 0.554462 13.3311C0.677134 13.7844 0.916426 14.1977 1.24851 14.5298C1.58059 14.8619 1.99388 15.1012 2.44722 15.2239C4.10689 15.6765 10.7867 15.6765 10.7867 15.6765C10.7867 15.6765 17.4664 15.6765 19.126 15.2239C19.5793 15.1012 19.9926 14.8619 20.3247 14.5298C20.6568 14.1977 20.8961 13.7844 21.0188 13.3311C21.5483 10.378 21.7115 5.88728 21.0051 3.04396Z"
                    fill="#FF0000"
                  />
                  <path
                    d="M8.64694 11.3972L14.1882 8.18759L8.64694 4.97803V11.3972Z"
                    fill="white"
                  />
                </svg>
              </span>
            </span>
            Watch Tutorial Video
          </button>
        </div>

        <div className="shadow-card">
          <div className="step-wraper">
            <div className={`step-fill step-0${step}`}>
              <span className="fill-bar "></span>
              <div className="step-items fill-step">
                <span className="step-circle">1</span>
                <span className="step-text">Who & What</span>
              </div>
              <div className="step-items ">
                <span className="step-circle">2</span>
                <span className="step-text">Settings</span>
              </div>
              <div className="step-items">
                <span className="step-circle">3</span>
                <span className="step-text">Advance Targets</span>
              </div>
              <div className="step-items">
                <span className="step-circle">4</span>
                <span className="step-text">
                  Action <span> (Optionl)</span>
                </span>
              </div>
            </div>
          </div>
          {renderStepContent()}
        </div>

        <div className="step-btn-group">
          <button className="bordered-btn" onClick={handleBackClick}>
            BACK
          </button>
          <button
            className="bordered-btn bordered-btn-bg"
            onClick={handleNextClick}
          >
            NEXT
          </button>
        </div>
      </div>
    </section>
  );
};

export default Instagramprospection;

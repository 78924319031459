import React from "react";
import List from "@mui/material/List";
import { Grid, Box, Button } from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { createGroup } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



const CreateGroup = () => {
  const {t} = useTranslation()
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const navigate = useNavigate();
  const validationSchema = yup.object({
    url: yup.string("Enter url").required(t('pages.title.url_is_required')),
  });
  const onSubmitForm = (data, setSubmitting) => {
    let params = {
      url: data.url,
    };
    createGroup(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: "Group Created Successfully",
            variant: "success",
          });
          setSubmitting(false);
          navigate("/connect-group");
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  return (
    <JumboCardQuick title={t('pages.title.facebook_Group')}>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Box sx={{ position: "relative" }}>
        <Button
          variant="outlined"
          sx={{ position: "absolute", right: 0, top: -60 }}
          onClick={() => navigate("/connect-group")}
        >
          {t('pages.title.view_Groups')}
        </Button>
      </Box>
      <List disablePadding>
        <Formik
          validateOnChange={true}
          enableReinitialize="true"
          initialValues={{
            url: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(data, { setSubmitting }) => {
            setSubmitting(true);
            onSubmitForm(data, setSubmitting);
          }}
        >
          {({ isSubmitting, values }) => (
            <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
              <Grid container spacing={2}>
                <Grid item sm={8} sx={{ width: { xs: "100%" } }}>
                  <JumboTextField
                    fullWidth
                    label={t('pages.title.facebook_group_url')}
                    name="url"
                    type="text"
                  />
                </Grid>

                <Grid
                  item
                  sm={4}
                  sx={{ width: { xs: "100%" }, justifyContent: "end" }}
                >
                  <LoadingButton
                    fullWidth
                    type="button"
                    variant="contained"
                    id="verfiyurlfbgroup"
                    size="large"
                    sx={{ mb: 3 }}
                    loading={isSubmitting}
                  >
                    {t('pages.title.submit')}
                  </LoadingButton>
                </Grid>
                <p id="group_name"></p>
              </Grid>
            </Form>
          )}
        </Formik>
      </List>
    </JumboCardQuick>
  );
};

export default CreateGroup;

import React from "react";
import "./index.css";
import { useTranslation } from "react-i18next";

const TopHeader = ({ title, url }) => {
  const { t } = useTranslation();

  return (
    <div className="top-header">
      <div className="container-ctm">
        <div className="top-header-inner">
          <div className="header-wel-text">{title}</div>
          <div className="header-left-text">
            <div className="title-box">
              <a
                className="tutorial-btn"
                href={url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <span className="tutorial-btn-icon">
                  <span>
                    <svg
                      width="22"
                      height="16"
                      viewBox="0 0 22 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.0051 3.04396C20.8824 2.59062 20.6431 2.17732 20.3111 1.84522C19.979 1.51313 19.5657 1.27382 19.1123 1.15112C17.4527 0.698486 10.7731 0.698486 10.7731 0.698486C10.7731 0.698486 4.0931 0.712187 2.43343 1.16482C1.98009 1.28752 1.56679 1.52685 1.23471 1.85896C0.902623 2.19107 0.663337 2.60439 0.540677 3.05775C0.0386653 6.00666 -0.156074 10.5001 0.554462 13.3311C0.677134 13.7844 0.916426 14.1977 1.24851 14.5298C1.58059 14.8619 1.99388 15.1012 2.44722 15.2239C4.10689 15.6765 10.7867 15.6765 10.7867 15.6765C10.7867 15.6765 17.4664 15.6765 19.126 15.2239C19.5793 15.1012 19.9926 14.8619 20.3247 14.5298C20.6568 14.1977 20.8961 13.7844 21.0188 13.3311C21.5483 10.378 21.7115 5.88728 21.0051 3.04396Z"
                        fill="#FF0000"
                      ></path>
                      <path
                        d="M8.64694 11.3972L14.1882 8.18759L8.64694 4.97803V11.3972Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                </span>
               <span> {t("pages.title.Watch Tutorial Video")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;

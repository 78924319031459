import React from 'react';
import ArticleItem from "./ArticleItem";
import List from "@mui/material/List";
import { popularArticles } from "./data";

const ArticlesList = ({ newsData, imageUrl }) => {
    return (
        <List disablePadding>
            {
                newsData?.map((item, index) => (
                    <ArticleItem article={item} key={index} imageUrl={imageUrl} />
                ))
            }
        </List>
    );
};

export default ArticlesList;

import { useTranslation } from "react-i18next";
import "../instagramprospection.css";

export function SecondStep() {
  const { t } = useTranslation();

  let RetargetOption = [
    { value: "yes", text: `${t("pages.title.yes")}` },
    { value: "no", text: `${t("pages.title.no")}` },
  ];

  let fromWhenOption = [
    { value: "90", text: `${t("pages.title.3months")}` },
    { value: "180", text: `${t("pages.title.6months")}` },
    { value: "360", text: `${t("pages.title.1year")}` },
    { value: "alltime", text: `${t("pages.title.alltime")}` },
    { value: "custom", text: `${t("pages.title.custom")}` },
  ];

  let requestOption = ["5", "10", "25", "50", t("pages.title.custom")];

  let intervalOption = [
    { value: "30-60", text: "30S TO 60S" },
    { value: "1-3", text: "1 TO 3MIN" },
    { value: "3-5", text: "3 TO 5MIN" },
    { value: "5-10", text: "5 TO 10MIN" },
  ];

  return (
    <div className="col-wraper col-4-4">
      <div className="">
        <span className="cards-title">Request</span>
        <div className="bordered-cards">
          {requestOption.map((item, index) => {
            return (
              <div className="gray-check active-check">
                <span className="text-center">{item}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <span className="cards-title">Interval</span>
        <div className="bordered-cards">
          {intervalOption.map((item, index) => {
            return (
              <div
                className="gray-check
              active-check"
              >
                <span className="text-center">{item.text}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <span className="cards-title">Lunch</span>
        <div className="bordered-cards">
          <div className="gray-check active-check">
            <span className="text-center">From to start</span>
          </div>
          <div className="gray-check">
            <span className="text-center">Custom</span>
          </div>
        </div>
      </div>
      <div className="">
        <span className="cards-title">Retarget same user?</span>
        <div className="bordered-cards">
          <div className="square-checkbox-wraper">
            {RetargetOption.map((item, index) => {
              return (
                <div className="square-checkbox">
                  <input
                    type="radio"
                    id={item.value}
                    name="radio-group"
                    checked=""
                  />
                  <label for={item.value}>{item.text}</label>
                </div>
              );
            })}
          </div>
          <span className="inner-title-sm">Retarget same user?</span>
          <select className="step-select">
            {fromWhenOption.map((item, index) => {
              return <option value={item.value}>{item.text}</option>;
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import "./style.css";
import { Button, CircularProgress, Grid } from "@mui/material";
import { eventRegistration } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";

const NovalysEvent = () => {
  const [loader, setLoader] = useState(false);
  const { t } = useTranslation();

  const hanldeRegister = () => {
    setLoader(true);
    let params = {
      itemid: "Affiliate-Fee-EUR-Yearly",
    };
    eventRegistration(
      params,
      (response) => {
        setLoader(false);
      },
      (error) => {}
    );
  };

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item md={2} lg={2} sm={12}></Grid>
        <Grid item md={8} lg={8} sm={12}>
          <setion className="container">
            <h1 style={{ fontSize: 20 }}>
              Pré-inscription Évènement : Anniversaire Novalya
            </h1>
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-12"></div>
              <div className="col-lg-8 col-md-8 col-sm-12">
                <article className="card fl-left bg1">
                  <section className="date">
                    <time datetime="23th feb">
                      <span style={{ color: "white" }}>18</span>
                      <span style={{ color: "white", marginTop: 5 }}>Mai</span>
                    </time>
                  </section>
                  <section className="card-cont">
                    <br></br>
                    <div className="even-date">
                      <i className="fa fa-calendar"></i>
                      <time>
                        <span style={{ color: "white" }}>
                          Week end du 18 Mai (à définir)
                        </span>
                      </time>
                    </div>
                    <div className="even-info">
                      <i className="fa fa-map-marker"></i>
                      <p style={{ color: "white" }}>Lieu (à définir)</p>
                    </div>
                    <div className="even-info">
                      <i className="fa fa-eur"></i>
                      <p style={{ color: "white" }}>
                        Prix spécial pré-vente : 49€ seulement
                      </p>
                    </div>
                    <div className="even-info">
                      <i className="fa fa-file-text-o"></i>
                      <p style={{ color: "white" }}>
                        Possibilité de réserver pendant 48h seulement
                        <br></br>
                        Réservez-vite
                      </p>
                      <br />
                      <br />
                      <br />
                    </div>
                    <Button
                      onClick={() => hanldeRegister()}
                      sx={{ ml: 15, justifyContent: "center", marginTop: 4 }}
                      variant="outlined"
                    >
                      {t("pages.title.register")}
                    </Button>
                  </section>
                </article>
              </div>
            </div>
          </setion>
        </Grid>
      </Grid>
    </>
  );
};

export default NovalysEvent;

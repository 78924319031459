import { fetchCompaigns } from "backendServices/ApiCalls";
import React, { createContext, useEffect, useState } from "react";

export const CampaignContext = createContext({
  groupDetail: {},
  setGroupDetail: () => {},
  interval: "",
  setInterval: () => {},
  message: "",
  setmessage: () => {},
  messageName: "",
  setMessageName: () => {},
  userIds: [],
  setUserIds: () => {},
});

export const CampaignProvider = ({ children }) => {
  const [groupDetail, setGroupDetail] = useState({});
  const [interval, setInterval] = useState("");
  const [message, setmessage] = useState("");
  const [messageName, setMessageName] = useState("");
  const [userIds, setUserIds] = useState([]); // TODO: Replace with actual user id [1
  const [apiData, setApiData] = useState(null); // Track if groups have been fetched
  useEffect(() => {
    fetchCompaigns(
      {},
      (response) => {
        const data = response?.data?.data[0];
        setApiData(data);
      },
      (error) => {}
    );
  }, []);
  useEffect(() => {
    if (apiData) {
      setGroupDetail({
        selectedGroup: apiData?.tag?.name,
        numPeopleInGroup: apiData?.tag?.total_user,
        id: apiData?.group_id,
      });
      setInterval(apiData?.time_interval);
      setmessage(apiData?.message_id);
      setMessageName(apiData?.MessageDatum?.name);
    }
  }, [apiData]);

  const contextValue = {
    message,
    setmessage,
    groupDetail,
    setGroupDetail,
    interval,
    setInterval,
    messageName,
    setMessageName,
    userIds,
    setUserIds,
  };
  return (
    <CampaignContext.Provider value={contextValue}>
      {children}
    </CampaignContext.Provider>
  );
};

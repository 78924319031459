import React, { useEffect } from "react";
import List from "@mui/material/List";
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { createSection, fetchSectionList } from "backendServices/ApiCalls";
import { LoadingButton } from "@mui/lab";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useNavigate } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { t } from "i18next";
import "./create.css";

const enumName = {
  firstName: "[first name]",
  lastName: "[last name]",
};
const colors = [{ color: "#A5B4FC" }, { color: "#D8B4FE" }];
const CreateSection = () => {
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = React.useState(false);
  const [isEmojiPickerId, setIsEmojiPickerId] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);
  const navigate = useNavigate();
  const [sectionData, setSectionData] = React.useState([]);

  const [fields, setFields] = React.useState([{ id: 0, value: "" }]);
  const [section, setSection] = React.useState("");
  const handleAddField = () => {
    const newField = { id: fields.length, value: "" };
    setFields([...fields, newField]);
  };

  useEffect(() => {
    fetchSectionList(
      {},
      (response) => {
        setSectionData(response?.data?.data);
      },
      (error) => {}
    );
  }, []);

  const handleFieldChange = (id, value) => {
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        return { ...field, value };
      }
      return field;
    });
    setFields(updatedFields);
  };
  const handleDeleteField = (id) => {
    const updatedFields = fields.filter((field) => field.id !== id);
    setFields(updatedFields);
  };
  const onSubmitForm = () => {
    const trimmedName = section.trim();

    if (!trimmedName) {
      setalertData({
        show: true,
        message: "Name cannot be empty",
        variant: "error",
      });
      setSubmitting(false);

      return;
    }

    if (sectionData.some((section) => section.section === trimmedName)) {
      setalertData({
        show: true,
        message: "Section name already exists",
        variant: "error",
      });
      setSubmitting(false);
      return;
    }

    let params = {
      section: section,
      varient: fields.map((field) => field.value),
    };
    if (fields.length < 2) {
      setalertData({
        show: true,
        message: `${t("pages.title.Please select atleast two sections")}`,
        variant: "error",
      });
      return;
    }
    for (const field of fields) {
      if (!field.value || !field.value.trim()) {
        setalertData({
          show: true,
          message: `${t("pages.title.Please fill in all variant fields")}`,
          variant: "error",
        });
        return;
      }
    }

    createSection(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: `${t("pages.title.Created Successfully")}`,
            variant: "success",
          });
          setSubmitting(false);
          navigate("/sections");
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
        setSubmitting(false);
      }
    );
  };

  return (
    <Box className="outer-contaier">
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Box className="header">
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {t("pages.title.create_Section")}
        </Typography>
        <Button variant="outlined" onClick={() => navigate("/sections")}>
          <ArrowBackIosOutlinedIcon sx={{ height: "50px" }} />
        </Button>
      </Box>

      <Box className="form-contaier edit-section-wraper create-section-wraper-new">
        <Box>
          <List disablePadding>
            <Box style={{ textAlign: "left", padding: "24px 24px 0" }}>
              <>
                <Grid container spacing={2}>
                  <Grid item sm={12} sx={{ width: { xs: "100%" } }}>
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "20px",
                      }}
                    >
                      <Box>
                        <Typography variant="h5">
                          Name of your Section
                        </Typography>

                        <TextField
                          fullWidth
                          className="form-field-top"
                          placeholder="Write the name here"
                          name="section"
                          type="text"
                          sx={{
                            backgroundColor: "#F6F6F6",
                            height: "20px",
                            border: "none",
                          }}
                          onChange={(e) => {
                            setSection(e.target.value);
                          }}
                          value={section}
                        />
                      </Box>
                    </Grid>
                    <Box className="new-section-designing-inner" mt={8}>
                      <Typography>Write your Variants</Typography>
                      {fields &&
                        fields.map((field, index) => (
                          <Box
                            display="flex"
                            flexDirection="column"
                            my={2}
                            p={2}
                            borderLeft={10}
                            borderColor={
                              index % 2 === 0
                                ? colors[0].color
                                : colors[1].color
                            }
                            bgcolor="#f9f9f9"
                            borderRadius={3}
                          >
                            <TextField
                              key={field.id}
                              placeholder={t("pages.title.varient_name")}
                              name={`varient_${field.id}`}
                              type="text"
                              value={field.value}
                              multiline
                              minRows={1}
                              onChange={(e) =>
                                handleFieldChange(field.id, e.target.value)
                              }
                              sx={{
                                backgroundColor: "#F6F6F6",
                                borderRadius: "10px",
                                border: "none",
                                "& .MuiOutlinedInput-root": {
                                  "& fieldset": {
                                    borderRadius: "10px",
                                  },
                                  "&:hover fieldset": {
                                    borderColor: "#EBECF3",
                                  },
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#EBECF3",
                                  },
                                },
                              }}
                            />
                            <Box display="flex" mt={2} alignItems="center">
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: 8 }}
                                onClick={() => {
                                  const updatedValue =
                                    field.value + enumName.firstName; // Append emoji to existing value
                                  handleFieldChange(field.id, updatedValue);
                                }}
                              >
                                {t("pages.title.first_name")}
                              </Button>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginRight: 8 }}
                                onClick={() => {
                                  const updatedValue =
                                    field.value + enumName.lastName; // Append emoji to existing value
                                  handleFieldChange(field.id, updatedValue);
                                }}
                              >
                                {t("pages.title.last_name")}
                              </Button>

                              <IconButton
                                className="emoji-icons"
                                color="primary"
                                style={{ marginRight: 8 }}
                              >
                                <EmojiEmotionsOutlinedIcon
                                  onClick={() => {
                                    setIsEmojiPickerOpen(!isEmojiPickerOpen);
                                    setIsEmojiPickerId(field.id);
                                  }}
                                />
                              </IconButton>
                              {isEmojiPickerOpen &&
                                isEmojiPickerId === field.id && (
                                  <EmojiPicker
                                    onEmojiClick={(emoji) => {
                                      const updatedValue = [
                                        ...field.value,
                                        emoji.emoji,
                                      ];
                                      const newValueString =
                                        updatedValue.join("");
                                      handleFieldChange(
                                        field.id,
                                        newValueString
                                      );
                                      setIsEmojiPickerOpen(false);
                                    }}
                                    pickerStyle={{
                                      width: "100%",
                                      marginTop: 8,
                                    }}
                                  />
                                )}

                              <IconButton
                                color="secondary"
                                onClick={() => handleDeleteField(field.id)}
                              >
                                <svg
                                  width="10"
                                  height="12"
                                  viewBox="0 0 10 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.875 12C1.53125 12 1.23708 11.8696 0.9925 11.6087C0.747917 11.3478 0.625417 11.0338 0.625 10.6667V2H0V0.666667H3.125V0H6.875V0.666667H10V2H9.375V10.6667C9.375 11.0333 9.25271 11.3473 9.00813 11.6087C8.76354 11.87 8.46917 12.0004 8.125 12H1.875ZM8.125 2H1.875V10.6667H8.125V2ZM3.125 9.33333H4.375V3.33333H3.125V9.33333ZM5.625 9.33333H6.875V3.33333H5.625V9.33333Z"
                                    fill="white"
                                  />
                                </svg>
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Grid>
                </Grid>
                <Box
                  className="footer-btn-group"
                  item
                  sx={{
                    display: "flex",
                    width: "100%",
                    backgroundColor: "#F4F1FA",
                    justifyContent: "space-between",
                    padding: "10px 20px",
                    borderRadius: "0 0 12px 12px",
                    mt: 1,
                  }}
                >
                  <LoadingButton
                    variant="contained"
                    type="button"
                    color="success"
                    onClick={handleAddField}
                    sx={{ ml: "0px" }}
                  >
                    {t("pages.title.add_Variant")}
                  </LoadingButton>
                  <LoadingButton
                    id="form-section"
                    type="submit"
                    variant="contained"
                    loading={submitting}
                    onClick={onSubmitForm}
                  >
                    {t("pages.title.submit")}
                  </LoadingButton>
                </Box>
              </>
            </Box>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateSection;

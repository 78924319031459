import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import Div from '@jumbo/shared/Div/Div';
import { CircularProgress } from '@mui/material';
import {useTranslation} from "react-i18next";


const VISIBLE_FIELDS = ['sr', 'sender', 'amount', 'date'];
const ApprovedResiduel = () => {
  const {t} = useTranslation();
  const [loading,setLoading]=useState(true)
  const [investmentdata,setInvestmentData]=useState([
    {
      'id': 1,
      'rank': 'NovaFree +',
      'amount': '€1000',
      'status': 'Approved'
    },
    {
      'id': 2,
      'rank': 'NovaFree +',
      'amount': '€1000',
      'status': 'Approved'
    },
    {
      'id': 3,
      'rank': 'NovaFree +',
      'amount': '€1000',
      'status': 'Approved'
    },
    {
      'id': 4,
      'rank': 'NovaFree +',
      'amount': '€1000',
      'status': 'Approved'
    },
    {
      'id': 5,
      'rank': 'NovaFree +',
      'amount': '€1000',
      'status': 'Approved'
    }
  ])
  const InvestmentData =()=>{
    setLoading(false)
 
  }
    
useEffect(()=>{
    InvestmentData();
},[])


if(loading){
  return  <Div
  sx={{
      display: 'flex',
      minWidth: 0,
      alignItems: 'center',
      alignContent: 'center',
      height: '100%',
  }}
>
  <CircularProgress sx={{m: '-40px auto 0'}}/>
</Div>
}

const initialState= {initialState:{
  columns:{
      columnVisibilityModel:{
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
      }
  }
}
}




const gridDesign = {
  '& .MuiDataGrid-toolbarContainer': {
    '& .MuiButton-text': {
      fontSize: '13px !important',
    },
    '& .MuiBadge-badge': {
      backgroundColor: '#074682',
    },
    '& .MuiInput-root':{
      borderRadius: 2,
      paddingLeft: 2,
      overflow: 'hidden',
    },

  }
}
      // Otherwise filter will be applied on fields such as the hidden column id
      const columns = [
        {
            field: "id",
            headerName: "ID",
            dataGeneratorUniquenessEnabled: true,
            width: 300,
            editable: true,
            groupable: false,
            aggregable: false,
        },
        {
            field: "rank",
            headerName: "Rank",  
            dataGeneratorUniquenessEnabled: true,
            width: 300,
            editable: true,
            groupable: false,
            aggregable: false,
        },
        {
            field: "amount",
            headerName: "Amount",  
            dataGeneratorUniquenessEnabled: true,
            width: 300,
            editable: true,
            groupable: false,
            aggregable: false,  
        },
        {
            field: "status",
            headerName: "Status",  
            dataGeneratorUniquenessEnabled: true,
            width: 300,
            editable: true,
            groupable: false,
            aggregable: false,    
        },
    ]

    

  return (
    <JumboDemoCard
    title={t('pages.title.approvedresiduel')}
    wrapperSx={{backgroundColor: 'background.paper', pt: 0}}
>
    <Box sx={{ height: 460, width: 1 }}>
    <DataGrid
        initialState={{
          initialState,
          pagination: { paginationModel: { pageSize: 6 } },
        }}
        rows={investmentdata}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        sx={gridDesign}
        pageSizeOptions={[6, 12, 18, 24, 30]}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
    </Box>
  </JumboDemoCard>
  )
}

export default ApprovedResiduel
import React from 'react'
import AffiliateHeaderMenu from './AffiliateHeaderMenu'

export default function AffiliateLayout(props) {
    return (
        <>
            <AffiliateHeaderMenu />
            <>{props.children}</>
        </>

    )
}
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import {
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
} from "@mui/material";
import { getunilevelreports } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import { useTranslation } from "react-i18next";

const UnilevelBonusReport = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [unilevelbonus, setUnilevelBonusData] = useState([]);

  const currentMonth = new Date().getMonth() + 1;

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    UnilevelData();
  };

  const UnilevelData = () => {
    let params = {
      month: selectedMonth,
    };
    setLoading(true);
    getunilevelreports(
      params,
      (response) => {
        setLoading(false);
        handleClose();
        setUnilevelBonusData(response?.data?.data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    UnilevelData();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const columns = [
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return params.value + " " + params.row.currency;
      },
    },
    {
      field: "username",
      headerName: "Sender",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "type",
      headerName: "Type",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "payOutPer",
      headerName: "% Payout",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    // {
    //   field: "details",
    //   headerName: "Details",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 400,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    {
      field: "createdat",
      headerName: "Date",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        const date = new Date(params.value);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
        return formattedDate;
      },
    },
  ];

  const rows = unilevelbonus;

  return (
    <>
      <JumboDemoCard
        title={t("pages.title.LevelCommission")}
        wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} textAlign={"right"}>
            <Button variant="contained" onClick={handleClickOpen}>
              Select Month
            </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Box sx={{ height: 460, width: 1 }}>
              <DataGrid
                initialState={{
                  initialState,
                  pagination: { paginationModel: { pageSize: 6 } },
                }}
                rows={rows}
                columns={columns}
                slots={{ toolbar: GridToolbar }}
                sx={gridDesign}
                pageSizeOptions={[6, 12, 18, 24, 30]}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </JumboDemoCard>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Level Comission"}</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <FormControl sx={{ mt: 2 }}>
            <InputLabel id="demo-simple-select-label">Month</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              style={{ width: 300 }}
              label="Month"
            >
              <MenuItem value={2}>{"February"}</MenuItem>
              <MenuItem value={3}>{"March"}</MenuItem>
              <MenuItem value={4}>{"April"}</MenuItem>
              <MenuItem value={5}>{"May"}</MenuItem>
              <MenuItem value={6}>{"June"}</MenuItem>
              <MenuItem value={7}>{"July"}</MenuItem>
              <MenuItem value={8}>{"August"}</MenuItem>
            </Select>
          </FormControl>
          <br />
          <Button variant="contained" sx={{ mt: 2 }} onClick={handleSubmit}>
            Submit
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UnilevelBonusReport;

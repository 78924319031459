export function SixthStep() {
  return (
    <div className="col-wraper col-2-video ">
      <div className="">
        <span className="cards-title text-center">LAST STEP</span>
        <p className="gray-text text-lg text-center">
          Congratulations on joining novalya watch this video to set up your
          account
        </p>
        <div className="video-wrap">
          <img src="assets/images/video-img.png" alt="" />
        </div>
      </div>
      <div className="shadow-card">
        <div className="step-vertial">
          <div className="vertical-step-01 vertical-fill-bar">
            <div className="step-text-head fill-step">
              <span className="step-circle">1</span>
              <span className="cards-title">Book a free Set up Meeting</span>
            </div>
            <div className="vertical-step-text">
              <button className="bordered-btn bordered-btn-bg">
                <img className="flag-img" src="assets/images/french-flag.png"  alt=""/>{" "}
                French Meeting
              </button>{" "}
              <br />
              <button className="bordered-btn bordered-btn-bg">
                <img
                  className="flag-img"
                  src="assets/images/english-flag.png"
                  alt=""
                />{" "}
                English Meeting
              </button>
            </div>
          </div>
          <div className="vertical-step-02">
            <div className="step-text-head">
              <span className="step-circle">2</span>
              <span className="cards-title">Join Our Community</span>
            </div>
            <div className="social-btn-box vertical-step-text">
              <button className="bordered-btn gray-btn-bg">
                <span className="social-icon-bg">
                  <svg
                    width="11"
                    height="20"
                    viewBox="0 0 11 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 11.5H9.5L10.5 7.5H7V5.5C7 4.47 7 3.5 9 3.5H10.5V0.14C10.174 0.0970001 8.943 0 7.643 0C4.928 0 3 1.657 3 4.7V7.5H0V11.5H3V20H7V11.5Z"
                      fill="#1758B4"
                    />
                  </svg>
                </span>{" "}
                Official Page
              </button>
              <button className="bordered-btn gray-btn-bg">
                <span className="social-icon-bg">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.335564 10.6163C0.69951 10.9802 1.06346 11.0947 1.78158 11.0947L9.65614 11.1364C9.72893 11.1364 9.79106 11.1364 9.82258 11.1777C9.85365 11.2088 9.86385 11.2714 9.86385 11.3339L9.89492 19.2189C9.90557 19.9366 10.0201 20.3005 10.384 20.6644C10.8731 21.1637 11.5598 21.0808 12.0693 20.5815C12.34 20.3107 12.5584 19.8638 12.7559 19.4475L20.7139 2.28436C21.1298 1.41091 21.0778 0.765589 20.6518 0.339074C20.235 -0.0767895 19.5901 -0.128717 18.7166 0.287146L1.55256 8.24491C1.13625 8.44242 0.689302 8.66078 0.418561 8.93151C-0.0807554 9.44102 -0.163753 10.117 0.335564 10.6163Z"
                      fill="#0CB0D5"
                    />
                  </svg>
                </span>{" "}
                Join the channel
              </button>
              <button className="bordered-btn gray-btn-bg">
                <span className="social-icon-bg">
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5143 2.5114C21.388 2.04496 21.1418 1.61972 20.8001 1.27802C20.4585 0.936327 20.0332 0.690101 19.5668 0.563862C17.8592 0.0981445 10.9865 0.0981445 10.9865 0.0981445C10.9865 0.0981445 4.11353 0.112242 2.4059 0.577959C1.93945 0.704205 1.51421 0.950444 1.17253 1.29215C0.830851 1.63387 0.584649 2.05913 0.458445 2.52558C-0.058074 5.55971 -0.258441 10.183 0.472628 13.0958C0.598845 13.5622 0.845053 13.9875 1.18673 14.3292C1.52841 14.6709 1.95364 14.9171 2.42008 15.0433C4.12771 15.509 11.0005 15.509 11.0005 15.509C11.0005 15.509 17.8733 15.509 19.5808 15.0433C20.0473 14.9171 20.4725 14.6709 20.8142 14.3292C21.1559 13.9875 21.4021 13.5622 21.5284 13.0958C22.0732 10.0574 22.241 5.43688 21.5143 2.5114Z"
                      fill="#FF0000"
                    />
                    <path
                      d="M8.79883 11.1059L14.5002 7.80354L8.79883 4.50122V11.1059Z"
                      fill="white"
                    />
                  </svg>
                </span>{" "}
                Youtube Channel
              </button>
            </div>
          </div>
          <div className="vertical-step-03">
            <div className="step-text-head">
              <span className="step-circle">3</span>
              <span className="cards-title">Login to your Account</span>
            </div>
            <div className="vertical-step-text">
              <button className="bordered-btn bordered-btn-bg">
                Access my account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

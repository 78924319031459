import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Link,
  Icon,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import { fetchGroupList, deleteGroup } from "backendServices/ApiCalls";
import { useNavigate } from "react-router-dom";
import { useModalState } from "app/hooks/use-modal-state";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from "react-i18next";
import { useJumboTheme } from "@jumbo/hooks";
import VideoIcon from "@mui/icons-material/VideoLibrary";

const GroupsList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const navigate = useNavigate();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const { theme } = useJumboTheme();
  const [storedLocale, setStoredLocale] = React.useState(
    localStorage.getItem("selectedLocale")
  );

  useEffect(() => {
    setTimeout(() => {
      setStoredLocale(localStorage.getItem("selectedLocale"));
    }, 300);
  }, [theme]);
  let params = {};
  const FetchGroupData = () => {
    fetchGroupList(
      params,
      (response) => {
        setLoading(false);
        if (response?.data?.data) setGroupData(response?.data?.data ?? []);
      },
      (error) => {}
    );
  };
  const deleteModal = useModalState();
  useEffect(() => {
    FetchGroupData();
  }, []);

  function handleDelete() {
    deleteGroup(
      { deleteId },
      (response) => {
        setalertData({
          show: true,
          message: "Group Deleted Successfully",
          variant: "success",
        });
        deleteModal.onClose();
        FetchGroupData();
      },
      (error) => {}
    );
  }

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      dataGeneratorUniquenessEnabled: true,
      width: 600,
    },
    {
      field: "group_type",
      headerName: "Type",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) =>
        params.row.group_type ? params.row.group_type : "All members",
    },
    {
      field: "action",
      headerName: "",
      minWidth: 320,
      align: "right",
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              minHeight: 36,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              onClick={() => {
                navigate("/connect?group_id=" + params?.row?.id);
              }}
              sx={{ minHeight: 36, ml: 2 }}
              variant="outlined"
            >
              {t("pages.title.connect")}
            </Button>
            <Link href={params.row.url} target="_blank" underline="none">
              <FacebookIcon sx={{ width: 100, minHeight: 36 }} />
            </Link>

            <Button
              color="error"
              onClick={() => {
                setDeleteId(params.row.id);
                deleteModal.onOpen();
              }}
              sx={{ minHeight: 36, ml: 2 }}
              variant="outlined"
            >
              {t("pages.title.delete")}
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <JumboDemoCard
      title={
        storedLocale === "fr-FR"
          ? "Liste des Groupes Facebook Enregistrés"
          : "List of Saved Facebook Groups"
      }
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Box sx={{ height: 460, width: 1, position: "relative" }}>
        <Box sx={{ position: "relative", top: -12 }}>
          {storedLocale === "fr-FR" ? (
            <>
              <Icon color="primary">
                <VideoIcon />
              </Icon>{" "}
              <a
                href="https://player.vimeo.com/video/866857782?badge=0&autopause=0&player_id=0&app_id=58479"
                target="_blank"
                rel="noreferrer"
              >
                VOIR LA VIDEO EXPLICATIVE
              </a>
            </>
          ) : (
            <>
              <Icon color="primary">
                <VideoIcon />
              </Icon>{" "}
              <a
                href="https://player.vimeo.com/video/867636365?badge=0&autopause=0&player_id=0&app_id=58479"
                target="_blank"
                rel="noreferrer"
              >
                Watch Tutorial Video
              </a>
            </>
          )}
        </Box>
        <Button
          variant="outlined"
          sx={{ position: "absolute", right: 0, top: -36 }}
          onClick={() => navigate("/create/groups")}
        >
          {t("pages.title.facebook_Group")}
        </Button>

        <DataGrid
          rows={groupData}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
      <Dialog
        maxWidth="xs"
        open={deleteModal.isOpen}
        sx={{
          ".MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& .MuiDialog-paper": { mt: 12, width: "80%", maxHeight: 435 },
        }}
      >
        <DialogContent>
          <Typography color="grey.600" mt={3} variant="body2">
            Are you sure you want to delete this url{" "}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteModal.onClose}>Cancel</Button>
          <Button onClick={handleDelete} variant="contained">
            {t("pages.title.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </JumboDemoCard>
  );
};

export default GroupsList;

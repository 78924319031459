import React, { useEffect, useState } from "react";

import {
  fetchPlanLimitDetails,
  updateUserGender,
} from "backendServices/ApiCalls";
import { useTranslation } from "react-i18next";

const Instagram = ({}) => {
  const [showVideo, setShowVideo] = useState(true);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [planLimit, setPlanLimit] = useState({});
  const [gender, setGender] = useState(planLimit.new_packages?.gender);
  const { t } = useTranslation();

  const handleGenderChange = (e) => {
    const genderVal = e.target.value;
    let params = {
      gender: genderVal,
    };
    updateUserGender(
      params,
      (response) => {
        if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: "Updated Successfully",
            variant: "success",
          });
          setGender(genderVal);
        }
      },
      (error) => {
        setalertData({
          show: true,
          message: error?.response?.data?.message,
          variant: "error",
        });
      }
    );
  };

  const fetchPlanLimitDetailsFunction = async () => {
    let params = {};
    fetchPlanLimitDetails(
      params,
      (response) => {
        if (response?.data?.data) {
          setPlanLimit(response?.data?.data);
          setGender(response?.data?.data?.new_packages?.gender);
        }
      },
      (error) => {}
    );
  };

  useEffect(() => {
    fetchPlanLimitDetailsFunction();
  }, []);

  return (
    <div className="instagram-profile">
      <div className="customize-box text-center">
        <h2>Coming Soon</h2>
      </div>
    </div>
  );
};

export default Instagram;

import React, { useEffect, useState } from "react";
import PopularAgents from "../../shared/widgets/PopularAgents";
import { CircularProgress, Grid } from "@mui/material";
import { PersonalReferrals } from "backendServices/ApiCalls";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div/Div";

export default function ViewReferrals() {
  const { ...authOptions } = useJumboAuth();
  const [picturelink, setPictureLink] = useState();
  const [loader, setLoader] = useState(false);
  const [referralusersdata, setReferralUsersData] = useState([]);

  const ReferralUsers = () => {
    setLoader(true);
    PersonalReferrals(
      (response) => {
        setReferralUsersData(response?.data?.data?.enteries);
        setPictureLink(response?.data?.data?.picturelink);
        setLoader(false);
      },
      (error) => {
        setLoader(false);
      }
    );
  };

  useEffect(() => {
    ReferralUsers();
  }, []);

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Grid item xs={12}>
      <PopularAgents picturelink={picturelink} data={referralusersdata} />
    </Grid>
  );
}

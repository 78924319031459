import "../instagramprospection.css";

export function FifthStep() {
  return (
    <div className="col-wraper col-1-sm ">
      <div className="">
        <div className="bordered-cards">
          <img className="welcome-img" src="assets/images/welcome-img.png" />
          <span className="gredient-title">Congratulations!</span>
          <p className="gray-text">
            You are one click away from <br /> finding new hot leads
          </p>
          <button className="bordered-btn bordered-btn-bg w-100">
            START WITH NOVALYA !
          </button>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import JumboDemoCard from '@jumbo/components/JumboDemoCard/JumboDemoCard';
import Div from '@jumbo/shared/Div/Div';
import { CircularProgress } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';
import { previousmonthrecordapi } from 'backendServices/ApiCalls';



const VISIBLE_FIELDS = ['sr', 'sender', 'amount', 'date'];
const MonthlyReport = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true)
  const [preData, setPreData] = useState([])


  const preFunction = () => {
    setLoading(false)
    previousmonthrecordapi((response) => {
      setPreData(response?.data?.data)
      setLoading(false)
    }, (error) => {
      setLoading(false)
    })
  }

  function getPreviousMonthNameFromDate(dateString) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const date = new Date(dateString);

    // Subtract 1 from the month index to get the previous month
    const previousMonthIndex = (date.getMonth() - 1 + 12) % 12;
    return months[previousMonthIndex];
  }

  useEffect(() => {
    preFunction();
  }, [])


  if (loading) {
    return <Div
      sx={{
        display: 'flex',
        minWidth: 0,
        alignItems: 'center',
        alignContent: 'center',
        height: '100%',
      }}
    >
      <CircularProgress sx={{ m: '-40px auto 0' }} />
    </Div>
  }

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        }
      }
    }
  }




  const gridDesign = {
    '& .MuiDataGrid-toolbarContainer': {
      '& .MuiButton-text': {
        fontSize: '13px !important',
      },
      '& .MuiBadge-badge': {
        backgroundColor: '#074682',
      },
      '& .MuiInput-root': {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: 'hidden',
      },

    }
  }
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    // {
    //   field: "id",
    //   headerName: "ID",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 200,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    {
      field: "direct_active_left_members",
      headerName: "Direct Active Left Members",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "direct_active_right_members",
      headerName: "Direct Active Right Members",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "team_active_left_members",
      headerName: "Team Active Left Members",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "team_active_right_members",
      headerName: "Team Active RIght Members",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "left_referral_points",
      headerName: "Left Referral Points",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "right_referral_points",
      headerName: "Right Referral Points",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "left_binary_points",
      headerName: "Left Binary Points",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "right_binary_points",
      headerName: "RIght Binary Points",
      dataGeneratorUniquenessEnabled: true,
      width: 350,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "dat",
      headerName: "Month",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
      valueGetter: (params) => {
        // Assuming params.row.dat is in the format '2023-06-01 15:58:32'
        return getPreviousMonthNameFromDate(params.row.dat);
      },
    }
  ]



  return (
    <JumboDemoCard
      title={t('pages.title.previousmonthrecord')}
      wrapperSx={{ backgroundColor: 'background.paper', pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={preData}
          columns={columns}
          // slots={{ toolbar: GridToolbar }}
          slots={{
            toolbar: (props) => (
              <GridToolbar
                {...props}
                sx={{
                  backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#fff',
                  '& .MuiIconButton-root': {
                    color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                  },
                }}
              />
            ),
          }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  )
}

export default MonthlyReport
import React, { useEffect } from 'react'
import Cookies from 'js-cookie';
import Div from '@jumbo/shared/Div';
import { CircularProgress } from '@mui/material';

const Redirect = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const page = urlSearchParams.get('page');
    const uname = urlSearchParams.get('uname').toUpperCase();

    useEffect(() => {
        let domainLink = 'https://novalya.com/'
        if (page === 'plans') {
            domainLink = 'https://app.novalya.com/'
        }
        else if (page === 'go') {
            domainLink = 'https://www.novalya.ai/fr/'
        }
        else {
            domainLink = domainLink
        }
        Cookies.set('uname', uname);
        localStorage.setItem('referralId', uname)
        window.location.assign(domainLink + page)
    }, [])

    return <Div
        sx={{
            display: 'flex',
            minWidth: 0,
            alignItems: 'center',
            alignContent: 'center',
            height: '100%',
        }}
    >
        <CircularProgress sx={{ m: '-40px auto 0' }} />
    </Div>
}

export default Redirect
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>404 - Page Not Found</h1>
            <p style={styles.text}>Sorry, the page you are looking for does not exist.</p>
            <Link to="/" style={styles.link}>Go to Homepage</Link>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '50px'
    },
    header: {
        fontSize: '48px',
        color: '#333'
    },
    text: {
        fontSize: '18px',
        color: '#666'
    },
    link: {
        marginTop: '20px',
        display: 'inline-block',
        textDecoration: 'none',
        color: '#007bff'
    }
};

export default NotFound;

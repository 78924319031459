import React, { useState } from 'react';
import { FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CityBgCard from "app/shared/widgets/CityBgCard";
import "./design.css"
import Checkoutform from './Checkoutform';

const Submitpayment = () => {
    const { t } = useTranslation();
    const [planMode, setPlanMode] = useState(false)
    const handlePlanMode = () => {
        planMode === true ? setPlanMode(false) : setPlanMode(true)
    }
    let string_data = localStorage.getItem('registration_data')
    let registration_data = JSON.parse(string_data)
    return (
        <React.Fragment>
            <Grid container className='checkout'>
                {/* <Grid item xs={12} lg={12}>
                    <CityBgCard planMode={planMode} handlePlanMode={handlePlanMode} page='checkout' />
                </Grid> */}

                <Grid item xs={12} lg={12}>
                    <Checkoutform regData={registration_data} />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Submitpayment;

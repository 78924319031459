import { getStatisticData } from "backendServices/ApiCalls";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactHighcharts from "react-highcharts";
import { useTranslation } from "react-i18next";

export function AiCommentChart() {
  const [timeFrame, setTimeFrame] = useState("daily");
  const [fbComment, setFbComment] = useState([]);
  const [igComment, setIgComment] = useState([]);
  const { t } = useTranslation();

  function getFbAiComments() {
    let params = { type: "fb_ai" };
    getStatisticData(
      params,
      (response) => {
        if (response.data.statistics) {
          setFbComment(response.data.statistics);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  function getIgAiComments() {
    let params = { type: "ig_ai" };
    getStatisticData(
      params,
      (response) => {
        if (response.data.statistics) {
          setIgComment(response.data.statistics);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  // Function to group data based on the selected timeframe
  function groupData(data, timeFrame) {
    let groupedData = {};
    data.forEach((item) => {
      const date = moment(item.created_at);
      let key;

      if (timeFrame === "daily") {
        key = date.format("D MMM"); // Group by day, e.g., "1 Jan"
      } else if (timeFrame === "weekly") {
        key = `${date.startOf("week").format("D MMM")} - ${date.endOf("week").format("D MMM")}`; // Group by week, e.g., "1-7 Jan"
      } else if (timeFrame === "monthly") {
        key = date.format("MMM"); // Group by month, e.g., "Jan"
      }

      if (!groupedData[key]) {
        groupedData[key] = 0;
      }
      groupedData[key] += item.message_count;
    });

    // Convert grouped data to arrays
    const categories = Object.keys(groupedData);
    const values = Object.values(groupedData);

    return { categories, values };
  }

  // Process data based on the selected timeframe
  const fbProcessedData = groupData(fbComment, timeFrame);
  const igProcessedData = groupData(igComment, timeFrame);

  // Ensure categories are the same for both series
  const categories = [...new Set([...fbProcessedData.categories, ...igProcessedData.categories])].sort((a, b) => {
    // Sorting logic based on the selected timeframe
    if (timeFrame === "daily") {
      return moment(a, "D MMM").diff(moment(b, "D MMM"));
    } else if (timeFrame === "weekly") {
      return moment(a.split(" - ")[0], "D MMM").diff(moment(b.split(" - ")[0], "D MMM"));
    } else if (timeFrame === "monthly") {
      return moment(a, "MMM").diff(moment(b, "MMM"));
    }
    return 0;
  });

  // Align data with categories
  const fbData = categories.map(category => fbProcessedData.values[fbProcessedData.categories.indexOf(category)] || 0);
  const igData = categories.map(category => igProcessedData.values[igProcessedData.categories.indexOf(category)] || 0);

  const aiconfig = {
    chart: {
      type: "column",
    },
    title: {
      text: "Messages Sent",
    },
    subtitle: {
      text: "Select timeframe to view the messages sent",
    },
    xAxis: {
      categories: categories,
      labels: {
        rotation: -45,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Messages Sent",
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      shared: true,
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b><br/>',
    },
    series: [
      {
        name: "Facebook AI Comments",
        data: fbData,
        color: "#3b5998", // Facebook blue color
      },
      {
        name: "Instagram AI Comments",
        data: igData,
        color: "#E1306C", // Instagram pink color
      },
    ],
  };

  useEffect(() => {
    getFbAiComments();
    getIgAiComments();
  }, []);

  // Event handler for timeframe select box change
  const handleTimeFrameChange = (e) => {
    setTimeFrame(e.target.value);
  };

  return (
    <div className="sp-stats-outer">
      <span className="sp-title"> {t("pages.title.AI Comments")}</span>
      <div className="sp-stats-main">
        <div className="sp-stats-selectWrap">
          <select onChange={handleTimeFrameChange} value={timeFrame}>
          <option value="daily">{t("pages.title.Daily")}</option>
            <option value="weekly">{t("pages.title.Weekly")}</option>
            <option value="monthly">{t("pages.title.Monthly")}</option>
          </select>
        </div>
        <ReactHighcharts config={aiconfig} />
      </div>
    </div>
  );
}

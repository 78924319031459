export function GetStarted({ folderData }) {
  return (
    <div className="col-2-1">
      <div>
        <div className="content-title-box">
          <span className="title-18"> Folder</span>
        </div>

        <div
          className="group-wraper"
          style={{ transition: "box-shadow 100ms linear" }}
        >
          {folderData?.map((item, index) => (
            <div
              className="item"
              key={index}
              style={{
                cursor: "grabbing",
              }}
            >
              <label
                className="group-items"
                htmlFor={`group-check-index-${index}`}
              >
                <input
                  type="radio"
                  id={`group-check-index-${index}`}
                  name="radio-group"
                />
                <div className="group-wraper-inner">
                  <span className="group-name">{item.name}</span>
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Divider from "@mui/material/Divider";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useTranslation } from "react-i18next";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EuroIcon from '@mui/icons-material/Euro';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';


const CheckOutPlansDetails = (props) => {
    const { t } = useTranslation();

    return (
        <JumboCardQuick title={t('widgets.title.ourOffice')} noWrapper>
            <List disablePadding>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(1.25, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <DriveFileRenameOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Name: ' + props.singlePlan.external_name} />
                </ListItem>
                <Divider component="li" />
                <ListItem sx={{ p: theme => theme.spacing(1.25, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <EuroIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Amount: €' + (props.singlePlan.price / 100).toFixed(2).replace('.', ',')} />
                </ListItem>
                <Divider component="li" />
                <ListItem sx={{ p: theme => theme.spacing(1.25, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <AvTimerIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Subscripion Period: ' + props.singlePlan.period_unit} />
                </ListItem>
                <Divider component="li" />
                <ListItem sx={{ p: theme => theme.spacing(1.25, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <PersonOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Username: ' + props.username} />
                </ListItem>
                <Divider component="li" />
                <ListItem sx={{ p: theme => theme.spacing(1.25, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.primary' }}>
                        <MailOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Email: ' + props.email} />
                </ListItem>
            </List>
        </JumboCardQuick>
    );
};

export default CheckOutPlansDetails;

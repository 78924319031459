import React, { useContext, useState } from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Fab from "@mui/material/Fab";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useRef } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, CircularProgress } from "@mui/material";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { UpdateReferralSide } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";

const CopyLink = () => {
  const { t } = useTranslation();
  const [copyMessage, setCopyMessage] = useState("");
  const [copyMessage1, setCopyMessage1] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [selectedReferralSide, setSelectedReferralSide] = useState(
    loginUserData?.referral_side
  );
  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });
  const textRef = useRef(null);
  const textRef1 = useRef(null);

  const handleCopyClick = () => {
    const textToCopy = textRef.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage("Link copied");
    setTimeout(() => setCopyMessage(""), 3000);
  };
  const handleCopyClick1 = () => {
    const textToCopy = textRef1.current.innerText;
    navigator.clipboard.writeText(textToCopy);
    setCopyMessage1("Link copied");
    setTimeout(() => setCopyMessage1(""), 3000);
  };
  const handleReferralSideChange = (event) => {
    setIsLoading(true);
    const newReferralSide = event.target.value;
    setSelectedReferralSide(newReferralSide);

    let param = {
      referral_side: newReferralSide,
    };
    UpdateReferralSide(
      param,
      (response) => {
        setloginUserData((prevState) => ({
          ...prevState,
          referral_side: newReferralSide,
        }));
        if (response?.data?.status === "error") {
          setIsLoading(false);
          setalertData({
            show: true,
            message: "Something went wrong. Please try again later.",
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setIsLoading(false);
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
        }
      },
      (error) => {}
    );
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // Set the container to full viewport height
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 16px" }} />
        <h4>We are validating your request</h4>
      </Box>
    );
  }

  return (
    <Card sx={{ textAlign: "center" }}>
      <CardContent
        sx={{
          backgroundImage: "linear-gradient(#38B8F2, #843CF6)",
          backgroundRepeat: "repeat-x",
          py: 6,
        }}
      >
        {alertData.show && (
          <SweetAlert alertData={alertData} setalertData={setalertData} />
        )}
        <Typography
          variant={"h1"}
          fontWeight={"300"}
          sx={{ fontSize: "2rem", color: "common.white", mb: 0 }}
        >
          {t("pages.title.referralLinks")}
        </Typography>
      </CardContent>
      <CardContent sx={{ position: "relative" }}>
        <Typography variant="h5" sx={{ marginTop: 3, marginBottom: -2 }}>
          {t("pages.title.tunneldevente")}
        </Typography>
        <Fab
          aria-label="volume"
          color="secondary"
          sx={{
            position: "absolute",
            left: "50%",
            top: "0",
            height: 60,
            width: 60,
            transform: "translate(-50%, -50%)",
          }}
        >
          <FilterAltIcon fontSize="medium" />
        </Fab>
        <Button
          sx={{ marginTop: 3 }}
          size="medium"
          variant={"contained"}
          color={"inherit"}
          disableElevation
          onClick={handleCopyClick}
        >
          {" "}
          <box my={2.25} ref={textRef}>
            <span style={{ textTransform: "lowercase" }}>
              https://app.novalya.com/redirect?page=demo&uname=
            </span>
            {loginUserData?.randomcode}
          </box>{" "}
          <ContentCopyIcon />{" "}
        </Button>
        {/* <Button sx={{ marginTop: 3 }} size="medium" variant={"contained"} color={"inherit"} disableElevation onClick={handleCopyClick}> <box style={{ textTransform: 'lowercase' }} my={2.25} ref={textRef}>{'https://app.novalya.com/redirect?page=demo&uname='+loginUserData?.randomcode} </box> <ContentCopyIcon /> </Button> */}

        {copyMessage && <div style={{ color: "green" }}>{copyMessage}</div>}

        {/* <Typography variant='h5' sx={{ marginTop: 3, marginBottom: -2 }}>{t('pages.title.novaMomentum')}</Typography>

          
        <Fab aria-label="volume" color="secondary"
          sx={{
            position: 'absolute',
            left: '50%',
            top: '0',
            height: 60,
            width: 60,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <FilterAltIcon fontSize="medium" />
        </Fab>
        <Button sx={{ marginTop: 3 }} size="medium" variant={"contained"} color={"inherit"} disableElevation onClick={handleCopyClick1}> <box my={2.25} ref={textRef1}><span style={{ textTransform: 'lowercase' }}>https://app.novalya.com/redirect?page=demolive&uname=</span>{loginUserData?.randomcode}</box> <ContentCopyIcon /> </Button>

        {copyMessage1 && (<div style={{ color: "green" }}>{copyMessage1}</div>)} */}

        {/* <FormControl  sx={{marginTop: 4.4}}>
          <box>{t('pages.title.yourreferralside')}</box>
          <Stack direction="row" spacing={2}>
              <FormLabel sx={{marginTop:"10px"}} id="demo-row-radio-buttons-group-label">{t('pages.title.referralside')}</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              onChange={handleReferralSideChange}
              >
                <FormControlLabel value="L" control={<Radio />} label="Left" checked={loginUserData?.referral_side === 'L'}/>
                <FormControlLabel value="R" control={<Radio />} label="Right"  checked={loginUserData?.referral_side === 'R'}/>
              </RadioGroup>
          </Stack>
        </FormControl> */}
      </CardContent>
    </Card>
  );
};

export default CopyLink;

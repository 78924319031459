import React, { useContext, useEffect, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import {
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
// import LocalizationOptions from 'app/shared/JumboCustomizer/components/LocalizationOptions'
import AffiliateLinkUpdateModal from "./common/AffiliateLinkUpdateModal";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SweetAlert from "../components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { useNavigate } from "react-router-dom";

export default function AffiliateLink() {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData } = useContext(CustomProvider);
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  const [alertData, setalertData] = useState({
    show: false,
    message: "",
    variant: "",
  });

  const affiliatedLinks = {
    blackFriday: `https://app.novalya.com/offers?uname=${loginUserData?.randomcode}`,
    happyHalloween: `https://app.novalya.com/offers?uname=${loginUserData?.randomcode}}`,
    officialWebsite: `https://app.novalya.com/signup/${loginUserData?.randomcode}`,
    salesFunnel: `https://www.novalya.ai/fr/go?uname=${loginUserData?.randomcode}&lang=fr`,
    salesFunnelEn: `https://www.novalya.ai/en/go?uname=${loginUserData?.randomcode}&lang=en`,
    salesFunnelDe: `https://www.novalya.ai/de/go?uname=${loginUserData?.randomcode}&lang=de`,
    salesFunnelEs: `https://www.novalya.ai/es/go?uname=${loginUserData?.randomcode}&lang=es`,
    webinaireGratuit: `https://www.novalya.ai/challenge?uname=${loginUserData?.randomcode}`,
    pricingPage: `https://app.novalya.com/redirect?page=plans&uname=${loginUserData?.randomcode}`,
  };
  const navigate = useNavigate();
  if (loginUserData?.user_type === 'Normal') {
    navigate("/affiliate");
  }
  const updateLoginInfo = (newRandomCode) => {
    setloginUserData((prevState) => ({
      ...prevState,
      randomcode: newRandomCode,
    }));
  };
  const copyLinkInClipBoard = (type) => {
    console.log('type', type);
    navigator.clipboard.writeText(affiliatedLinks[type]);
    setalertData({
      show: true,
      message: "Link copied successfully",
      variant: "success",
    });
  };


  const blackFriday = `https://app.novalya.com/offers?uname=${loginUserData?.randomcode}`;
  const happyHalloween = `https://app.novalya.com/offers/${loginUserData?.randomcode}`;
  const officialWebsite = `https://app.novalya.com/signup/${loginUserData?.randomcode}`;
  const salesFunnel = `https://www.novalya.ai/fr/go?uname=${loginUserData?.randomcode}&lang=fr`;
  const salesFunnelEn = `https://www.novalya.ai/en/go?uname=${loginUserData?.randomcode}&lang=en`;
  const salesFunnelDe = `https://www.novalya.ai/de/go?uname=${loginUserData?.randomcode}&lang=de`;
  const salesFunnelEs = `https://www.novalya.ai/es/go?uname=${loginUserData?.randomcode}&lang=es`;
  // const webinaireGratuit = `https://www.novalya.ai/challenge?uname=${loginUserData?.randomcode}`;
  const pricingPage = `https://app.novalya.com/redirect?page=plans&uname=${loginUserData?.randomcode}`;

  const getMailtoLink = (link) => `mailto:?body=${encodeURIComponent(link)}`;
  const getMessengerLink = (link) => `https://www.messenger.com/t/?message=${encodeURIComponent(link)}`;
  const getWhatsAppLink = (link) => `https://wa.me/?text=${encodeURIComponent(link)}`;
  const getTelegramLink = (link) => `https://t.me/share/url?url=${encodeURIComponent(link)}`;

  const getSalesFunnelLink = () => {
    switch (selectedLanguage) {
      case "French":
        return { link: salesFunnel, type: "salesFunnel" };
      case "German":
        return { link: salesFunnelDe, type: "salesFunnelDe" };
      case "Spanish":
        return { link: salesFunnelEs, type: "salesFunnelEs" };
      default:
        return { link: salesFunnelEn, type: "salesFunnelEn" };
    }
  };

  useEffect(() => {
    const selectedLocale = localStorage.getItem('selectedLocale');
    switch (selectedLocale) {
      case 'fr-FR':
        setSelectedLanguage('French');
        break;
      case 'es-ES':
        setSelectedLanguage('German');
        break;
      case 'sr-RS':
        setSelectedLanguage('English');
        break;
      default:
        setSelectedLanguage('English');
    }
  }, []);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  return (
    <AffiliateLayout>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <Card className="sep-affiliate-shadowMain">
        <div
          className="sep-affiliate-link-header">
          <span className="sep-affiliate-link-title">
            {t("pages.title.my-affiliate-code-is")} : <strong>{loginUserData?.randomcode}</strong>
          </span>
          <AffiliateLinkUpdateModal
            data={loginUserData}
            updateLoginInfo={updateLoginInfo}
          />
        </div>
        <Grid container>
          <Grid item xs={12} >
            <div className="sep-affilaite-land">
              <div className="sep-affilaite-landInner">
                <Typography variant="body2">
                  {t("pages.title.SelectLanguageAffiliate")}
                </Typography>
                <select
                  name=""
                  id=""
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  style={{ width: "20%" }}
                >
                  <option value="English">
                    {t("pages.title.us-english")}
                  </option>
                  <option value="German">
                    {t("pages.title.de-german")}
                  </option>
                  <option value="French">
                    {t("pages.title.fr-french")}
                  </option>
                  <option value="Spanish">
                    {t("pages.title.es-spanish")}
                  </option>
                </select>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* <div style={{ display: "flex", padding: "10px 20px" }}>
                    <p style={{ lineHeight: "35px", fontSize: "18px", fontWeight: 500, fontFamily: "'DM Sans'", color: "#170F49" }}>Select Language of Your Affiliate Links :</p>
                    <div style={{ width: "244px" }}>
                        <LocalizationOptions />
                    </div>
                </div> */}
        <div className="sep-alliliate-gridcol">
          {/* <div className="sep-affiliateGrid-items">
            <div className="sep-affiliatelink-img"
              style={{
                backgroundImage: `url(${ASSET_IMAGES}/black-friday.png)`,
              }}
            >
              &nbsp;
            </div>
            <div className="sep-affiliatelink-content">
              <div className="sep-affiliatelink-hdg">
                {t("pages.title.black-friday-sale")}
              </div>
              <div className="sep-affiliatelink-input">
                <FormControl
                  sx={{ m: 1, width: "82%", marginLeft: 0 }}
                  variant=""
                >
                  <OutlinedInput
                    id="affiliate-link-1"
                    type="text"
                    value={blackFriday}
                    readOnly
                    sx={{ padding: 0 }}
                    startAdornment={
                      <InputAdornment
                        position="center"
                        sx={{
                          background: "#DADADA",
                          maxHeight: "38px",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        <IconButton>
                          <img src="/images/globe-sync.svg" alt="globe-sync" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Button
                  variant="contained"
                  onClick={() => copyLinkInClipBoard("blackFriday")}
                  color="success"
                >
                  {t("pages.title.copy-link")}
                </Button>
              </div>
              <div className="sep-affiliateLink-foot">
                {t("pages.title.share-the-link-on")} :{" "}
                <a href={getMailtoLink(blackFriday)}>
                  Email
                </a>{" "}
                |{" "}
                <a href={getMessengerLink(blackFriday)} target="_blank" rel="noopener noreferrer">
                  Messenger
                </a>{" "}
                |{" "}
                <a href={getWhatsAppLink(blackFriday)} target="_blank" rel="noopener noreferrer">
                  WhatsApp
                </a>{" "}
                |{" "}
                <a href={getTelegramLink(blackFriday)} target="_blank" rel="noopener noreferrer">
                  Telegram
                </a>
              </div>
            </div>
          </div> */}

          <div className="sep-affiliateGrid-items">
            <div className="sep-affiliatelink-img"
              style={{
                backgroundImage: `url(${ASSET_IMAGES}/official-website.png)`,
              }}
            >
              &nbsp;
            </div>
            <div className="sep-affiliatelink-content">
              <div className="sep-affiliatelink-hdg">
                {t("pages.title.OfficialWebsite")}
              </div>
              <div className="sep-affiliatelink-input">
                <FormControl
                  sx={{ m: 1, width: "82%", marginLeft: 0 }}
                  variant=""
                >
                  <OutlinedInput
                    id="affiliate-link-1"
                    type="text"
                    value={officialWebsite}
                    readOnly
                    sx={{ padding: 0 }}
                    startAdornment={
                      <InputAdornment
                        position="center"
                        sx={{
                          background: "#DADADA",
                          maxHeight: "38px",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        <IconButton>
                          <img src="/images/globe-sync.svg" alt="globe-sync" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Button
                  variant="contained"
                  onClick={() => copyLinkInClipBoard("officialWebsite")}
                  color="success"
                >
                  {t("pages.title.copy-link")}
                </Button>
              </div>
              <div className="sep-affiliateLink-foot">
                {t("pages.title.share-the-link-on")} :{" "}
                <a href={getMailtoLink(officialWebsite)}>
                  Email
                </a>{" "}
                |{" "}
                <a href={getMessengerLink(officialWebsite)} target="_blank" rel="noopener noreferrer">
                  Messenger
                </a>{" "}
                |{" "}
                <a href={getWhatsAppLink(officialWebsite)} target="_blank" rel="noopener noreferrer">
                  WhatsApp
                </a>{" "}
                |{" "}
                <a href={getTelegramLink(officialWebsite)} target="_blank" rel="noopener noreferrer">
                  Telegram
                </a>
              </div>
            </div>
          </div>
          {selectedLanguage === "French" && loginUserData?.user_type === "Distributor" ? (
            <div className="sep-affiliateGrid-items">
              <div className="sep-affiliatelink-img"
                style={{
                  backgroundImage: `url(${ASSET_IMAGES}/Sales-Funnel.jpg)`,
                }}
              >
                &nbsp;
              </div>
              <div className="sep-affiliatelink-content">
                <div className="sep-affiliatelink-hdg">
                  {t("pages.title.SalesFunnel")}
                </div>
                <div className="sep-affiliatelink-input">
                  <FormControl
                    sx={{ m: 1, width: "82%", marginLeft: 0 }}
                    variant=""
                  >
                    <OutlinedInput
                      id="affiliate-link-1"
                      type="text"
                      value={getSalesFunnelLink().link}
                      readOnly
                      sx={{ padding: 0 }}
                      startAdornment={
                        <InputAdornment
                          position="center"
                          sx={{
                            background: "#DADADA",
                            maxHeight: "38px",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        >
                          <IconButton>
                            <img src="/images/globe-sync.svg" alt="globe-sync" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={() => copyLinkInClipBoard(getSalesFunnelLink().type)}
                    color="success"
                  >
                    {t("pages.title.copy-link")}
                  </Button>
                </div>
                <div className="sep-affiliateLink-foot">
                  Share the link on :{" "}
                  <a href={getMailtoLink(getSalesFunnelLink())}>
                    Email
                  </a>{" "}
                  |{" "}
                  <a href={getMessengerLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Messenger
                  </a>{" "}
                  |{" "}
                  <a href={getWhatsAppLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    WhatsApp
                  </a>{" "}
                  |{" "}
                  <a href={getTelegramLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Telegram
                  </a>
                </div>
              </div>
            </div>
          ) : selectedLanguage === "English" || loginUserData?.user_type === "Distributor" ? (
            <div className="sep-affiliateGrid-items">
              <div className="sep-affiliatelink-img"
              style={{
                  backgroundImage: `url(${ASSET_IMAGES}/Sales-Funnel.jpg)`,
                }}
              >
                &nbsp;
              </div>
              <div className="sep-affiliatelink-content">
                <div className="sep-affiliatelink-hdg">
                  {t("pages.title.SalesFunnel")}
                </div>
                <div className="sep-affiliatelink-input">
                  <FormControl
                    sx={{ m: 1, width: "82%", marginLeft: 0 }}
                    variant=""
                  >
                    <OutlinedInput
                      id="affiliate-link-1"
                      type="text"
                      value={getSalesFunnelLink().link}
                      readOnly
                      sx={{ padding: 0 }}
                      startAdornment={
                        <InputAdornment
                          position="center"
                          sx={{
                            background: "#DADADA",
                            maxHeight: "38px",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        >
                          <IconButton>
                            <img src="/images/globe-sync.svg" alt="globe-sync" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={() => copyLinkInClipBoard(getSalesFunnelLink().type)}
                    color="success"
                  >
                    {t("pages.title.copy-link")}
                  </Button>
                </div>
                <div className="sep-affiliateLink-foot">
                  Share the link on :{" "}
                  <a href={getMailtoLink(getSalesFunnelLink())}>
                    Email
                  </a>{" "}
                  |{" "}
                  <a href={getMessengerLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Messenger
                  </a>{" "}
                  |{" "}
                  <a href={getWhatsAppLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    WhatsApp
                  </a>{" "}
                  |{" "}
                  <a href={getTelegramLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Telegram
                  </a>
                </div>
              </div>
            </div>
          ) : selectedLanguage === "German" && loginUserData?.user_type === "Distributor" ? (
            <div className="sep-affiliateGrid-items">
              <div className="sep-affiliatelink-img"
                style={{
                  backgroundImage: `url(${ASSET_IMAGES}/Sales-Funnel.jpg)`,
                }}
              >
                &nbsp;
              </div>
              <div className="sep-affiliatelink-content">
                <div className="sep-affiliatelink-hdg">
                  {"Verkaufstrichter"}
                </div>
                <div className="sep-affiliatelink-input">
                  <FormControl
                    sx={{ m: 1, width: "82%", marginLeft: 0 }}
                    variant=""
                  >
                    <OutlinedInput
                      id="affiliate-link-1"
                      type="text"
                      value={getSalesFunnelLink().link}
                      readOnly
                      sx={{ padding: 0 }}
                      startAdornment={
                        <InputAdornment
                          position="center"
                          sx={{
                            background: "#DADADA",
                            maxHeight: "38px",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        >
                          <IconButton>
                            <img src="/images/globe-sync.svg" alt="globe-sync" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={() => copyLinkInClipBoard(getSalesFunnelLink().type)}
                    color="success"
                  >
                    {t("pages.title.copy-link")}
                  </Button>
                </div>
                <div className="sep-affiliateLink-foot">
                  Share the link on :{" "}
                  <a href={getMailtoLink(getSalesFunnelLink())}>
                    Email
                  </a>{" "}
                  |{" "}
                  <a href={getMessengerLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Messenger
                  </a>{" "}
                  |{" "}
                  <a href={getWhatsAppLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    WhatsApp
                  </a>{" "}
                  |{" "}
                  <a href={getTelegramLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Telegram
                  </a>
                </div>
              </div>
            </div>
          ) : selectedLanguage === "Spanish" && loginUserData?.user_type === "Distributor" ? (
            <div className="sep-affiliateGrid-items">
              <div className="sep-affiliatelink-img"
                style={{
                  backgroundImage: `url(${ASSET_IMAGES}/Sales-Funnel.jpg)`,
                }}
              >
                &nbsp;
              </div>
              <div className="sep-affiliatelink-content">
                <div className="sep-affiliatelink-hdg">
                  {"Embudo de ventas"}
                </div>
                <div className="sep-affiliatelink-input">
                  <FormControl
                    sx={{ m: 1, width: "82%", marginLeft: 0 }}
                    variant=""
                  >
                    <OutlinedInput
                      id="affiliate-link-1"
                      type="text"
                      value={getSalesFunnelLink().link}
                      readOnly
                      sx={{ padding: 0 }}
                      startAdornment={
                        <InputAdornment
                          position="center"
                          sx={{
                            background: "#DADADA",
                            maxHeight: "38px",
                            height: "100%",
                            borderRadius: "10px",
                          }}
                        >
                          <IconButton>
                            <img src="/images/globe-sync.svg" alt="globe-sync" />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Button
                    variant="contained"
                    onClick={() => copyLinkInClipBoard(getSalesFunnelLink().type)}
                    color="success"
                  >
                    {t("pages.title.copy-link")}
                  </Button>
                </div>
                <div  className="sep-affiliateLink-foot">
                  Share the link on :{" "}
                  <a href={getMailtoLink(getSalesFunnelLink())}>
                    Email
                  </a>{" "}
                  |{" "}
                  <a href={getMessengerLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Messenger
                  </a>{" "}
                  |{" "}
                  <a href={getWhatsAppLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    WhatsApp
                  </a>{" "}
                  |{" "}
                  <a href={getTelegramLink(getSalesFunnelLink())} target="_blank" rel="noopener noreferrer">
                    Telegram
                  </a>
                </div>
              </div>
            </div>
          ) : null}

          <div className="sep-affiliateGrid-items">
            <div className="sep-affiliatelink-img"
              style={{
                backgroundImage: `url(${ASSET_IMAGES}/plans.png)`,
              }}
            >
              &nbsp;
            </div>
            <div className="sep-affiliatelink-content">
              <div className="sep-affiliatelink-hdg">
                {t("pages.title.link3text")}
              </div>
              <div className="sep-affiliatelink-input">
                <FormControl
                  sx={{ m: 1, width: "82%", marginLeft: 0 }}
                  variant=""
                >
                  <OutlinedInput
                    id="affiliate-link-1"
                    type="text"
                    value={pricingPage}
                    readOnly
                    sx={{ padding: 0 }}
                    startAdornment={
                      <InputAdornment
                        position="center"
                        sx={{
                          background: "#DADADA",
                          maxHeight: "38px",
                          height: "100%",
                          borderRadius: "10px",
                        }}
                      >
                        <IconButton>
                          <img src="/images/globe-sync.svg" alt="globe-sync" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Button
                  variant="contained"
                  onClick={() => copyLinkInClipBoard("pricingPage")}
                  color="success"
                >
                  {t("pages.title.copy-link")}
                </Button>
              </div>
              <div className="sep-affiliateLink-foot">
                Share the link on :{" "}
                <a href={getMailtoLink(pricingPage)}>
                  Email
                </a>{" "}
                |{" "}
                <a href={getMessengerLink(pricingPage)} target="_blank" rel="noopener noreferrer">
                  Messenger
                </a>{" "}
                |{" "}
                <a href={getWhatsAppLink(pricingPage)} target="_blank" rel="noopener noreferrer">
                  WhatsApp
                </a>{" "}
                |{" "}
                <a href={getTelegramLink(pricingPage)} target="_blank" rel="noopener noreferrer">
                  Telegram
                </a>
              </div>
            </div>
          </div>

          {/* <div style={{ display: "grid", gridTemplateColumns: "2fr 10fr", gap: "25px", margin: "15px 0px", border: "1px solid #D8D8D8", borderRadius: "10px" }}>
                        <div style={{ padding: "8px", backgroundColor: "#FFF", backgroundImage: "url(/images/affiliate-link-image-3.svg)", borderRight: "1px solid #D8D8D8", backgroundBlendMode: "multiply", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat", borderRadius: "10px 0px 0px 10px" }}>&nbsp;</div>
                        <div style={{ padding: "8px" }}>
                            <div style={{ lineHeight: "35px", fontSize: "16px", fontWeight: "500", fontFamily: "'DM Sans", color: "#170F49" }}>Copy and share your link</div>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <FormControl sx={{ m: 1, width: '82%', marginLeft: 0 }} variant="">
                                    <OutlinedInput
                                        id="affiliate-link-1"
                                        type='text'
                                        value={affiliatedLinks.webinaireGratuit}
                                        readOnly
                                        sx={{ padding: 0 }}
                                        startAdornment={
                                            <InputAdornment position="center" sx={{ background: "#DADADA", maxHeight: "38px", height: "100%", borderRadius: "10px" }}>
                                                <IconButton>
                                                    <img src="/images/globe-sync.svg" alt="globe-sync" />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <Button variant="contained" onClick={() => copyLinkInClipBoard('webinaireGratuit')} color="success"> {t("pages.title.copy-link")}</Button>
                            </div>
                            <div style={{ lineHeight: "35px", fontSize: "14px", fontWeight: "500", fontFamily: "'DM Sans", color: "#585858" }}>Share the link on :   <a href="#" style={{ color: "#5E3BB7" }}>Email</a> | <a href="#" style={{ color: "#5E3BB7" }}>Messenger</a> | <a href="#" style={{ color: "#5E3BB7" }}>WhatsApp</a> | <a href="#" style={{ color: "#5E3BB7" }}>Telegram</a></div>
                        </div>
                    </div> */}
        </div>
      </Card>
    </AffiliateLayout>
  );
}
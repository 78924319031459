import React, { useState, useContext } from "react";
import Div from "@jumbo/shared/Div";
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styled from "styled-components";
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import Modals from "./Modals";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import Spinner from "app/shared/Spinner/Spinner";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const Item = styled(Paper)(({ theme }) => ({}));

const Steps = () => {
  const { t } = useTranslation();
  const { loginUserData, setloginUserData, loading } =
    useContext(CustomProvider);
  const [open, setOpen] = React.useState(false);
  const [affiliateagreementopen, setAffiliateAgreementOpen] =
    React.useState(false);
  const [becomeaffiliateopen, setBecomeAffiliateOpen] = useState(false);
  const [updatepayoutinformationopen, setUpdatePayoutInformationOpen] =
    useState(false);
  const [currentPayoutDetails, setCurrentPayoutDetails] = useState(false);
  const [loader, setLoader] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const Swal = useSwalWrapper();
  const sweetAlerts = (icone, title, text) => {
    Swal.fire({
      icon: icone,
      title: title,
      text: text,
    });
  };

  const checkAffiliate = () => {
    setLoader(true);
    let check_aff = localStorage.getItem("affiliate_status");
    localStorage.removeItem("affiliate_status");
    if (check_aff === "yes") {
      setloginUserData((prevState) => ({
        ...prevState,
        user_type: "Distributor",
      }));
      Swal.fire({
        icon: "success",
        title: "Payment Success",
        text: "You have become an affiiate successfully.",
      });
    }
    setLoader(false);
  };

  useEffect(() => {
    checkAffiliate();
  }, []);

  useEffect(() => {
    if (loginUserData?.user_type === "Distributor") {
      setActiveStep(1);
    }
    if (loginUserData?.kyc_status === "Verified") {
      setActiveStep(2);
    }
  }, [loginUserData]);

  const handleAgreement = () => {
    window.open(
      "https://novalya.com/wp-content/uploads/2024/02/Contrat-Affiliation-Novalya-01-Fev-2024-.pdf",
      "_blank"
    );
  };

  const handleLink = () => {
    const url = "https://novalya.com/affiliate-compensation/";
    window.open(url, "_blank");
  };

  if (loading || loader) {
    return <Spinner />;
  }
  return (
    <>
      <JumboDemoCard
        title={t("pages.title.verifyyourKYC")}
        wrapperSx={{
          backgroundColor: "background.paper",
          pt: 0,
          justifyContent: "left",
          marginTop: 3,
        }}
      >
        <Div sx={{ maxWidth: 400 }}>
          <Modals
            open={open}
            setOpen={setOpen}
            affiliateagreementopen={affiliateagreementopen}
            setAffiliateAgreementOpen={setAffiliateAgreementOpen}
            becomeaffiliateopen={becomeaffiliateopen}
            setBecomeAffiliateOpen={setBecomeAffiliateOpen}
            updatepayoutinformationopen={updatepayoutinformationopen}
            setUpdatePayoutInformationOpen={setUpdatePayoutInformationOpen}
            currentPayoutDetails={currentPayoutDetails}
            setCurrentPayoutDetails={setCurrentPayoutDetails}
          />
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>{t("pages.title.becomeaffiliate")}</StepLabel>
              <StepContent>
                <Typography>
                  <Stack direction="row" spacing={2}>
                    <Item>
                      {loginUserData?.user_type === "Normal" ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => setOpen(true)}
                        >
                          {t("pages.title.becomeaffiliate")}
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          disabled
                        >
                          {t("pages.title.youareaffiliate")}
                        </Button>
                      )}
                    </Item>
                    <Item>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => handleAgreement()}
                      >
                        {t("pages.title.affiliataggrement")}
                      </Button>
                    </Item>
                  </Stack>
                </Typography>

                <Div sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={
                        loginUserData?.user_type === "Distributor"
                          ? false
                          : true
                      }
                    >
                      {t("pages.title.continue")}
                    </Button>
                    <Button
                      disabled={true}
                      color="warning"
                      size="small"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t("pages.title.back")}
                    </Button>
                  </div>
                </Div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>{t("pages.title.kycdocuments")}</StepLabel>
              <StepContent>
                <Typography>
                  {loginUserData?.kyc_status === "Unverified" ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => setBecomeAffiliateOpen(true)}
                    >
                      {t("pages.title.kyc")}
                    </Button>
                  ) : loginUserData?.kyc_status === "Uploaded" ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        sweetAlerts(
                          "info",
                          "info",
                          "You have already uploaded your documents. It will be verify soon. Thank You!"
                        )
                      }
                    >
                      {t("pages.title.kyc")}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      disabled={true}
                    >
                      {t("pages.title.youverified")}
                    </Button>
                  )}
                </Typography>
                <Div sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                      disabled={
                        loginUserData?.kyc_status === "Verified" ? false : true
                      }
                    >
                      {t("pages.title.continue")}
                    </Button>
                    <Button
                      disabled={false}
                      color="warning"
                      size="small"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t("pages.title.back")}
                    </Button>
                  </div>
                </Div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel
                optional={
                  <Typography variant="caption">
                    {t("pages.title.laststep")}
                  </Typography>
                }
              >
                {t("pages.title.payoutinformation")}
              </StepLabel>
              <StepContent>
                <Typography>
                  {loginUserData?.bank_account_title === null &&
                  loginUserData?.wallet_address === null &&
                  loginUserData?.outside_bank_account_title === null &&
                  loginUserData?.porequestcount === 0 ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => setUpdatePayoutInformationOpen(true)}
                    >
                      {t("pages.title.updatepayoutinformation")}
                    </Button>
                  ) : loginUserData?.porequestcount > 0 ? (

                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() =>
                        sweetAlerts(
                          "info",
                          "info",
                          "Your request already submitted to admin, it will be verify soon."
                        )
                      }
                    >
                      {t("pages.title.updatepayoutinformation")}
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setCurrentPayoutDetails(true);
                      }}
                    >
                      {t("pages.title.updatepayoutinformation")}
                    </Button>
                  )}
                </Typography>
                <Div sx={{ mb: 2 }}>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={handleNext}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t("pages.title.finish")}
                    </Button>
                    <Button
                      disabled={false}
                      color="warning"
                      size="small"
                      onClick={handleBack}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      {t("pages.title.back")}
                    </Button>
                  </div>
                </Div>
              </StepContent>
            </Step>
          </Stepper>
        </Div>
      </JumboDemoCard>
      <Button
        sx={{ width: "50%", marginTop: 1 }}
        size="small"
        onClick={() => handleLink()}
        variant="contained"
      >
        {t("pages.title.link")}
      </Button>
    </>
  );
};

export default Steps;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import JumboLayout from "@jumbo/components/JumboLayout";
import Header from "../shared/headers/Header";
import Sidebar from "../shared/sidebars/Sidebar";
import Footer from "../shared/footers/Footer";
import useJumboLayout from "@jumbo/hooks/useJumboLayout";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import useJumboFooterTheme from "@jumbo/hooks/useJumboFooterTheme";
import { AppContext } from "app/AppContext";
import { authUserData, getreferralUsers } from "backendServices/ApiCalls";
import layoutConfig from "./layoutConfig";
import { mainTheme as mainThemeDark } from "../../themes/main/dark";
import { headerTheme as headerThemeDark } from "../../themes/header/dark";
import { footerTheme as footerThemeDark } from "../../themes/footer/dark";
import { sidebarTheme as sidebarThemeDark } from "../../themes/sidebar/dark";
import { mainTheme as mainThemeDefault } from "../../themes/main/default";
import { headerTheme as headerThemeDefault } from "../../themes/header/default";
import { footerTheme as footerThemeDefault } from "../../themes/footer/default";
import { sidebarTheme as sidebarThemeDefault } from "../../themes/sidebar/default";

const CustomProvider = createContext();

const VerticalDefault = ({ children }) => {
  const { setJumboLayoutOptions } = useJumboLayout();
  const { sidebarOptions } = useJumboLayoutSidebar();
  const { setTheme } = useJumboTheme();
  const { setSidebarTheme } = useJumboSidebarTheme();
  const { setHeaderTheme } = useJumboHeaderTheme();
  const { setFooterTheme } = useJumboFooterTheme();
  const { checkLogin } = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [loginUserData, setloginUserData] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [isLimit, setIsLimit] = useState(false);
  const [affData, setAffData] = useState(null);
  const [refUsers, setRefUsers] = useState([]);
  const [upgradePop, setUpgradePop] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    const getUserData = () => {
      setLoading(true);

      authUserData(
        (response) => {
          if (!isMounted) return;

          setloginUserData(response?.data?.data);
          const currentUrl = location.pathname + location.search + location.hash;
          const userType = response?.data?.data?.user_type;
          const loginStatus = response?.data?.data?.login_status;
          const subscriptionStatus = response?.data?.data?.subscription_status;

          if (
            loginStatus === "Block" ||
            subscriptionStatus === "payment_failed" ||
            subscriptionStatus === "subscription_cancelled" ||
            subscriptionStatus === "payment_refunded"
          ) {
            navigate("/logout");
          }

          if (userType === "Normal") {
            if (
              [
                "/referrals",
                "/previous-month-record",
                "/pending-residuel-report",
                "/binary-points-report",
                "/payout-summary",
                "/binary-tree",
              ].includes(currentUrl)
            ) {
              navigate("/normal-users");
            }
          }

          setLoading(false);
        },
        (error) => {
          if (!isMounted) return;
          setLoading(false);
        },
        { signal: abortController.signal }
      );
    };

    getUserData();
    setJumboLayoutOptions(layoutConfig);

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [location.pathname]);

  useEffect(() => {
    const themeMode = Cookies.get("mode");

    if (themeMode === "dark") {
      setTheme({ mode: "dark", ...mainThemeDark });
      setHeaderTheme({ ...headerThemeDark });
      setSidebarTheme({ ...sidebarThemeDark });
      setFooterTheme({ ...footerThemeDark });
    } else {
      setTheme({ mode: "light", ...mainThemeDefault });
      setHeaderTheme({ ...headerThemeDefault });
      setSidebarTheme({ ...sidebarThemeDefault });
      setFooterTheme({ ...footerThemeDefault });
    }
  }, [sidebarOptions.style]);

  useEffect(() => {
    const GetRefUserData = () => {
      setLoading(true);
      getreferralUsers(

        (response) => {
          setRefUsers(response?.data?.data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log(error?.response?.data);
        }
      );
    };
    GetRefUserData()
  }, [])
  const loginStatus = checkLogin();

  return (
    <CustomProvider.Provider
      value={{
        loginUserData,
        setloginUserData,
        loading,
        authUserData,
        setCurrentLanguage,
        currentLanguage,
        isLimit,
        setIsLimit,
        setAffData,
        affData,
        refUsers,
        upgradePop,
        setUpgradePop
      }}
    >
      <JumboLayout
        header={loginStatus && <Header />}
        sidebar={loginStatus && <Sidebar />}
        footer={loginStatus && <Footer />}
        headerSx={{ height: 80 }}
      >
        {children}
      </JumboLayout>
    </CustomProvider.Provider>
  );
};

export default VerticalDefault;
export { CustomProvider };
import "./observer/patchResizeObserver";
import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./app/App";
import "./app/config/i18n";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-5ST8N5TC",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { getpoolreports } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

function PoolReports() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [poolData, setPoolData] = useState([]);
  const UnilevelData = () => {
    setLoading(true);
    getpoolreports(
      (response) => {
        setLoading(false);
        setPoolData(response?.data?.data);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    UnilevelData();
  }, []);

  const updatedRows = poolData.map((row, index) => ({
    ...row,
    id: index + 1, // You can use any unique identifier here
  }));

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };

  const columns = [
    // {
    //   field: "username",
    //   headerName: "Sender",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    {
      field: "amount",
      headerName: "Amount",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    },
    {
      field: "pool",
      headerName: "Pool",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        let poolType = "";
        switch (params.value) {
          case "Pool 1 Bonus":
            poolType = "Silver";
            break;
          case "Pool 2 Bonus":
            poolType = "Gold";
            break;
          case "Pool 3 Bonus":
            poolType = "Platinum";
            break;
          default:
            poolType = params.value;
        }
        return <div>{poolType}</div>;
      },
    },
    {
      field: "createdMonth",
      headerName: "Achieved on Month",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "approvedMonth",
      headerName: "Paid on Month",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    // {
    //   field: "details",
    //   headerName: "Details",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 400,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
    // {
    //   field: "createdat",
    //   headerName: "Date",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 200,
    //   editable: true,
    //   groupable: false,
    //   aggregable: false,
    // },
  ];

  // const rows = poolData

  return (
    <JumboDemoCard
      title={t("pages.title.PoolCommission")}
      wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
    >
      <Box sx={{ height: 460, width: 1 }}>
        <DataGrid
          initialState={{
            initialState,
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          rows={updatedRows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          sx={gridDesign}
          pageSizeOptions={[6, 12, 18, 24, 30]}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </Box>
    </JumboDemoCard>
  );
}

export default PoolReports;

import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Avatar, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';

export default function CustomerTable(props) {
	const { t } = useTranslation();
	const { loginUserData } = useContext(CustomProvider);
	// const [refRevenue, setRefRevenue] = useState({ per1: 0, per2: 0 });
	const { refferedUsers, totalUsersCount } = props?.refUsers || {};

	let showStatus = true;
	if (props.hasOwnProperty('showStatus')) {
		showStatus = props.showStatus;
	}

	const [currentPage, setCurrentPage] = useState(1);
	const [currentUsers, setCurrentUsers] = useState([]);
	const [itemsPerPage, setItemsPerPage] = useState(10);

	const totalPages = itemsPerPage === 0 ? 1 : Math.ceil(refferedUsers?.length / itemsPerPage);  // Handle 'View All' case

	const indexOfLastUser = itemsPerPage === 0 ? refferedUsers.length : currentPage * itemsPerPage;
	const indexOfFirstUser = itemsPerPage === 0 ? 0 : indexOfLastUser - itemsPerPage;

	const color = {
		"In trial": "#2c73ff",
		"Active": "#22C55E",
		"subscription_activated": "#22C55E",
		"To Be Collected": "#FFC804",
		"Payment Due": "#FF7A00",
		"Cancelled": "#FF0000"
	};

	const memoizedFormatDate = useMemo(() => (dateInput, isUnix = false) => {
		const date = isUnix ? new Date(dateInput * 1000) : new Date(dateInput);
		return date.toLocaleDateString('en-US', {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	}, []);

	const calculatedRevenue = useMemo(() => {
		const count = totalUsersCount?.[0]?.totalCount || 0;
		let revenue = { per1: 0, per2: 0 };

		if (count >= 1 && count < 3) revenue = { per1: 20, per2: 0 };
		else if (count >= 3 && count < 10) revenue = { per1: 20, per2: 20 };
		else if (count >= 10 && count < 20) revenue = { per1: 22.5, per2: 22.5 };
		else if (count >= 20 && count < 30) revenue = { per1: 25, per2: 25 };
		else if (count >= 30 && count < 50) revenue = { per1: 30, per2: 25 };
		else if (count >= 50) revenue = { per1: 30, per2: 30 };

		return revenue;
	}, [totalUsersCount]);

	const calEstRevenue = (user, revenue) => {
		if (!user || !revenue) return 0;
		if (Number(user.sponsorid) === loginUserData?.user_id) {
			return (user.plan_price * revenue.per1) / 100 || 0;
		} else if (Number(user.l2_sponsorid) === loginUserData?.user_id) {
			return (user.plan_price * revenue.per2) / 100 || 0;
		}
		return 0;
	};

	useEffect(() => {
		setCurrentUsers(() => refferedUsers?.slice(indexOfFirstUser, indexOfLastUser));
	}, [currentPage, refferedUsers, itemsPerPage]);

	const paginate = (pageNumber) => setCurrentPage(pageNumber);
	// height: "690px"
	return (
		<>
		    <div className="table-outer-box">
				<TableContainer className='sep-AffiliateTable-div' component={Paper} elevation={3}>
					<Table className='sep-AffiliateTable-main' aria-label="customer table">
						<TableHead>
							<TableRow sx={{ backgroundColor: '#f4f6f8' }}>
								<TableCell><Typography variant="subtitle2">#</Typography></TableCell>
								<TableCell><Typography variant="subtitle2">Name</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Plan</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Period</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Price</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Estimated Revenue</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Joining Date</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Activation on</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Sponsored By</Typography></TableCell>
								<TableCell align="center"><Typography variant="subtitle2">Status</Typography></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!currentUsers?.length ? (
								<TableRow>
									<TableCell colSpan={10} align="center" sx={{ padding: '16px' }}>
										<Typography variant="body1" color="textSecondary">{t("pages.title.Noanynewsaleincurrentmonth")}</Typography>
									</TableCell>
								</TableRow>
							) : (
								currentUsers.map((userData, index) => (
									<TableRow
										key={`${userData?.customerid}-${index}`}
										sx={{
											backgroundColor: index % 2 === 0 ? '#f6f6f6' : 'inherit',
											'&:hover': { backgroundColor: '#f1f3f5' },
											transition: 'background-color 0.3s ease',
										}}
									>
										<TableCell align="left">{indexOfFirstUser + index + 1}</TableCell>
										<TableCell align="left">
											<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
												<Avatar alt="Avatar" sx={{ width: 40, height: 40 }} />
												<div>
													<Typography variant="body2" sx={{ fontWeight: '500' }}>{`${userData?.firstname} ${userData?.lastname}`}</Typography>
													<Typography variant="body2" sx={{ color: '#888', fontSize: '0.65rem' }}>
														{Number(userData?.sponsorid) === loginUserData?.user_id ? userData?.email || ' ' : ' '}
													</Typography>
												</div>
											</div>
										</TableCell>
										<TableCell align="center">{userData?.plan_pkg === "Unlimited_new" ? "Unlimited" : userData?.plan_pkg}</TableCell>
										<TableCell align="center">{(userData?.sub_type === "year" ? 12 : userData?.plan_period) + (userData?.plan_period === 1 && userData?.sub_type === "month" ? " Month" : " Months")}</TableCell>
										<TableCell align="center">{`${userData?.plan_price} ${userData?.currency === "USD" ? "$" : "€"}`}</TableCell>
										<TableCell align="center">
											{`${calEstRevenue(userData, calculatedRevenue).toFixed(2)} ${userData?.currency === "USD" ? "$" : "€"}`}
										</TableCell>
										<TableCell align="center">{memoizedFormatDate(userData?.createdat)}</TableCell>
										<TableCell align="center">
											{userData?.trial_end === 0
												? memoizedFormatDate(userData?.createdat)
												: memoizedFormatDate(userData?.trial_end, true)}
										</TableCell>
										<TableCell align="center">
											{Number(userData?.sponsorid) === loginUserData?.user_id ? 'You' : userData?.sponsor_name}
										</TableCell>
										<TableCell align="center">
											{userData?.trial_status === 'Active' ? (
												<Chip label="In trial" sx={{
													backgroundColor: color["In trial"], color: '#fff',
													width: '80px',
													textAlign: 'center',
												}} />
											) : null
											}
										</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<CustomerTablePagination
						skip={indexOfFirstUser}
						limit={itemsPerPage}
						setItemsPerPage={setItemsPerPage}
						itemsPerPage={itemsPerPage}
						page={currentPage}
						totalPages={totalPages}
						onPageChange={paginate}
					/>
			</div>
			
		</>
	);
}

const CustomerTablePagination = ({ page, totalPages, onPageChange, setItemsPerPage, itemsPerPage }) => {

	const handleNext = () => {
		if (page < totalPages) onPageChange(page + 1);
	};

	const handlePrev = () => {
		if (page > 1) onPageChange(page - 1);
	};

	const handlePageClick = (pageNumber) => {
		onPageChange(pageNumber);
	};

	const renderPageNumbers = () => {
		const pages = [];
		if (totalPages <= 10) {
			for (let i = 1; i <= totalPages; i++) {
				pages.push(i);
			}
		} else {
			pages.push(1, 2);
			if (page > 4) pages.push('...');
			const start = Math.max(3, page - 2);
			const end = Math.min(totalPages - 2, page + 2);
			for (let i = start; i <= end; i++) {
				pages.push(i);
			}
			if (page < totalPages - 3) pages.push('...');
			pages.push(totalPages - 1, totalPages);
		}
		return pages;
	};

	const handleItemsPerPageChange = (event) => {
		const value = event.target.value === 'View All' ? 0 : Number(event.target.value);
		setItemsPerPage(value);
		onPageChange(1); // Reset to first page
	};

	const btnStyle = {
		backgroundColor: "#fff",
		color: "grey",
		padding: "5px 15px",
		margin: "0 5px",
		borderRadius: "5px",
	};

	return (
		<div style={{ padding: '10px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
			<div style={{
				display: "flex",
				alignItems: "center",
				gap: "8px",
				width: "290px"
			}}>
				<label htmlFor="items-per-page" style={{
					width: "110px"
				}}>Items per page:</label>
				<select
					id="items-per-page"
					value={itemsPerPage}
					onChange={handleItemsPerPageChange}
					style={{
						padding: '5px 10px',
						borderRadius: '5px',
						width: 'auto',
						minWidth: "80px" 
					}}
				>
					<option value="10">10</option>
					<option value="20">20</option>
					<option value="50">50</option>
					<option value="0">View All</option>
				</select>
			</div>


			<div>
				{
					itemsPerPage !== 0 && <div>
						<button
							onClick={handlePrev}
							disabled={page === 1}
							style={btnStyle}
						>
							{'<'}
						</button>

						{renderPageNumbers().map((pageNumber, index) => (
							<button
								key={index}
								onClick={() => pageNumber !== '...' && handlePageClick(pageNumber)}
								disabled={pageNumber === page || pageNumber === '...'}
								style={{
									...btnStyle,
									backgroundColor: pageNumber === page ? '#2c73ff' : '#f4f5f5',
									color: pageNumber === page ? 'white' : 'black',
									cursor: pageNumber === '...' ? 'default' : 'pointer',
								}}
							>
								{pageNumber}
							</button>
						))}

						<button
							onClick={handleNext}
							disabled={page === totalPages}
							style={btnStyle}
						>
							{'>'}
						</button>
					</div>
				}
			</div>
		</div>
	);
};


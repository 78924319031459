import React, { useContext, useState } from "react";
import AffiliateLayout from "./common/AffiliateLayout";
import CustomerCard from "./common/CustomerCard";
import CustomerTable from "./common/CustomerTable";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Button, CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { BsFunnel, BsGrid, BsListUl } from "react-icons/bs";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import RefCustomerTable from "./common/RefCustomerTable";
import RefCustomerCard from "./common/RefCustomerCard";

export default function AllAffiliatedCustomers() {
  const [searchParams] = useSearchParams();
  const currentTab = searchParams.get('tab');

	const [dataType, setDataType] = useState("grid");
  const { loginUserData, refUsers , loading } = useContext(CustomProvider);
  const navigate = useNavigate();

  if (loginUserData?.user_type === 'Normal') {
    navigate("/affiliate");    
  } 

  const totalCount =  refUsers?.totalUsersCount?.[0]?.totalCount ?? 0;

  // const [loader, setLoader] = useState(true);
  
  // const [dashboardData, setdDashboardData] = useState(null);

  // const GetRefUserData = () => {
  //   setLoader(true);
  //   getreferralUsers(

  //     (response) => {
  //       setRefUsers(response?.data?.data);
  //       setLoader(false);
  //     },
  //     (error) => {
  //       setLoader(false);
  //       console.log(error?.response?.data);
  //     }
  //   );
  // };

  // const GetDashboardData = () => {
  //   setLoader(true);
  //   dashboarddataApi(
  //     (response) => {
  //       setdDashboardData(response?.data?.dashboardData);
  //       setLoader(false);
  //     },
  //     (error) => {
  //       setLoader(false);
  //       console.log(error?.response?.data);
  //     }
  //   );
  // };

  // useEffect(() => {
  //   // GetDashboardData();
  //   GetRefUserData()
  // }, [])


  const handleChange = (event) => {
    console.log(event.target.value);
  };
  return (
    <>
      <AffiliateLayout>
        <div
          className="sep-title-area"
          style={{
          backgroundColor: '#fff',
          boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          margin: '0',
          padding: '20px',
        }}>
          <div className="sep-title-area-left" style={{
            display: "flex",
            gap:"0 8px"
          }}>
            <Link
              to="affiliate"
            >
              <svg
                className="side-drop-arrow"
                width="37"
                height="36"
                viewBox="0 0 37 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ display: "block" }}
              >
                <path
                  d="M36.5 18C36.5 27.6522 28.4541 35.5 18.5 35.5C8.54587 35.5 0.5 27.6522 0.5 18C0.5 8.34784 8.54587 0.5 18.5 0.5C28.4541 0.5 36.5 8.34784 36.5 18Z"
                  fill="#E9F1FF"
                  stroke="#2C73FF"
                />
                <path
                  d="M20 23.6389L15 18.5L20 13.3611"
                  stroke="#2C73FF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>

            <h2 >{currentTab === 'trial' ? 'Customers' : 'All Customers'}</h2>
          </div>
          <div
            className="sep-title-area-right">
            {/* <FormControl sx={{ width: "150px" }}>
              <legend className="sep-title-select">Sort By</legend>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value="name-asc"
                label="Sort By"
                onChange={handleChange}
              >
                <MenuItem value="name-asc">Name (A-Z)</MenuItem>
                <MenuItem value="name-desc">Name (Z-A)</MenuItem>
              </Select>
            </FormControl> */}
            {/* <Button className="sep-filter-btn"
              variant="contained"
              color="primary"
            >
              <BsFunnel />
              <span>Filter</span>
            </Button> */}
            <Button 
              className="sep-viewlist-btn"
              onClick={()=>setDataType(dataType === "grid"?"list":"grid")}
              variant="contained"
              color="success"
            >
              {dataType === "grid" ? (
                <>
                  <BsListUl />
                  <span style={{
                    marginLeft:"8px"
                  }}>List View</span>
                </>
              ) : (
                <>
                  <BsGrid />
                  <span style={{
                    marginLeft:"8px"
                  }}>Grid View</span>
                </>
              )}
            </Button>
          </div>
        </div>
        
        <div style={{
          backgroundColor: '#fff',
          boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '12px',
          margin: '20px 0',
          padding: '20px',
        }}>
          {
            loading ? (
              <div
                style={{
                  width: "10",
                  height: "400px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress size={24} color="inherit" />
              </div>
            ) : currentTab === "trial" ? (
              dataType === "grid" ? (
                <div className="data-grid-area sep-grid-table-cards">
                  {refUsers?.refferedUsers?.map((data, index) => (
                    <CustomerCard refUser={data} totalCount={totalCount} key={index}/>
                  ))}
                </div>
              ) : (
                <CustomerTable refUsers={refUsers} />
              )
            ) : currentTab === "paid" ? (
              dataType === "grid" ? (
                <div className="data-grid-area sep-grid-table-cards">
                  {refUsers?.refferedCustomer?.map((data) => (
                    <RefCustomerCard refCustomer={data} totalCount={refUsers?.referredCustomer?.length} />
                  ))}
                </div>
              ) : (
                <RefCustomerTable refUsers={refUsers} />
              )
            ) : null
          }

        </div>
          

      </AffiliateLayout>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import Div from "@jumbo/shared/Div/Div";
import { CircularProgress } from "@mui/material";
import {
  fetchResellerList,
  deleteReseller,
  updateResellerStatus,
} from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useModalState } from "app/hooks/use-modal-state";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

const ResellerList = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [status, setStatus] = React.useState({});
  const navigate = useNavigate();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [selectedRows, setSelectedRows] = React.useState([]);

  let params = {};
  const FetchResellerData = () => {
    fetchResellerList(
      params,
      (response) => {
        setLoading(false);
        if (response?.data?.data) setGroupData(response?.data?.data ?? []);
      },
      (error) => {}
    );
  };
  function handleDelete() {
    deleteReseller(
      { deleteId },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Deleted Successfully")}`,
          variant: "success",
        });
        deleteModal.onClose();
        FetchResellerData();
      },
      (error) => {}
    );
  }

  const handleStatusChange = (resellerId, newStatus) => {
    // Update the status in the local state
    setStatus((prevStatus) => ({
      ...prevStatus,
      [resellerId]: newStatus,
    }));

    // Update the status in the backend
    updateResellerStatus(
      resellerId,
      newStatus,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
        } else if (response?.data?.status === "success") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "success",
          });
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
        }
      },
      (error) => {}
    );
  };

  const { loginUserData } = useContext(CustomProvider);
  const onWhitelist = () => {};
  const deleteModal = useModalState();
  useEffect(() => {
    FetchResellerData();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const gridDesign = {
    "& .MuiDataGrid-main": {
      height: "320px",
    },
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
    "& .MuiDataGrid-columnHeaders": {
      background: "#ffffff",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 10,
      borderBottomRightRadius: 10,
    },
    "& .MuiDataGrid-row": {
      marginTop: 2,
      background: "#ffffff",
      borderRadius: 2,
    },
    "& .MuiDataGrid-footerContainer": {
      background: "#ffffff",
    },
    "& .MuiTextField-root": {
      position: "absolute",
      right: 30,
      top: -55,
    },
  };

  const columns = [
    {
      field: "company_name",
      headerName: "Company Name",
      dataGeneratorUniquenessEnabled: true,
      width: 250, // Adjust the width as needed
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.company}
        </div>
      ),
    },
    {
      field: "first_name",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 250, // Adjust the width as needed
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.firstname}
        </div>
      ),
    },
    {
      field: "last_name",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.lastname}
        </div>
      ),
    },
    // {
    //   field: "comments",
    //   headerName: "#Comments",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) => (params.row.comments ? params.row.comments : 0),
    // },
    // {
    //   field: "messages",
    //   headerName: "# Messages",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) =>
    //   params.row.messages ? params.row.messages : 0,
    // },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.email}
        </div>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.mobile}
        </div>
      ),
    },
    {
      field: "company_address",
      headerName: "Address of Company",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.address1}
        </div>
      ),
    },
    {
      field: "username",
      headerName: "Username for the admin",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {params.row.username}
        </div>
      ),
    },
    {
      field: "Action",
      headerName: "Action",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      renderCell: (params) => (
        <>
          {" "}
          <IconButton aria-label="delete" style={{ color: "red" }}>
            <DeleteIcon
              onClick={() => {
                setDeleteId(params.row.id);
                deleteModal.onOpen();
              }}
            />
          </IconButton>
          <IconButton aria-label="edit" style={{ color: "red" }}>
            <EditIcon
              onClick={() => navigate(`/edit-gray-label/${params.row.id}`)}
            />
          </IconButton>
          <>
            <Switch
              defaultChecked={params.row.status === "Approved"}
              onChange={(event) =>
                handleStatusChange(params.row.id, event.target.checked ? 1 : 0)
              }
            />
          </>
        </>
      ),
    },

    // {
    //   field: "tier",
    //   headerName: "Tier",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 100,
    //   renderCell: (params) =>
    //     params.row.tier ? params.row.tier : "Tier1",
    // },
    // {
    //   field: "has_conversection",
    //   headerName: "Has Conversection",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150, // Adjust the width as needed
    //   renderCell: (params) =>
    //     params.row.has_conversection === 0 ? (
    //       <Chip label="Yes" color="success" variant="outlined" />
    //     ) : (
    //       <Chip label="No" color="secondary" variant="outlined" />
    //       )

    // },
    // {
    //   field: "status",
    //   headerName: "Is Active",
    //   dataGeneratorUniquenessEnabled: true,
    //   width: 150, // Adjust the width as needed
    //   renderCell: (params) =>
    //     params.row.status === 0 ? (
    //       <Chip label="Yes" color="success" variant="outlined" />
    //     ) : (
    //       <Chip label="No" color="secondary" variant="outlined" />
    //     ),
    // },
  ];

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <JumboDemoCard
        title="Gray lable Users"
        sx={{
          borderRadius: "0%",
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
        wrapperSx={{
          backgroundColor: "background.paper",
          pt: 0,
          borderRadius: 0,
        }}
      >
        <Box sx={{ width: 1, position: "relative" }}>
          <Box sx={{ position: "relative", top: -12 }}></Box>
          <Button variant="contained" component={Link} to="/new-gray-label">
            Add New
          </Button>
        </Box>
        <Dialog
          maxWidth="xs"
          open={deleteModal.isOpen}
          sx={{
            ".MuiDialog-container": {
              alignItems: "flex-start",
            },
            "& .MuiDialog-paper": { mt: 12, width: "80%", maxHeight: 435 },
          }}
        >
          <DialogContent>
            <Typography color="grey.600" mt={3} variant="body2">
              {t("pages.title.delete_data")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteModal.onClose}>
              {t("pages.title.cancel")}
            </Button>
            <Button onClick={handleDelete} variant="contained">
              {t("pages.title.confirm")}
            </Button>
          </DialogActions>
        </Dialog>
      </JumboDemoCard>
      <DataGrid
        rows={groupData}
        columns={columns}
        slots={{ toolbar: QuickSearchToolbar }}
        sx={gridDesign}
        hideScrollbar={true}
      />
    </>
  );
};

function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        px: 0.5,
        py: 1.5,
        "& .MuiInputBase-root": {
          "&:after": {
            border: "none",
          },
        },
      }}
    >
      <GridToolbarQuickFilter />
    </Box>
  );
}

export default ResellerList;

import React from "react";

import "./index.css";
import { useNavigate } from "react-router-dom";

const CheckProfileModal = () => {
  const navigate = useNavigate();

  return (
    <div className="connect-wraper">
      <button
        className="connect-account-btn"
        onClick={() => {
          navigate("/");
        }}
      >
        connect your account
      </button>
    </div>
  );
};

export default CheckProfileModal;

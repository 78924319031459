import { useTranslation } from "react-i18next";
import "../instagramprospection.css";
import { countryList } from "../addtarget/CountryList";

export function ThirdStep({ keywordData }) {
  const { t } = useTranslation();
  let gender = [
    { value: "male", text: `${t("pages.title.male")}` },
    { value: "female", text: `${t("pages.title.female")}` },
    { value: "both", text: `${t("pages.title.both")}` },
  ];

  return (
    <div className="col-wraper col-1-2-1">
      <div className="">
        <span className="cards-title">Gender</span>
        <div className="bordered-cards">
          {gender.map((item, index) => {
            return (
              <div
                className="gray-check
                active-check"
              >
                <span className="text-center">{item.text}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <span className="cards-title">Country</span>
        <div className="bordered-cards">
          <select className="step-select">
            {countryList.map((country, index) => (
              <option key={index} value={country}>
                {country}
              </option>
            ))}
          </select>
          <textarea
            className="step-textarea"
            placeholder="Australia, America"
          ></textarea>
          <button className="bordered-btn bordered-btn-bg w-100">
            CLEAR TEXTAREA
          </button>
        </div>
      </div>
      <div className="">
        <span className="cards-title">Keyword</span>
        <div className="bordered-cards">
          <div className="gray-check">
            <span className="text-center">None</span>
          </div>
          <span className="inner-title-sm">Retarget same user?</span>
          <select className="step-select">
            {keywordData.map((item, index) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

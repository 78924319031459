import React from 'react'
import {useTranslation} from "react-i18next";

const Affiliateaggrement = () => {
  const {t} = useTranslation();
  return (
    <div>
        {t('pages.title.aggrementpara')}
    </div>
  )
}

export default Affiliateaggrement
import React, { useEffect, useMemo, useState } from "react";
import { stepCount } from "..";
import agtemplateicons from "../images/ag-template-icons.svg";
import {
  createMessageTemplate,
  deleteNewMessage,
  getAllMessagesList,
  getAllMessageTemplate,
  setFavoriteMessage,
} from "backendServices/ApiCalls";
import { Button, FormControl, Menu, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar, Alert } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import Div from "@jumbo/shared/Div/Div";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";

export function MessageListScreen({
  setStep,
  handleClose,
  setPreviewData,
  setNewMessageId,
  setMessageId,
  setIsCreateMessage,
  selectedLanguage,
  setSelectedLanguage,
  categoryList,
  setCategoryList,
  selectedCategory,
  setSelectedCategory,
  handleMoreButtonOptions
}) {
  const [templateList, setTemplateList] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [myMessageCategoryId, setMyMessageCategoryId] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isDuplicateModal, setIsDuplicateModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const moreButtonOpen = Boolean(anchorEl);
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  const handleMoreButtonOpen = (event,item) => {
    setAnchorEl(event.currentTarget);
    setCurrentMessage(item)
  };

  const handleMoreButtonClose = (e, item) => {
    setAnchorEl(null);
    handleMoreButtonOptions(e.target.innerText?.trim(), item);


    // handleClose();
  };

  const FetchData = () => {
    setIsLoading(true);
    getAllMessageTemplate(
      {},
      (response) => {
        const data = response.data;
        const categories = data.reduce((uniqueCategories, currentItem) => {
          if (currentItem.category.name === "My message") {
            setMyMessageCategoryId(currentItem.category.id);
          }
          if (
            !uniqueCategories.some(
              (category) => category.name === currentItem.category.name
            )
          ) {
            uniqueCategories.push(currentItem.category);
          }
          return uniqueCategories;
        }, []);

        setCategoryList(categories);
        setTemplateList(data);

        if (categories.length > 0 && !selectedCategory) {
          const defaultCategory = categories[0];
          setSelectedCategory(defaultCategory.id);
          const messages = data.filter(
            (item) => item.category.id === defaultCategory.id
          );
          setFilteredMessages(messages);
        }
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
      }
    );
    // getAllMessagesList(
    //   {},
    //   (response) => {
    //     const data = response.data;

    //     const categories = data.reduce((uniqueCategories, currentItem) => {
    //       if (
    //         !uniqueCategories.some(
    //           (category) => category.name === currentItem.category.name
    //         )
    //       ) {
    //         uniqueCategories.push(currentItem.category);
    //       }
    //       return uniqueCategories;
    //     }, []);

    //     setCategoryList(categories);
    //     setTemplateList(data);

    //     if (categories.length > 0 && !selectedCategory) {
    //       const defaultCategory = categories[0];
    //       setSelectedCategory(defaultCategory.id);
    //       const messages = data.filter(
    //         (item) => item.category.id === defaultCategory.id
    //       );
    //       setFilteredMessages(messages);
    //     }
    //   },
    //   (error) => {}
    // );
  };
  function handleDelete() {
    setAnchorEl(null);
    deleteNewMessage(
      { messageId: currentMessage.id },
      (response) => {
        setalertData({
          show: true,
          message: `${t("pages.title.Deleted Successfully")}`,
          variant: "success",
        });
        setIsDeleteModal(false);
        setCurrentMessage("");
        FetchData();
      },
      (error) => {
        setCurrentMessage("");
        setIsDeleteModal(false);
      }
    );
  }
  const handleDuplicate = () => {
    setBtnLoading(true)
    setIsDuplicateModal(false);

    setAnchorEl(null);
    const params = {
      name: `${currentMessage?.title} (Copy)`,
      variants: currentMessage?.variants?.map((variant) => variant?.name),
    };
    createMessageTemplate(
      params,
      () => {
        setalertData({
          show: true,
          message: "Duplicate created Successfully",
          variant: "success",
        });

        FetchData();
        setIsDuplicateModal(false);
    setBtnLoading(false)

        setCurrentMessage("");
      },
      (error) => {
        console.log(error);
        setCurrentMessage("");
        setIsDuplicateModal(false);
    setBtnLoading(false)

      }
    );
  };

  useEffect(() => {
    FetchData();
    console.log(window.location.pathname,"path")
  }, []);

  function setFavoriteMessages(messageId) {
    setFavoriteMessage(
      { message_id: messageId },
      (response) => {
        setOpen(true);
        FetchData();
      },
      (error) => {
        console.log(error);
      }
    );
  }
  useEffect(() => {
    if (selectedLanguage && !searchTerm) {
      // if (selectedLanguage === "All Languages") {
      //   setFilteredMessages(
      //     templateList.filter((item) => item.category_id === selectedCategory)
      //   );
      //   return;
      // }
      if (selectedLanguage === "English" || myMessageCategoryId === selectedCategory) {
        setFilteredMessages(
          templateList.filter(
            (item) =>
              (selectedCategory !== "fav"
                ? item.category_id === selectedCategory
                : item.favorite === true) &&
              (item.language === "English" || item.language === null)
          )
        );
        return;
      }

      setFilteredMessages(
        templateList.filter(
          (item) =>
            (selectedCategory !== "fav"
              ? item.category_id === selectedCategory
              : item.favorite === true) && item.language === selectedLanguage
        )
      );

      return;
    }
    if (searchTerm === "") {
      setFilteredMessages(
        templateList.filter((item) =>
          selectedCategory !== "fav"
            ? item.category_id === selectedCategory
            : item.favorite === true
        )
      );
    } else {
      const handler = setTimeout(() => {
        setFilteredMessages(
          templateList.filter(
            (item) =>
              (selectedCategory !== "fav"
                ? item.category_id === selectedCategory
                : item.favorite === true) &&
              item.title.toLowerCase().includes(searchTerm.toLowerCase())
          )
        );
      }, 300);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [searchTerm, templateList, selectedCategory, selectedLanguage]);

  const languages = [
    {
      label: `${t("pages.title.english")}`,
      img: "/images/flag/usa.png",
      value: "English",
    },
    {
      label: `${t("pages.title.french")}`,
      img: "/images/flag/french.png",
      value: "French",
    },
    {
      label: `${t("pages.title.german")}`,
      img: "/images/flag/germany.png",
      value: "German",
    },
    {
      label: `${t("widgets.title.Spanish")}`,
      locale: "es-ES",
      img: "/images/flag/spanish.png",
      value: "Spanish",
    },
  ];

  // console.log("filteredMessages", filteredMessages, myMessageCategoryId);

  // useEffect(() => {
  //   if (myMessageCategoryId === selectedCategory) {
  //     setSelectedLanguage("English");
  //   }
  // }, [selectedCategory]);

  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
      <div class="ag-msg-popup">
        <div class="ag-msg-popup-inner ag-template-steps">
          <div class="ag-msg-popup-content">
            <div class="ag-template-mainWrap">
              <div class="ag-template-mainInner">
                <div class="ag-select-temp-header">
                  <div class="ag-header-back-btn">
                    <div class="ag-msg-back-btn">
                      <button
                        class="ag-back-btn"
                        onClick={() => setStep(stepCount.firstScreen)}
                      >
                        <span>
                          <svg
                            width="5"
                            height="9"
                            viewBox="0 0 5 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M4 1L0.764706 4.23529L4 7.47059"
                              stroke="#898989"
                              stroke-width="1.25"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>{" "}
                        {t("pages.title.Back")}
                      </button>
                    </div>
                    <span class="ag-main-title">
                      {" "}
                      {t("pages.title.Select a Template")}
                    </span>
                  </div>
                  <div class="crm-right-search">
                    <form class="serach-form" onSubmit={(e) => e.preventDefault()} >
                      <input
                        type="text"
                        name=""
                        placeholder="Search ...."
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <p class="search-btn">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2083_436)">
                            <path
                              d="M10.0833 1.83301C14.6373 1.83301 18.3333 5.52901 18.3333 10.083C18.3333 14.637 14.6373 18.333 10.0833 18.333C5.52933 18.333 1.83333 14.637 1.83333 10.083C1.83333 5.52901 5.52933 1.83301 10.0833 1.83301ZM10.0833 16.4997C13.6281 16.4997 16.5 13.6278 16.5 10.083C16.5 6.53734 13.6281 3.66634 10.0833 3.66634C6.53766 3.66634 3.66666 6.53734 3.66666 10.083C3.66666 13.6278 6.53766 16.4997 10.0833 16.4997ZM17.8612 16.5648L20.4545 19.1571L19.1574 20.4542L16.5651 17.8609L17.8612 16.5648Z"
                              fill="#7551D2"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_2083_436">
                              <rect width="22" height="22" fill="white"></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </p>
                    </form>
                    <FormControl
                      fullWidth
                      className="custom-language-cs"
                      style={
                        myMessageCategoryId !== selectedCategory
                          ? {}
                          : { pointerEvents: "none", opacity: "0.4" }
                      }
                    >
                      <Select
                        size={"small"}
                        labelId="select-locale"
                        id="customizer-select-locale"
                        value={selectedLanguage}
                        onChange={(e) => setSelectedLanguage(e.target.value)}
                        sx={{
                          "#customizer-select-locale": {
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                          },
                        }}
                      >
                        {languages.map((language) => (
                          <MenuItem
                            key={language?.value}
                            value={language?.value}
                          >
                            <img
                              src={language?.img}
                              alt={language?.label}
                              style={{
                                marginRight: "8px",
                                height: "32px",
                                width: "32px",
                              }}
                            />
                            <span style={{ width: "70px", textAlign: "left" }}>
                              {language.label}
                            </span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div class="ag-selectTemplate ag-scroll">
                  <div class="ag-selectLeft ctm-scroll">
                    <ul class="ag-selectNav">
                      {categoryList
                        .filter((category) => category.name !== "My message")
                        .map((category) => (
                          <li
                            key={category.id}
                            className={
                              selectedCategory === category.id ? "active" : ""
                            }
                            onClick={() => {
                              setSelectedCategory(category.id);
                              const messages = templateList.filter(
                                (item) => item.category.id === category.id
                              );
                              setFilteredMessages(messages);
                            }}
                          >
                            <div>
                              <span>{category.name}</span>
                              <svg
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.16388 6.71078C7.0902 6.77944 7.03109 6.86224 6.9901 6.95424C6.94911 7.04624 6.92707 7.14556 6.92529 7.24626C6.92351 7.34696 6.94204 7.44699 6.97976 7.54038C7.01748 7.63377 7.07363 7.7186 7.14484 7.78982C7.21606 7.86104 7.3009 7.91718 7.39428 7.95491C7.48767 7.99263 7.5877 8.01115 7.68841 8.00937C7.78911 8.0076 7.88842 7.98556 7.98042 7.94456C8.07242 7.90357 8.15522 7.84447 8.22388 7.77078L11.4749 4.52078L12.0049 3.99078L11.4749 3.46078L8.22488 0.210782C8.0835 0.0740957 7.89408 -0.00159032 7.69743 2.5151e-05C7.50078 0.00164062 7.31263 0.0804287 7.17351 0.21942C7.03439 0.358411 6.95542 0.546484 6.95362 0.743131C6.95182 0.939779 7.02733 1.12927 7.16388 1.27078L9.13388 3.24078L0.754883 3.24078C0.55597 3.24078 0.365206 3.3198 0.224553 3.46045C0.0839003 3.6011 0.00488265 3.79187 0.00488264 3.99078C0.00488263 4.18969 0.0839003 4.38046 0.224553 4.52111C0.365206 4.66176 0.55597 4.74078 0.754883 4.74078L9.13388 4.74078L7.16388 6.71078Z"
                                  fill="#A4A4A4"
                                />
                              </svg>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <ul class="ag-selectNav">
                      {categoryList
                        .filter((category) => category.name === "My message")
                        .map((category) => (
                          <li
                            key={category.id}
                            className={
                              selectedCategory === category.id ? "active" : ""
                            }
                            onClick={() => {
                              setSelectedCategory(category.id);
                              const messages = templateList.filter(
                                (item) => item.category.id === category.id
                              );
                              setFilteredMessages(messages);
                            }}
                          >
                            <div>
                              <span>My Messages</span>
                              <svg
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  clip-rule="evenodd"
                                  d="M7.16388 6.71078C7.0902 6.77944 7.03109 6.86224 6.9901 6.95424C6.94911 7.04624 6.92707 7.14556 6.92529 7.24626C6.92351 7.34696 6.94204 7.44699 6.97976 7.54038C7.01748 7.63377 7.07363 7.7186 7.14484 7.78982C7.21606 7.86104 7.3009 7.91718 7.39428 7.95491C7.48767 7.99263 7.5877 8.01115 7.68841 8.00937C7.78911 8.0076 7.88842 7.98556 7.98042 7.94456C8.07242 7.90357 8.15522 7.84447 8.22388 7.77078L11.4749 4.52078L12.0049 3.99078L11.4749 3.46078L8.22488 0.210782C8.0835 0.0740957 7.89408 -0.00159032 7.69743 2.5151e-05C7.50078 0.00164062 7.31263 0.0804287 7.17351 0.21942C7.03439 0.358411 6.95542 0.546484 6.95362 0.743131C6.95182 0.939779 7.02733 1.12927 7.16388 1.27078L9.13388 3.24078L0.754883 3.24078C0.55597 3.24078 0.365206 3.3198 0.224553 3.46045C0.0839003 3.6011 0.00488265 3.79187 0.00488264 3.99078C0.00488263 4.18969 0.0839003 4.38046 0.224553 4.52111C0.365206 4.66176 0.55597 4.74078 0.754883 4.74078L9.13388 4.74078L7.16388 6.71078Z"
                                  fill="#A4A4A4"
                                />
                              </svg>
                            </div>
                          </li>
                        ))}
                      <li
                        className={selectedCategory === "fav" ? "active" : ""}
                        onClick={() => {
                          const temp = templateList.filter(
                            (item) => item.favorite === true
                          );
                          setFilteredMessages(temp);
                          setSelectedCategory("fav");
                        }}
                      >
                        <div>
                          <span
                            style={{
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            My Favorites{" "}
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 12C5.81473 11.9997 5.63382 11.9391 5.4808 11.8259C3.2139 10.1587 2.23232 9.01563 1.6909 8.30094C0.537119 6.7775 -0.0152558 5.21344 0.000320296 3.51969C0.0184924 1.57875 1.45582 0 3.20438 0C4.47585 0 5.35648 0.775938 5.86933 1.42219C5.88558 1.44245 5.90565 1.4587 5.9282 1.46984C5.95074 1.48098 5.97522 1.48674 6 1.48674C6.02478 1.48674 6.04926 1.48098 6.07181 1.46984C6.09435 1.4587 6.11442 1.44245 6.13067 1.42219C6.64352 0.775313 7.52415 0 8.79562 0C10.5442 0 11.9815 1.57875 11.9997 3.52C12.0153 5.21406 11.4623 6.77813 10.3091 8.30125C9.76768 9.01594 8.7861 10.1591 6.5192 11.8263C6.36615 11.9393 6.18524 11.9998 6 12Z"
                                fill="#ff0000"
                              ></path>
                            </svg>
                          </span>
                          <svg
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.16388 6.71078C7.0902 6.77944 7.03109 6.86224 6.9901 6.95424C6.94911 7.04624 6.92707 7.14556 6.92529 7.24626C6.92351 7.34696 6.94204 7.44699 6.97976 7.54038C7.01748 7.63377 7.07363 7.7186 7.14484 7.78982C7.21606 7.86104 7.3009 7.91718 7.39428 7.95491C7.48767 7.99263 7.5877 8.01115 7.68841 8.00937C7.78911 8.0076 7.88842 7.98556 7.98042 7.94456C8.07242 7.90357 8.15522 7.84447 8.22388 7.77078L11.4749 4.52078L12.0049 3.99078L11.4749 3.46078L8.22488 0.210782C8.0835 0.0740957 7.89408 -0.00159032 7.69743 2.5151e-05C7.50078 0.00164062 7.31263 0.0804287 7.17351 0.21942C7.03439 0.358411 6.95542 0.546484 6.95362 0.743131C6.95182 0.939779 7.02733 1.12927 7.16388 1.27078L9.13388 3.24078L0.754883 3.24078C0.55597 3.24078 0.365206 3.3198 0.224553 3.46045C0.0839003 3.6011 0.00488265 3.79187 0.00488264 3.99078C0.00488263 4.18969 0.0839003 4.38046 0.224553 4.52111C0.365206 4.66176 0.55597 4.74078 0.754883 4.74078L9.13388 4.74078L7.16388 6.71078Z"
                              fill="#A4A4A4"
                            />
                          </svg>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="ag-selectRight ag-scroll">
                  {
                    isLoading ? (
                        <Div
                          sx={{
                            display: "flex",
                            minWidth: 0,
                            alignItems: "center",
                            alignContent: "center",
                            height: "100%",
                            width:"100%",
                            justifyContent: "center",
                          }}
                        >
                          <CircularProgress  />
                        </Div>)
                        :(
                          <div class="ag-selectWraper">
                      {
                     
                        filteredMessages.map((item,i) => (
                          <div class="ag-selectItems" key={`${item.id}-${i}`}>
                            <button
                              className={"ag-favorite"}
                              onClick={() => {
                                setAlertMessage(
                                  item?.favorite
                                    ? "Removed from favorites!"
                                    : "Added to favorites!"
                                );
                                setFavoriteMessages(item.id);
                              }}
                            >
                              <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6 12C5.81473 11.9997 5.63382 11.9391 5.4808 11.8259C3.2139 10.1587 2.23232 9.01563 1.6909 8.30094C0.537119 6.7775 -0.0152558 5.21344 0.000320296 3.51969C0.0184924 1.57875 1.45582 0 3.20438 0C4.47585 0 5.35648 0.775938 5.86933 1.42219C5.88558 1.44245 5.90565 1.4587 5.9282 1.46984C5.95074 1.48098 5.97522 1.48674 6 1.48674C6.02478 1.48674 6.04926 1.48098 6.07181 1.46984C6.09435 1.4587 6.11442 1.44245 6.13067 1.42219C6.64352 0.775313 7.52415 0 8.79562 0C10.5442 0 11.9815 1.57875 11.9997 3.52C12.0153 5.21406 11.4623 6.77813 10.3091 8.30125C9.76768 9.01594 8.7861 10.1591 6.5192 11.8263C6.36615 11.9393 6.18524 11.9998 6 12Z"
                                  fill={item?.favorite ? "#ff5f5f" : "#C5C5C5"}
                                />
                              </svg>
                            </button>
                            <span class="ag-selestImg">
                              <img src={agtemplateicons} alt="" />
                            </span>
                            <span class="ag-selectTitle">{item.title} </span>
                            <div class="ag-selectBtnWrap">
                              <button
                                class="ag-BorderBtn"
                                onClick={() => {
                                  // const variant = item.variants.map(
                                  //   (variant) => variant.name
                                  // );
                                  setIsCreateMessage(false);
                                  setPreviewData(item);
                                  setMessageId(item.id);
                                  setStep(stepCount.previewScreen);
                                }}
                              >
                                {t("pages.title.Preview")}
                              </button>
                              {myMessageCategoryId === selectedCategory ? (
                                <>
                                  <div
                                    style={
                                     {position: "relative"}
                                        
                                    }
                                  >
                                    <Button
                                      id="basic-button"
                                      aria-controls="basic-menu"
                                      aria-haspopup="true"
                                      aria-expanded={
                                        moreButtonOpen ? "true" : undefined
                                      }
                                      onClick={(e)=>handleMoreButtonOpen(e,item)}
                                      sx={{
                                        borderRadius: "30px",
                                        border:
                                          "1px solid rgb(44 115 255 / 25%)",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: "10px",
                                        padding: "10px",
                                        fontSize: "12px",
                                        lineHeight: "1",
                                        fontWeight: "400",
                                        background: "#2165ed",
                                        color: "#fff",
                                        cursor: "pointer",
                                        transition: "0.25s",
                                        width: "100%",
                                        textTransform: "none",
                                      }}
                                      className="ag-FilBtn"
                                      variant="contained"
                                    >
                                      More
                                    </Button>
                                    <Menu
                                      id="basic-menu"
                                      anchorEl={anchorEl}
                                      open={moreButtonOpen && currentMessage.id === item.id}
                                      onClose={(e) =>
                                        handleMoreButtonClose(e, item)
                                      }
                                      MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                      }}
                                    >
                                      <MenuItem
                                        onClick={(e) => {
                                         
                                          setAnchorEl(null)
                                          setIsDuplicateModal(true);
                                        }}
                                        value="duplicate"
                                      >
                                        <FileCopyOutlinedIcon /> Duplicate
                                      </MenuItem>
                                      <MenuItem
                                        onClick={(e) =>
                                          handleMoreButtonClose(e, item)
                                        }
                                        value="edit"
                                      >
                                        <EditCalendarIcon /> Edit
                                      </MenuItem>
                                      <MenuItem
                                        onClick={(e) => {
                                          setAnchorEl(null)
                                          setIsDeleteModal(true);
                                        }}
                                        value="delete"
                                      >
                                        <DeleteOutlineOutlinedIcon /> Delete
                                      </MenuItem>
                                    </Menu>
                                  </div>
                                </>
                              ) : (
                                <button
                                  class="ag-FilBtn"
                                  onClick={() => {
                                    if (typeof setNewMessageId === "function") {
                                      setNewMessageId(item);
                                    }

                                    // handleClose();
                                  }}
                                >
                                  {t("pages.title.Select")}
                                </button>
                              )}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                        )
                      
                  }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Dialog
        maxWidth="xs"
        open={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
          >
            Delete Message
          </Typography>
        </Box>
        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box
            className="stage-popup-main-text"
            sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "center" }}>
                {t("pages.title.delete_data")}
              </Typography>
            </Box>

            <Box
              className="stage-btn-group"
              sx={{
                backgroundColor: "#F4F1FA",
                m: "-20px",
                pb: "15px",
                pt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <button
                  variant="contained"
                  color="error"
                  className="red-pop-btn"
                  onClick={() => handleDelete()}
                >
                  {t("pages.title.delete")}
                </button>
                <button
                  onClick={() => setIsDeleteModal(false)}
                  className="gray-pop-btn"
                >
                  {t("pages.title.cancel")}
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="xs"
        open={isDuplicateModal}
        onClose={() => setIsDuplicateModal(false)}
        sx={{
          ".MuiDialog-container": {
            mt: "-80px",
          },
          "& .MuiDialog-paper": {
            width: "80%",
            marginTop: "174px",
            borderRadius: "12px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#F4F1FA",
            color: "#2C73FF",
            p: 2,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              textAlign: "center",
              flexGrow: 1,
              color: "#2C73FF",
            }}
          >
            Duplicate Message
          </Typography>
        </Box>
        <DialogContent className="stage-popup-main" sx={{ padding: "0px" }}>
          <Box
            className="stage-popup-main-text"
            sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                padding: "20px",
              }}
            >
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.26444 16.0377H15.7356C15.9543 16.0377 16.1694 15.9809 16.3597 15.8729C16.5499 15.7649 16.7089 15.6093 16.8211 15.4215C16.9333 15.2336 16.9948 15.0199 16.9997 14.8012C17.0046 14.5824 16.9526 14.3662 16.8489 14.1735L10.1137 1.66514C9.63589 0.778287 8.36411 0.778287 7.8863 1.66514L1.15114 14.1735C1.04742 14.3662 0.995449 14.5824 1.00031 14.8012C1.00518 15.0199 1.0667 15.2336 1.17889 15.4215C1.29107 15.6093 1.45006 15.7649 1.64034 15.8729C1.83061 15.9809 2.04565 16.0377 2.26444 16.0377Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.77164 6.12383L8.99849 10.9454L9.22494 6.12581C9.22634 6.095 9.22144 6.06423 9.21054 6.03537C9.19965 6.00652 9.18298 5.98019 9.16156 5.958C9.14015 5.9358 9.11443 5.91821 9.08598 5.90629C9.05754 5.89437 9.02696 5.88838 8.99612 5.88868C8.96581 5.88898 8.93588 5.89534 8.90808 5.9074C8.88028 5.91946 8.85517 5.93696 8.83425 5.95888C8.81332 5.9808 8.797 6.00669 8.78625 6.03502C8.7755 6.06335 8.77053 6.09355 8.77164 6.12383Z"
                  stroke="#FF0202"
                  strokeOpacity="0.7"
                  strokeWidth="1.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.9994 14.1004C8.84307 14.1004 8.69025 14.054 8.56027 13.9672C8.43029 13.8803 8.32898 13.7569 8.26915 13.6124C8.20933 13.468 8.19367 13.3091 8.22417 13.1557C8.25467 13.0024 8.32995 12.8616 8.44049 12.751C8.55104 12.6405 8.69187 12.5652 8.8452 12.5347C8.99853 12.5042 9.15745 12.5199 9.30188 12.5797C9.44631 12.6395 9.56976 12.7408 9.65661 12.8708C9.74347 13.0008 9.78982 13.1536 9.78982 13.31C9.78982 13.5196 9.70655 13.7206 9.55831 13.8689C9.41008 14.0171 9.20904 14.1004 8.9994 14.1004Z"
                  fill="#FF0202"
                  fillOpacity="0.7"
                />
              </svg>
              <Typography sx={{ textAlign: "center" }}>
                {t("pages.title.duplicate_data")}
              </Typography>
            </Box>

            <Box
              className="stage-btn-group"
              sx={{
                backgroundColor: "#F4F1FA",
                m: "-20px",
                pb: "15px",
                pt: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <button
                  variant="contained"
                  color="success"
                  style={{ backgroundColor:"#22C55E"}}
                  onClick={handleDuplicate}
                  disabled={btnLoading}
                >
                  <span>{t("pages.title.confirm")}</span>
                </button>
                <button
                  onClick={() => setIsDuplicateModal(false)}
                  className="gray-pop-btn"
                >
                  {t("pages.title.cancel")}
                </button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import "./GermanEvents.css";
import { ASSET_IMAGES } from "../../utils/constants/paths";
import { useTranslation } from "react-i18next";
import { germanEventCharge, getTicketCount } from "backendServices/ApiCalls";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import SweetAlert from "../components/mui/Alerts/SweetAlert";

const GermanEvents = () => {

    const { t } = useTranslation();
    const { loginUserData } = useContext(CustomProvider);
    const [alertData, setalertData] = useState({
        show: false,
        message: "",
        variant: "",
    });
    const [loading, setLoading] = useState(false)
    const [tickets, setTickets] = useState({
        ticketNum: 0,
        ticketLeft:100
    })
    

    const handleBuy = async () => {
        setLoading(true)
    

        const currency = "EUR"
        const itemPriceId = "German-Event-Nuremburg-151124-EUR" 
        let params = {
            customerId: loginUserData?.customerid,
            currency,
            itemPriceId

        };
        germanEventCharge(
            params,
            (response) => {
                if (response.data.status === "success") {
                    window.open(response?.data?.data?.url, '_blank');
                    setLoading(false)

                } else {
                    setalertData({
                        show: true,
                        message: "Something Went Wrong, Please Try Again",
                        variant: "error",
                    });
                    setLoading(false)

                }
            },
            (error) => {
                setLoading(false)

                setalertData({
                    show: true,
                    message: error?.response?.data?.message,
                    variant: "error",
                });
            }
        );
    };

    const getTicketLeft = ()=>{
       
        getTicketCount(
            (response) => {
                if (response.data.status === "success") {
                    const total = response?.data?.data?.[0]?.total_tickets_sold
                    setTickets((data)=>({
                        ...data,
                        ticketLeft:data.ticketLeft - total
                    }))
                } else {
                    setalertData({
                        show: true,
                        message: "Something Went Wrong, Please Try Again",
                        variant: "error",
                    });

                }
            },
            (error) => {

                setalertData({
                    show: true,
                    message: error?.response?.data?.message,
                    variant: "error",
                });
            }
        );
    }

    useEffect(() => {
        getTicketLeft()
    }, [])

    useEffect(() => {
        setTickets((data)=>({
            ...data,
           ticketNum:loginUserData?.isAlreadyCharge
        }))
    }, [loginUserData?.isAlreadyCharge])
    

    return (
        <>
            {alertData.show && (
                <SweetAlert alertData={alertData} setalertData={setalertData} />
            )}
            <div className="german-events">
                <div className="ge-header-container">
                    <h2 className="ge-header">{t("pages.title.ge-title")}</h2>
                </div>
                <div className="ge-main-con">
                    <div className="ge-box-header">
                        <div className="ge-box-date">
                            <h3 >{t("pages.title.ge-subtitle")}</h3>
                            <span className="ge-attendees" >{t("pages.title.ge-limit-att")}</span>
                        </div>

                        <a
                            href="https://maps.app.goo.gl/kbSP99rnCV8unhNm9"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ge-location-link"
                        >
                            {t("pages.title.ge-view-map")}
                        </a>
                    </div>
                    <img src={`${ASSET_IMAGES}/ge-image.png`} alt="German Event" className="ge-image" />
                    <div className="ge-box-con">
                        <div className="ge-box-item">

                            <p className="ge-corp-header">
                                {t("pages.title.ge-moto")}
                            </p>
                            <p>
                                <span className="ge-corp-header">{t("pages.title.ge-corp-title")}</span> {t("pages.title.ge-corp-team")}
                            </p>
                            <p className="ge-corp-header">
                                {t("pages.title.ge-corp-moto")}
                            </p>
                            <p>
                                <span className="ge-corp-header">{t("pages.title.ge-note")} </span>  {t("pages.title.ge-details")}
                            </p>
                            <p className="ge-disclaimer">
                                <span className="ge-corp-header">
                                    {t("pages.title.ge-imp")}{" "}
                                </span>
                                {t("pages.title.ge-imp-msg")}
                            </p>
                            <p >
                                {t("pages.title.ge-team-msg")}
                            </p>
                            <p>
                                <span className="ge-corp-header">{t("pages.title.ge-team-nva")}</span>
                            </p>


                        </div>
                        <div className="ge-offer-con">
                            {
                                tickets.ticketNum > 0 && <p className="ge-corp-header  success-con" style={{ fontSize: "15px", color: "#fff" }}>
                                        {
                                            tickets.ticketNum === 1
                                                ? t("pages.title.ge-single-ticket", { count: tickets.ticketNum })
                                                : t("pages.title.ge-many-tickets", { count: tickets.ticketNum })
                                        }
                                    </p>
                            }
                            <p className="ge-corp-header" style={{ fontSize: "18px" }}>
                                {t("pages.title.ge-discount")}
                            </p>
                            {/* <p className="ge-corp-header">
                                {t("pages.title.ge-time")}
                            </p> */}
                            <p className="ge-corp-header">
                                {t("pages.title.ge-acc-price")}
                            </p>
                            <button disabled={loading} style={{ background: loading && "grey" }} className="ge-buy-btn" onClick={() => handleBuy()}>
                                {t("pages.title.ge-dis-price")}
                            </button>
                            <p className="ge-corp-header" style={{ textAlign: "center", color: "#ff2424" }}>
                                {
                                    tickets.ticketLeft === 0
                                    ? t("pages.title.ge-no-spots")
                                    : tickets.ticketLeft === 1
                                        ? t("pages.title.ge-single-spot", { count: tickets.ticketLeft })
                                        : t("pages.title.ge-many-spot", { count: tickets.ticketLeft })
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default GermanEvents;

import React, { useState, useEffect } from "react";
import Div from "@jumbo/shared/Div";
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
  Grid,
  CircularProgress,
} from "@mui/material";
import Link from "@mui/material/Link";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import { getAssetPath } from "../../../utils/appHelpers";
import { countries } from "../../components/mui/AutoCompletes/data";
import {
  registerUser,
  get_user_data_by_referral_id,
  getCompanyLogo,
} from "../../../../backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { Link as MyLink, useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { LoadingButton } from "@mui/lab";
import Spinner from "app/shared/Spinner/Spinner";
import { useTranslation } from "react-i18next";



const Signup = () => {
  const { t } = useTranslation();
  const { referralid } = useParams();
  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [sponsorData, setSponsorData] = useState({});
  const [lan, setLan] = useState("en");
  const [value, setValue] = React.useState(new Date("2022-06-04T21:11:54"));
  const [referralIdSpinner, setReferralIdSpinner] = useState(true);
  const [loader, setLoader] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(
    countries.find(
      (country) => country.label.toLowerCase() === "France".toLowerCase()
    )
  );

  const validationSchema = yup.object({
    firstname: yup.string("Enter first name").required("first name is required"),
    username: yup
      .string("Enter username")
      .matches(
        /^[a-zA-Z0-9]*$/,
        "Username must only contain alphabets and numbers"
      )
      .matches(/^\S*$/, "Username must not contain spaces")
      .min(6, "Username must be at least 6 characters long")
      .required("Username is required"),
    lastname: yup.string("Enter last name").required("last name is required"),
    email: yup
      .string("Enter email address")
      .email("Invalid email address")
      .required("Email is required"),
    confirm_email: yup
      .string("Enter email address again")
      .email("Invalid email address")
      .oneOf([yup.ref("email"), null], "email must match")
      .required("Email is required"),
    mobile: yup
      .string("Enter mobile number")
      .required("mobile number is required"),
    address: yup.string("Enter your address").required("address is required"),
    zipCode: yup.string("Enter zip code").required("zip code is required"),
    city: yup.string("Enter city").required("city is required"),
    language: yup.mixed("Select language").required("language is required"),
    country: yup.mixed().required("Country is required"),
    mobilecountry: yup.mixed().required("select code"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()+^])[A-Za-z\d@$!%*?&()+^]+$/,
        "Password must include at least one uppercase letter, one lowercase letter, one number, and one special character allowed charecters are @$!%*?&()+^"
      ),
    confirmpassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    if (window.location.hostname == "localhost") {
      var testDomainText = "admin-app.com";
      var testDomain = testDomainText.split(".");
    } else {
      var testDomain = window.location.hostname.split(".");
    }
    const domainParts = testDomain;

    if (domainParts.length >= 1 && domainParts[0]) {
      let final_domain;
      if (domainParts[0].includes("-")) {
        const parts = domainParts[0].split("-");
        final_domain = parts[1];
      } else {
        final_domain = domainParts[0];
      }

      getCompanyLogo({ domain: final_domain }).then((response) => {
        setCompanyData(response.data.data);

        const favicon = document.querySelector('link[rel="icon"]');

        if (favicon && response.data.data.logo_val) {
          favicon.href = response.data.data.logo_val;
        }

        if (response.data.data.company) {
          var capitalizedCompany =
            response.data.data.company.charAt(0).toUpperCase() +
            response.data.data.company.slice(1);
          document.title = capitalizedCompany + "";
        }
      });
    }
  }, []); // Run only once when the component mounts

  const navigate = useNavigate();
  useEffect(() => {
    // Add meta tag to disable Google Translate
    const meta = document.createElement("meta");
    meta.name = "google";
    meta.content = "notranslate";
    document.head.appendChild(meta);

    // Cleanup function to remove the meta tag when component unmounts
    return () => {
      document.head.removeChild(meta);
    };
  }, []);
  useEffect(() => {
    // Function to disable right-click
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    // Add event listener to disable right-click on the entire document
    document.addEventListener("contextmenu", disableRightClick);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []); // Empty dependency array ensures this effect runs only once after component mounts

  const initialstates = {
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    confirm_email: "",
    mobile: "",
    company: "",
    address: "",
    zipCode: "",
    city: "",
    mobilecountry: selectedCountry || null,
    country: null,
    language: null,
    password: "",
    confirmpassword: "",
    birthday: null,
  };
  const handleSubmit = (data, setSubmitting) => {
    let cCode = data.mobilecountry.phone;

    if (!cCode) {
      cCode = "33";
    }

    let birthday = data.birthday;
    const originalDate = new Date(birthday);
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const day = String(originalDate.getDate()).padStart(2, "0");
    const convertedDate = `${year}-${month}-${day}`;
    const domainParts = window.location.hostname.split(".");
    const domain = domainParts.length > 1 ? domainParts[0] : "";

    let params = {
      sponsorid: sponsorData?.randomcode || "NOVALYA",
      username: data.username,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      mobile: cCode + data.mobile,
      address: data.address,
      password: data.password,
      country: data.country.code,
      language: data.language.code,
      company: data.company,
      zipCode: data.zipCode,
      city: data.city,
      item_price_id: localStorage.getItem("planId"),
      coupon_code: "empty",
      birthday: "22-08-1994",
      // birthday: convertedDate,
      domain,
    };

    localStorage.setItem("registration_language", data.language.code);
    registerUser(
      params,
      (response) => {
        if (response?.data?.status === "error") {
          setalertData({
            show: true,
            message: response?.data?.message,
            variant: "error",
          });
          setSubmitting(false);
        } else if (response?.data?.status === "success") {
          setSubmitting(false);
          localStorage.setItem("registration_data", JSON.stringify(params));
          window.location.replace(response?.data?.redirect_url);
        } else {
          setalertData({
            show: true,
            message: "Something went wrong please try again later",
            variant: "error",
          });
          setSubmitting(false);
        }
      },
      (error) => {
        setSubmitting(false);
      }
    );
  };

  const languages = [
    {
      code: "fr",
      label: "French",
    },
    {
      code: "en",
      label: "English",
    },
    {
      code: "de",
      label: "German",
    },
    {
      code: "es",
      label: "Spanish",
    },
  ];

  const setReferralId = () => {
    localStorage.setItem("referralId", referralid);
    window.location.href = "https://novalya.com/";
    // navigate('/plans');
  };

  useEffect(() => {
    {
      let checkPlanSelect = localStorage.getItem("planId");
      referralid
        ? setReferralId()
        : checkPlanSelect === null
        ? navigate("/plans")
        : setReferralIdSpinner(false);
      setReferralIdSpinner(false);
      let storageReferralId = localStorage.getItem("referralId");
      get_user_data_by_referral_id(
        { randomcode: storageReferralId },
        (response) => {
          setLoader(true);
          setSponsorData(response?.data?.data);
          setLoader(false);
        },
        (error) => {}
      );
    }
    let countryName = "France";
    const country = countries.find(
      (country) => country.label.toLowerCase() === countryName.toLowerCase()
    );
    setSelectedCountry(country);
  }, []);

  let frenchObj = {
    line1: "S'abonner",
    line2: "Déjà un compte ?",
    line3: "Se connecter",
    line4: "Retour en haut de page",
    line5: "abonnement",
    line6: "En partenariat avec",
    line7: "utilisateur: ",
    line8: "poste: ",
    line9:
      "Je confirme par la présente que j'ai plus de 16 ans et j'accepte les termes et conditions.",
    line10: "Adresse de facturation:",
    pline1: "Nom d'utilisateur",
    pline2: "Prénom",
    pline3: "Nom de famille",
    pline4: "Numéro de portable",
    pline5: "Email",
    pline6: "Confirmer l'adresse e-mail",
    pline7: "Nom de la société (facultatif)",
    pline8: "Adresse",
    pline9: "Ville",
    pline10: "Code postal",
    pline11: "Choisir un pays",
    pline12: "Choisir une langue",
    pline13: "Mot de passe",
    pline14: "Confirmer le mot de passe",
  };

  const defautLanguageFun = () => {
    const defaultLanguage = navigator.language;
    const wordsArray = defaultLanguage?.split("-");
    if (wordsArray?.length > 0) {
      setLan(wordsArray[0]);
    }
  };

  useEffect(() => {
    defautLanguageFun();
  }, []);

  if (loader) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  return (
    <Div
      sx={{
        width: 1000,
        maxWidth: "100%",
        margin: "auto",
        p: 2,
        "@media screen and (max-width: 500px)": {
          paddingBottom: "200px", // Adjust this for your responsive design
        },
      }}
    >
      {alertData.show ? (
        <SweetAlert
          alertData={alertData}
          setalertData={setalertData}
          sx={{
            background: "linear-gradient(to bottom, #6b8e29 50%,#2a3511 100%)",
          }}
        />
      ) : (
        ""
      )}
      {referralIdSpinner ? <Spinner /> : null}
      <center>
        <Card
          sx={{
            display: "flex",
            minWidth: 0,
            flexDirection: { xs: "column", md: "row" },
          }}
          className="signup-card"
        >
          <CardContent
            sx={{
              flex: "0 1 300px",
              position: "relative",
              background: `#0267a0 url(${getAssetPath(
                `${ASSET_IMAGES}/widgets/signup-page.jpg`,
                "640x428"
              )}) no-repeat center`,
              backgroundSize: "cover",

              "&::after": {
                display: "inline-block",
                position: "absolute",
                content: `''`,
                inset: 0,
                backgroundColor: "rgb(27 32 62 / 85%)",
              },
            }}
          >
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                flex: 1,
                flexDirection: "column",
                color: "common.white",
                position: "relative",
                zIndex: 1,
                height: "100%",
              }}
            >
              <Div sx={{ mb: 2 }}>
                <Typography
                  variant={"h3"}
                  color={"inherit"}
                  fontWeight={500}
                  mb={3}
                >
                  { t("pages.title.signup")}
                </Typography>

                <Typography variant="p">
                  <span>
                    {t("pages.title.alreadyaccount")}
                  </span>
                  <MyLink
                    style={{ color: "yellow" }}
                    component="Link"
                    to="/login"
                  >
                    <span> {t("pages.title.login")}</span>
                  </MyLink>
                </Typography>
                <br></br>
                <Typography variant="p">
                  <span>
                    { t("pages.title.backto")}
                  </span>
                  <MyLink
                    style={{ color: "yellow" }}
                    component="Link"
                    to="/plans"
                  >
                    <span>
                      {" "}
                      {t("pages.title.Subscription") }
                    </span>
                  </MyLink>
                </Typography>
                {companyData && !companyData?.logo ? (
                  <>
                    <Typography
                      variant="h3"
                      color={"inherit"}
                      mt={6}
                      sx={{ textAlign: "left" }}
                    >
                      {t("pages.title.Sponsored by")}
                    </Typography>

                    <Typography
                      variant="h5"
                      sx={{ textAlign: "left" }}
                      color={"yellow"}
                    >
                      <span>{ t("pages.title.user") }:</span>
                      <span>
                        {lan === "en"
                          ? `${
                              sponsorData?.randomcode
                                ? sponsorData?.randomcode
                                : "NOVALYA"
                            }`
                          : `${
                              sponsorData?.randomcode
                                ? sponsorData?.randomcode
                                : "NOVALYA"
                            }`}
                      </span>
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                {/* <Typography
                  variant="h5"
                  sx={{ textAlign: "left" }}
                  color={"yellow"}
                >
                  {
                    lan === 'en'
                      ?
                      "Position: "
                      :
                      frenchObj.line8
                  }
                  {`${sponsorData?.referral_side === "L" ? "Left" : "Right"
                    }`}
                </Typography> */}
              </Div>

              <Div sx={{ mt: "auto", textAlign: "center" }}>
                <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
                  {/* <img
                    src={`${ASSET_IMAGES}/logo-white.png`}
                    style={{ width: "150px" }}
                    alt="GDSG"
                  /> */}

                  <img
                    src={
                      companyData && companyData.logo
                        ? companyData.logo_val
                        : `${ASSET_IMAGES}/logo-white.png`
                    }
                    style={{ width: "150px" }}
                    alt="GDSG"
                  />
                </Link>
              </Div>
            </Div>
          </CardContent>

          <CardContent
            sx={{
              flex: 1,
              pl: 1,
              pr: 1,
              pt: 4,
              pb: 4,
            }}
          >
            <Formik
              validateOnChange={false}
              initialValues={initialstates}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setSubmitting(true);
                handleSubmit(data, setSubmitting);
              }}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form
                  style={{ textAlign: "left" }}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <JumboTextField
                        fullWidth
                        name="username"
                        label={t("pages.title.Username")}
                        type="text"
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.first_name")}
                        name="firstname"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.last_name")}
                        name="lastname"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        component={TextField}
                        value={selectedCountry}
                        onChange={(event, newValue) => {
                          setSelectedCountry(newValue);
                          setFieldValue("mobilecountry", newValue);
                        }}
                        onInputChange={(event, newValue) => {
                          // Clear the selected country when the user types
                          if (newValue === null) {
                            setSelectedCountry(null);
                          }
                        }}
                        id="mobilecountry-select-demo"
                        options={countries}
                        filterOptions={(options, state) => {
                          const inputValue = state.inputValue;
                          return options.filter((option) =>
                            option.phone.startsWith(inputValue)
                          );
                        }}
                        autoHighlight
                        getOptionLabel={(option) => `+${option?.phone}`}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              style={{ width: 25 }}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            <span> {`+${option.phone}`}</span>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <JumboTextField
                            name="mobilecountry"
                            autoComplete="off" // Set autoComplete attribute to "off"
                            fullWidth
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: selectedCountry ? (
                                <img
                                  loading="lazy"
                                  style={{ width: 25 }}
                                  src={`https://flagcdn.com/w20/${selectedCountry.code.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${selectedCountry.code.toLowerCase()}.png 2x`}
                                  alt=""
                                />
                              ) : null,
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.mobile")}
                        name="mobile"
                        type="tel"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        name="email"
                        label={t("pages.title.Email")}
                        type="email"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        name="confirm_email"
                        label={
                          t("pages.title.Confirm_Email")
                        }
                        type="email"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Typography variant="h4">
                        {t("pages.title.billingaddress")
                        }
                      </Typography>
                    </Grid>

                    {/* <Grid container spacing={2} xs={12} sm={12} sx={{pl:2}}> */}
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={
                         t("pages.title.Company Name(Optional)")
                        }
                        name="company"
                      />
                    </Grid>
                    {/* <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack spacing={3}>
                      <Field name="birthday">
                        {({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            label="Birth Date"
                            inputFormat="yyyy-MM-dd"
                            onChange={(newDate) => {
                              setFieldValue('birthday', newDate); // Update the field value
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        )}
                      </Field>
                      </Stack>
                  </LocalizationProvider>
                    </Grid> */}

                    {/* </Grid> */}

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={ t("pages.title.Address") }
                        name="address"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={t("pages.title.City")  }
                        name="city"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={  t("pages.title.Zip_Code") }
                        name="zipCode"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        component={TextField}
                        onChange={(event, newValue) => {
                          setFieldValue("country", newValue);
                        }}
                        id="country-select-demo"
                        options={countries}
                        filterOptions={(options, state) => {
                          const inputValue = state.inputValue.toLowerCase();
                          return options.filter((option) =>
                            option.label.toLowerCase().startsWith(inputValue)
                          );
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              style={{ width: 25 }}
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            <span> {option.label}</span>{" "}
                            <span>({option.code}) </span>{" "}
                            <span>{`+${option.phone}`}</span>
                          </Box>
                        )}
                        renderInput={(params) => (
                          <JumboTextField
                            name="country"
                            fullWidth
                            {...params}
                            label={
                              t("pages.title.Choose a country") 
                            }
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        sx={{ width: "100%" }}
                        component={TextField}
                        onChange={(event, newValue) => {
                          setFieldValue("language", newValue);
                        }}
                        id="language-select-demo"
                        options={languages}
                        filterOptions={(options, state) => {
                          const inputValue = state.inputValue.toLowerCase();
                          return options.filter((option) =>
                            option.label.toLowerCase().startsWith(inputValue)
                          );
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.label}
                          </Box>
                        )}
                        renderInput={(params) => (
                          <JumboTextField
                            name="language"
                            fullWidth
                            {...params}
                            label={
                             

                                t("pages.title.Choose a language") 
                            }
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={   t("pages.title.Password") }
                        type="password"
                        name="password"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <JumboTextField
                        fullWidth
                        label={
                        t("pages.title.Confirm Password") 
                        }
                        name="confirmpassword"
                        type="password"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label={
                          t("pages.title.I hereby confirm that I am aged of over 16 and agree to terms and conditions.") 
                        
                        }
                        // label=" I hereby confirm that I am aged of over 16 and agree to terms and conditions."
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <LoadingButton
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mb: 3 }}
                        loading={isSubmitting}
                      >
                        <span>
                         {t("pages.title.signup")}
                            
                        </span>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </center>
    </Div>
  );
};

export default Signup;

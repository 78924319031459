import React, { useContext, useEffect, useState } from "react";
import Div from "@jumbo/shared/Div/Div";
import {
  Button,
  CircularProgress,
  Box,
  IconButton,
} from "@mui/material";
import { fetchDeactivated } from "backendServices/ApiCalls";
import SweetAlert from "app/pages/components/mui/Alerts/SweetAlert";
import { useTranslation } from "react-i18next";
import { CustomProvider } from "app/layouts/vertical-default/VerticalDefault";
import { Datatable } from "app/pages/components/mui/Datatable";
import TabMenu from "app/pages/components/mui/TabMenu/TabMenu";
import { novaButtonsConfig } from "./TabData";
import TopHeader from "app/pages/components/mui/TopHeader/TopHeader";

const Deactivated = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [groupData, setGroupData] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const [alertData, setalertData] = React.useState({
    show: false,
    message: "",
    variant: "",
  });

  let params = {};
  const FetchGroupData = () => {
    fetchDeactivated(
      params,
      (response) => {
        setLoading(false);
        if (response?.data?.data) setGroupData(response?.data?.data ?? []);
      },
      (error) => {
      }
    );
  };
  const { loginUserData } = useContext(CustomProvider);

  useEffect(() => {
    FetchGroupData();
  }, []);

  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const columns = [
    {
      field: "user_name",
      headerName: t("pages.title.Member"),
      dataGeneratorUniquenessEnabled: true,
      width: 250, // Adjust the width as needed
      headerClassName: "nova-name-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          className="nova-name-custom-div"
          style={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #E0E1E3",
            color: "#170F49",
            padding: "4px 5px",
            borderRadius: "30px",
          }}
        >
          <img
            src={params.row.image}
            alt={params.row.user_name}
            style={{
              width: "36px",
              height: "36px",
              borderRadius: "50%",
              marginRight: "8px",
            }}
          />
          <span className="nova-name-custom-span">{params.row.user_name}</span>
          <IconButton
            className="nova-fb-icon"
            target="_blank"
            href={params.row.profile}
          >
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="17.5"
                fill="#2C73FF"
                fillOpacity="0.1"
                stroke="#E0E1E3"
              />
              <path
                d="M19.6667 19.075H22.0476L23 14.875H19.6667V12.775C19.6667 11.6935 19.6667 10.675 21.5714 10.675H23V7.147C22.6895 7.10185 21.5171 7 20.279 7C17.6933 7 15.8571 8.73985 15.8571 11.935V14.875H13V19.075H15.8571V28H19.6667V19.075Z"
                fill="#2C73FF"
              />
            </svg>
          </IconButton>
        </div>
      ),
    },
    {
      field: "mutual_friend",
      headerName: t("pages.title.# Mutual Friends"),
      dataGeneratorUniquenessEnabled: true,
      headerClassName: "nova-mutual-header",
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-mutual-custom-div">
          {params.row.mutual_friend ? params.row.mutual_friend : 0}
        </div>
      ),
    },
    {
      field: "gender",
      headerName: t("pages.title.Gender"),
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      headerClassName: "nova-gender-header",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-gender-custom-div">
          {params.row.gender
            ? params.row.gender == "Male"
              ? t("pages.title.male")
              : t("pages.title.female")
            : "-"}
        </div>
      ),
    },
    {
      field: "lived",
      headerName: t("pages.title.Lives In"),
      headerClassName: "nova-lives-header",
      dataGeneratorUniquenessEnabled: true,
      width: 150,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="nova-lives-custom-div">
          {params.row.lived ? params.row.lived : ""}
        </div>
      ),
    },
  ];

 
  return (
    <>
      {alertData.show && (
        <SweetAlert alertData={alertData} setalertData={setalertData} />
      )}
       <TopHeader
        title={t("pages.title.List of Deactivated Friend")}
        url="https://player.vimeo.com/video/955825106?badge=0&autopause=0&player_id=0&app_id=58479"
      />
      <TabMenu buttonsConfig={novaButtonsConfig} />
      <div className="nova-container">
        <div className="nova-box nova-box-only nova-deactivate">
          <Box className="nova-btn-card btn-group-friends-list">
            <Button
              variant="contained"
              id="async_decativated"
              attr-data={JSON.stringify({
                userIds: selectedRows,
              })}
              sx={{
                marginLeft: 1,
                backgroundColor: "#F44336 !important",
                "&:hover": { backgroundColor: "red !important" },
              }}
            >
              {t("pages.title.delete")}
            </Button>
          </Box>

          <Datatable
            rows={groupData}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(ids) => {
              setSelectedRows(ids);
            }}
            className="nova-table-ctm "
          />
        </div>
      </div>
    </>
  );
};

export default Deactivated;

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

import { fetchMessageList, getAllMessagesList } from "backendServices/ApiCalls";

export function formatNumber(number) {
  const stringValue = typeof number === "number" ? number.toString() : number;

  const numericValue = parseFloat(stringValue?.replace(/,/g, ""));
  if (numericValue >= 1000000) {
    return (numericValue / 1000000).toFixed(1) + "m";
  } else if (numericValue >= 1000) {
    return (numericValue / 1000).toFixed(1) + "k";
  } else {
    return numericValue.toString();
  }
}

export function ZeroStep({ group, messagesList, media }) {
  const { t } = useTranslation();
  const [messageData, setMessageData] = useState(messagesList);

  useEffect(() => {
    let isMounted = true;

    function fetchMessageLists() {
      let params = media === "Facebook" ? JSON.stringify(['fb_prospecting']):JSON.stringify(['ig_prospecting'])
      getAllMessagesList(
        params,
        (response) => {
          if (isMounted && response?.data) setMessageData(response?.data);
        },
        (error) => {}
      );
    }

    fetchMessageLists();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [media]);

  return (
    <div className="col-2-1">
      <div className="col_2">
        {group.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 320px)",
            }}
          >
            <Box sx={{ width: "100%", maxWidth: "380px" }}>
              <Card sx={{ background: "rgba(80, 72, 226, 0.15)" }}>
                <CardHeader
                  sx={{ paddingBottom: "0px", textAlign: "center" }}
                  title={
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: "12.5px",
                        fontWeight: "500",
                        letterSpacing: "1px",
                        textTransform: "uppercase",
                        margin: "0 0 15px 0",
                        color: "#5048e2",
                        marginBottom: "0px",
                        marginTop: "10px",
                        textAlign: "center",
                      }}
                    >
                      You don't have any {media} group yet.
                    </Typography>
                  }
                />
                <CardContent sx={{ paddingTop: "0px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "80px",
                    }}
                  >
                    <Button
                      onClick={() => {
                        let url = "https://www.instagram.com/";
                        if (media === "Facebook") {
                          url = "https://www.facebook.com/groups/discover";
                        }
                        window.open(url, "_blank");
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Add a group
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </div>
        )}
      </div>
      <div className="col_1">
        <span className="title-18">{t("pages.title.messages")}</span>
        <div className="message-right">
          {messageData?.map((item, index) => (
            <label
              className="group-items"
              htmlFor={`messages-${index}`}
              key={`messages-${index}`}
            >
              <div className="group-wraper-right">
                <span className="group-name">{item.title} </span>
              </div>
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import "./profile.css";
import PreferredLanguage from "./components/PreferredLanguage";
import PasswordForm from "./components/PasswordForm";
import Contacts from "./components/Contacts";
import ChargebeePortal from "./components/ChargebeePortal";


const UserProfile = () => {
  return (
    <>
      <section className="profile-page">
        <div className="container">
          <div className="profile-page-wrap">
            <div className="profile-page-left">
              <Contacts />
              <ChargebeePortal />
            </div>
            <div className="profile-page-right">
              <PreferredLanguage />
              <PasswordForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserProfile;

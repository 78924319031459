import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import JumboDemoCard from "@jumbo/components/JumboDemoCard/JumboDemoCard";
import { PersonalReferrals } from "backendServices/ApiCalls";
import Div from "@jumbo/shared/Div/Div";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const VISIBLE_FIELDS = ["sr", "sender", "amount", "date"];
const PersonalReferralsReports = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [investmentdata, setInvestmentData] = useState([]);

  const PersonalReferralReportsData = () => {
    setLoading(true);
    PersonalReferrals(
      (response) => {
        setLoading(false);
        setInvestmentData(response?.data?.data?.enteries);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    PersonalReferralReportsData();
  }, []);
  const getRowId = (row) => {
    return row.username; // You can choose any other unique identifier from your data if 'username' is not unique
  };

  function convertUnixTimestampToYMDWithTime(unixTimestamp) {
    const date = new Date(unixTimestamp * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ymdWithTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return ymdWithTime;
  }

  const rowsWithIndex = investmentdata.map((row, index) => ({
    ...row,
    id: index + 1,
  }));
  if (loading) {
    return (
      <Div
        sx={{
          display: "flex",
          minWidth: 0,
          alignItems: "center",
          alignContent: "center",
          height: "100%",
        }}
      >
        <CircularProgress sx={{ m: "-40px auto 0" }} />
      </Div>
    );
  }

  const initialState = {
    initialState: {
      columns: {
        columnVisibilityModel: {
          id: false,
          avatar: false,
          website: false,
          email: false,
          phone: false,
          username: false,
          city: false,
          company: false,
          position: false,
          lastUpdated: false,
          salary: false,
        },
      },
    },
  };

  const gridDesign = {
    "& .MuiDataGrid-toolbarContainer": {
      "& .MuiButton-text": {
        fontSize: "13px !important",
      },
      "& .MuiBadge-badge": {
        backgroundColor: "#074682",
      },
      "& .MuiInput-root": {
        borderRadius: 2,
        paddingLeft: 2,
        overflow: "hidden",
      },
    },
  };
  // Otherwise filter will be applied on fields such as the hidden column id
  const columns = [
    {
      field: "id",
      headerName: "ID",
      dataGeneratorUniquenessEnabled: true,
      width: 100,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "firstname",
      headerName: "First Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "lastname",
      headerName: "Last Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "username",
      headerName: "User Name",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "email",
      headerName: "Email",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "pkg_name",
      headerName: "Subscription",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "subscription_status",
      headerName: "Subscription Status",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
    {
      field: "nextBillingAt",
      headerName: "Renewal Date",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
      renderCell: (params) => {
        return convertUnixTimestampToYMDWithTime(params.value);
      },
    },
    {
      field: "status",
      headerName: "Status",
      dataGeneratorUniquenessEnabled: true,
      width: 200,
      editable: true,
      groupable: false,
      aggregable: false,
    },
  ];

  return (
    <>
      <Grid sx={{ mb: 3 }} container>
        <Grid item sm={4} md={4} lg={4}>
          <Button
            onClick={() => navigate("/referrals")}
            size="small"
            variant="contained"
          >
            Grid View
          </Button>
        </Grid>
      </Grid>
      <JumboDemoCard
        title={t("pages.title.referralsreport")}
        wrapperSx={{ backgroundColor: "background.paper", pt: 0 }}
      >
        <Box sx={{ height: 460, width: 1 }}>
          <DataGrid
            getRowId={getRowId}
            initialState={{
              initialState,
              pagination: { paginationModel: { pageSize: 6 } },
            }}
            rows={rowsWithIndex}
            columns={columns}
            // slots={{ toolbar: GridToolbar }}
            slots={{
              toolbar: (props) => (
                <GridToolbar
                  {...props}
                  sx={{
                    backgroundColor:
                      theme.palette.mode === "dark" ? "#424242" : "#fff",
                    "& .MuiIconButton-root": {
                      color: theme.palette.mode === "dark" ? "#fff" : "#000",
                    },
                  }}
                />
              ),
            }}
            sx={gridDesign}
            pageSizeOptions={[6, 12, 18, 24, 30]}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </JumboDemoCard>
    </>
  );
};

export default PersonalReferralsReports;
